<div class="{{lang}}">
  <div id="pcoded" class="pcoded" [ngClass]="animateSidebar" [attr.nav-type]="navType" [attr.theme-layout]="themeLayout"
    [attr.horizontal-placement]="verticalPlacement" [attr.horizontal-layout]="verticalLayout"
    [attr.pcoded-device-type]="pcodedDeviceType" [attr.vertical-nav-type]="verticalNavType"
    [attr.vertical-effect]="verticalEffect" [attr.hnavigation-view]="vnavigationView" [attr.fream-type]="freamType"
    [attr.sidebar-img]="sidebarImg" [attr.sidebar-img-type]="sidebarImgType" [attr.layout-type]="layoutType"
    (window:resize)="onResize($event)">
    <!--<div class="pcoded-overlay-box"></div>-->
    <div class="pcoded-container navbar-wrapper" *ngIf="isAuthenticated">
      <div *ngIf="!withoutTheme">
        <!-- Languages bar -->
        <nav id="brand-bar" class="navbar navbar-default navbar-fixed-top" role="navigation">
          <!-- UN global brand bar -->
          <div class="container-fluid brandbar-header language-switcher" [ngClass]="isMobile ? 'wrap' : 'noWrap'">
            <div class="navbar-header">
              <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                data-target="#language-switcher" aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand ar-class-labels" href="https://www.un.org" target="_blank">{{"Welcome to the United
                Nations" | translate}}</a>
            </div>
            <div class="navbar-collapse collapse geo-margin" id="language-switcher" aria-expanded="false"
              style="height: 0px;  ">
              <nav role="navigation">
                <ul class="language-switcher-locale-url nav navbar-nav navbar-right" role="menu">
                  <ul class="language-switcher-locale-url">
                    <li class="ar first" [class.active]="lang === 'ar'"><a [class.active]="lang === 'ar'"
                        class="language-link" (click)="changeLang('ar')" xml:lang="ar" role="menuitem">العربية</a>
                    </li>
                    <li class="zh-hans" [class.active]="lang === 'zh'"><a [class.active]="lang === 'zh'"
                        class="language-link" (click)="changeLang('zh')" xml:lang="zh-hans" role="menuitem">中文</a></li>
                    <li class="en" [class.active]="lang === 'en'"><a (click)="changeLang('en')" class="language-link"
                        [class.active]="lang === 'en'" xml:lang="en" role="menuitem">English</a></li>
                    <li class="fr" [class.active]="lang === 'fr'"><a (click)="changeLang('fr')" class="language-link"
                        [class.active]="lang === 'fr'" xml:lang="fr" role="menuitem">Français</a></li>
                    <li class="ru" [class.active]="lang === 'ru'"><a [class.active]="lang === 'ru'"
                        class="language-link" (click)="changeLang('ru')" xml:lang="ru" role="menuitem">Русский</a></li>
                    <li class="es last" [class.active]="lang === 'es'"><a [class.active]="lang === 'es'"
                        class="language-link" (click)="changeLang('es')" xml:lang="es" role="menuitem">Español</a></li>
                  </ul>
                </ul>
              </nav>
            </div>
          </div><!-- /.container -->
        </nav> <!-- Languages bar -->
        <mat-toolbar class="main">
          <div *ngIf="!isMobile">
            <a href="/" class="ripple light">
              <img class="img-fluid cst-img" src="assets/images/e-delegate-portal.PNG" alt="UN Logo" />
            </a>
            <span *ngIf="environment.branch !== 'PROD'" [routerLink]="['/portal/profile']" class="env_branch">
              - {{ environment.branch }}
            </span>
            <span class="separator" *ngIf="isMobile">|</span>
          </div>
          <button class="mobile_menu" mat-icon-button *ngIf="isMobile" [matMenuTriggerFor]="mainmenu"
            #clickMenuTrigger="matMenuTrigger">
            <mat-icon class="menu_icon">menu</mat-icon>
            {{ 'menu' | translate }}
          </button>
          <!-- <button mat-icon-button [matMenuTriggerFor]="whatsnew">What's New</button>
            <button mat-icon-button [matMenuTriggerFor]="generalassy">General Assembly</button> -->
          <span class="spacer"></span>
          <button mat-icon-button [matMenuTriggerFor]="userMenu" class="profile_menu" aria-label="User avatar image">
            <img src="assets/images/avatar-blank.jpg" class="user_avatar" alt="User-Profile-Image">
            {{ !isMobile ? currentFullName : '' }}
            <mat-icon class="arrow_dropdown">expand_more</mat-icon>
          </button>
          <span class="spacer_right" *ngIf="!isMobile" class="spacer_right"></span>

          <mat-menu mat-icon-button #userMenu="matMenu">
            <button mat-menu-item class="profile" [routerLink]="['/portal', 'profile']">
              <mat-icon>supervisor_account</mat-icon>
              {{"Profile" | translate}}
            </button>
            <button mat-menu-item (click)="signout()" class="profile">
              <mat-icon>exit_to_app</mat-icon>
              {{"Logout" | translate}}
            </button>
          </mat-menu>
          <mat-menu class="{{lang}}" #mainmenu="matMenu">
            <mat-nav-list>
              <app-menu-list-item (closeMenu)="closeMenu($event)" *ngFor="let item of items" [language]="language"
                [item]="item"></app-menu-list-item>
            </mat-nav-list>
          </mat-menu>
        </mat-toolbar>
        <mat-toolbar *ngIf="!isMobile">
          <!-- <button class="main-menu-drop" *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="whatsnew">
            {{"What's New" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button> -->
          <button class="main-menu-drop" *ngIf="showUNSDMenu === false" mat-icon-button
            [matMenuTriggerFor]="generalassy">
            {{"General Assembly" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <button class="main-menu-drop" *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="seco">
            {{"Security Council" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <button class="menu-width" *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="ecosoc">
            {{"ECOSOC" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <button *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="geneva">
            {{"Geneva" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <button *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="vienna">
            {{"Vienna" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <button *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="nairobi">
            {{"Nairobi" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <span *ngIf="environment.branch !== 'PROD' && showUNSDMenu === false"><button mat-icon-button
              [matMenuTriggerFor]="escap">
              {{"Bangkok" | translate}}
              <mat-icon>arrow_drop_down</mat-icon>
            </button></span>
          <button *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="conf">
            {{"Conferences" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <!-- <button *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="unep"> {{"UNEP" | translate}}
            <mat-icon>
              arrow_drop_down</mat-icon>
          </button> -->

          <!-- normal protocol menu -->
          <button *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="protocol">
            {{"Protocol" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>

          <!-- UNSD protocol menu -->
          <button *ngIf="showUNSDMenu === true" mat-icon-button [matMenuTriggerFor]="protocolUNSD">
            {{"Protocol" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>

          <button *ngIf="showUNSDMenu === false" mat-icon-button [matMenuTriggerFor]="resources">
            {{"Resources" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="secre" *ngIf="showSecretariat && showUNSDMenu === false">
            {{"Secretariat" | translate}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu class="{{lang}}" #whatsnew="matMenu" class="first-level">
            <a [href]="'javascript:void(0);'" (click)="onItemSelected('https://journal.un.org/-$lang$-', $event)"
              target="_blank">
              <button mat-menu-item>{{"Journal of the United Nations" | translate}}</button>
            </a>
            <button mat-menu-item [routerLink]="['/portal', 'whatsNew', 'eCorrespondenceEmail']">{{"e-Correspondence" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'whatsNew', 'spokespersonMorningHeadlines']">{{"Spokesperson's Morning
              Headlines" | translate}}</button>
            <!-- <button mat-menu-item [routerLink]="['/portal', 'whatsNew', 'meetingsAtUNHQ']">{{"Meetings at UNHQ" |
              translate}}</button> -->
            <a [href]="'javascript:void(0);'" (click)="onItemSelected('https://www.un.org/calendar/-$lang$-', $event)"
              target="_blank">
              <button mat-menu-item>{{"Calendar of Conferences and Meetings" | translate}}</button>
            </a>
            <a href="https://forms.office.com/e/X5zhX2Kx12" target="_blank">
              <button mat-menu-item>{{"Survey on Conference Services" | translate}}</button>
            </a>
            <a [href]="'javascript:void(0);'" (click)="onItemSelected('https://news.un.org/-$lang$-/', $event)"
              target="_blank">
              <button mat-menu-item>{{"UN News Centre" | translate}}</button>
            </a>
            <a [href]="'javascript:void(0);'" (click)="onItemSelected('https://www.un.org/press/-$lang$-', $event)"
              target="_blank">
              <button mat-menu-item>{{"Meetings Coverage & Press Releases" | translate}}</button>
            </a>
            <button mat-menu-item [routerLink]="['/portal', 'whatsNew', 'documentsIssuedAtUNHQToday']">{{"Documents
              issued at UNHQ Today" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #generalassy="matMenu" class="first-level">
            <button mat-menu-item [matMenuTriggerFor]="plenary">{{"Plenary" | translate}}</button>
            <!-- <button mat-menu-item [matMenuTriggerFor]="highlevelpolitical">{{"High-level Political Forum on Sustainable
              Development" | translate}}</button> -->
            <!-- <button mat-menu-item [matMenuTriggerFor]="ffd">{{"2023 High-level Dialogue on Financing for Development" |
              translate}}</button> -->
            <!-- <button mat-menu-item [matMenuTriggerFor]="imrf">{{"International Migration Review Forum" |
              translate}}</button> -->
            <!-- <button mat-menu-item [matMenuTriggerFor]="pppr">{{"HLM on pandemic preparedness prevention and response" |
              translate}}</button> -->
            <!-- <button mat-menu-item [matMenuTriggerFor]="uhc">{{"HLM on universal health coverage" |
              translate}}</button> -->
            <!-- <button mat-menu-item [matMenuTriggerFor]="hlmtb">{{"HLM on the fight against tuberculosis" |
              translate}}</button> -->
            <!-- <button mat-menu-item [matMenuTriggerFor]="hlmar">{{"High-level meeting on antimicrobial resistance" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="sotf">{{"Summit of the Future" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="hlmaetpslr">{{"High-level meeting on addressing the existential
              threats posed by sea level rise" |
              translate}}</button> -->
            <button mat-menu-item [matMenuTriggerFor]="firtscomitte">{{"First Committee" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="secondcomitte">{{"Second Committee" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="thirdcomitte">{{"Third Committee" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="fourthcomitte">{{"Fourth Committee" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="fifthcomitte">{{"Fifth Committee" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="sixthcomitte">{{"Sixth Committee" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subbodies">{{"Subsidiary Bodies" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'searchelectedofficers']">{{"Search
              elected officers" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #plenary="matMenu" class="second-level">
            <button mat-menu-item [matMenuTriggerFor]="plenaryScheduleOfmeetings">{{"Schedule" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="plenaryAgenda">{{"Agenda" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="plenaryResolutions">{{"Resolutions and Decisions" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="plenarySpeakerList">{{"Speakers list" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="plenaryDocuments">{{"Documents" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'plenary', 'placegaplenary']">{{"Plenary
              e-Place" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'plenary', 'gaannouncements']">{{"Plenary
              Announcements" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'plenary', 'esubmit']">{{"e-Submit" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'elections']">{{"e-Candidatures and Elections" |
              translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'plenary', 'candiweb']">{{"Candiweb" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'ecredentials']">{{"e-Credentials" |
              translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'plenary', 'faqs']">{{"FAQs" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="plenaryServicesTools">{{"Services and tools for virtual meetings"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #plenaryScheduleOfmeetings="matMenu" class="third-level">
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/info/meetings/79schedule.shtml')">{{"Schedule Of Meetings"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #plenaryAgenda="matMenu" class="third-level">
            <button mat-menu-item (click)="onItemSelected('https://www.un.org/en/ga/79/agenda/', $event)">{{"79th
              session" |
              translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://pep.unmeetings.org/reports/ga79_agendaStatus.html')">{{"Status of
              consideration of agenda items" |
              translate}}</button>
            <!-- <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'agenda', 'gaagendastatus']">{{"Status of
              consideration of agenda items" | translate}}</button> -->
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'agenda', 'annotatedagenda']">{{"Annotated agenda"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #plenaryResolutions="matMenu" class="third-level">
            <button mat-menu-item [routerLink]="['/portal', 'proposals', 'plenary']">
              {{"List of proposals (action module)" | translate}}
            </button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'resolutionsDecisions', 'sponsorshipP']">{{"e-Proposals
              (Sponsorship and Submission)" | translate}}</button>
            <span *ngIf="environment.branch !== 'PROD'"><button mat-menu-item
                [routerLink]="['/portal', 'generalAssembly', 'plenary', 'resolutionsDecisions', 'eRecordedVotes']">{{"e-Recorded
                votes" | translate}}</button></span>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'resolutionsDecisions', 'edecisions']">{{"e-Decisions"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'resolutionsDecisions', 'votingIntentions']">{{"e-Voting
              intentions" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected(' https://www.un.org/en/ga/79/resolutions.shtml', $event)">{{"List of
              resolutions" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/pdf/guidelines_submit_draft_proposals.pdf', $event)">{{"Submission
              guidelines" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/pdf/guidelines_submit_draft_proposals_jan2017.pdf')">{{"Editing
              of Proposals at the United Nations" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #plenarySpeakerList="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'speakerlist', 'espeakers']">{{"e-Speakers" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'speakerlist', 'speakersgd']">{{"General Debate
              speaker registration" | translate}}</button>

          </mat-menu>
          <mat-menu class="{{lang}}" #plenaryDocuments="matMenu" class="third-level">

            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'documents', 'DGACMServices']">{{"Documentation
              services for Delegates provided by DGACM" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #plenaryServicesTools="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'plenary', 'stvm', 'PowerPoint']">{{"PowerPoint" |
              translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'plenary', 'stvm', 'PDF']">{{"PDF" |
              translate}}</button>
          </mat-menu>
          <!-- <mat-menu #highlevelpolitical="matMenu" class="second-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'HighlevelPoliticalForumonSustainableDevelopment', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'HighlevelPoliticalForumonSustainableDevelopment', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'HighlevelPoliticalForumonSustainableDevelopment', 'speakersmrt']">{{"SDG
              Summit Leader's Dialogue" | translate}}</button>
          </mat-menu> -->
          <!-- <mat-menu #ffd="matMenu" class="second-level">
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'ffd', 'speakersld']">{{"Interactive
              roundtables" | translate}}</button>
          </mat-menu> -->
          <!-- <mat-menu #imrf="matMenu" class="second-level">
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'imrf', 'elistofparticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="imrfspeakers">{{"e-Speakers" | translate}}</button>
          </mat-menu> -->
          <!-- <mat-menu class="{{lang}}" #imrfspeakers="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly' ,'imrf', 'speakers', 'espeakers']">{{"General debate"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly' ,'imrf', 'speakers', 'speakersld']">{{"Round tables"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly' ,'imrf', 'speakers', 'espeakers']">{{"Policy debate"
              | translate}}</button>
          </mat-menu> -->
          <!-- <mat-menu #pppr="matMenu" class="second-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'pppr', 'speakersld']">{{"Multi-stakeholder panels" |
              translate}}</button>
          </mat-menu> -->
          <!-- <mat-menu #uhc="matMenu" class="second-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'uhc', 'speakersld']">{{"Multi-stakeholder panels" |
              translate}}</button>
          </mat-menu> -->
          <!-- <mat-menu #hlmtb="matMenu" class="second-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'hlmtb', 'speakersld']">{{"Multi-stakeholder
              panels" | translate}}</button>
          </mat-menu> -->
          <mat-menu #hlmar="matMenu" class="second-level">
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly' ,'hlmar', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'hlmar', 'speakersld']">{{"Multi-stakeholder
              panels" | translate}}</button>
          </mat-menu>
          <mat-menu #sotf="matMenu" class="second-level">
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'sotf', 'speakersld']">{{"Interactive
              dialogues" | translate}}</button>
          </mat-menu>
          <mat-menu #hlmaetpslr="matMenu" class="second-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly' ,'hlmaetpslr', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'hlmaetpslr', 'speakersld']">{{"Multi-stakeholder
              panels" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #firtscomitte="matMenu" class="second-level">
            <button mat-menu-item [matMenuTriggerFor]="firstComProgramOfWork">{{"Programme of work" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="firstComAgenda">{{"Agenda" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="firstComDCDraftProp">{{"Draft proposals" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="firstComSpeaker">{{"Speaker List" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="firstComDocs">{{"Documents" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="firstComBureau">{{"Bureau - Secretariat" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'esubmit']">{{"e-Submit" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('http://webtv.un.org/meetings-events/general-assembly/main-committees/1st-committee/')">{{"First
              Committee webcast" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/first/index.shtml', $event)">{{"First Committee
              website" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'place']">{{"First
              Committee e-Place" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'announcements']">
              {{"First Committee Announcements" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #firstComProgramOfWork="matMenu" class="third-level">
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/first/79/pdf/A_C1_79_CRP1.pdf')">{{"Programme of work and
              timetable of the First Committee" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/first/79/pdf/A_C1_79_CRP2.pdf')">{{"Timetable for
              thematic discussions" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://meetings.unoda.org/ga-c1/general-assembly-first-committee-seventy-ninth-session-2024')">{{"Side
              events calendar" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #firstComAgenda="matMenu" class="third-level">
            <button mat-menu-item onClick="window.open('http://undocs.org/en/A/C.1/79/1')">{{"Allocation
              of agenda
              items" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #firstComDCDraftProp="matMenu" class="third-level">
            <button mat-menu-item [routerLink]="['/portal', 'proposals', 'c1']">
              {{"List of proposals (action module)" | translate}}
            </button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'draftProposals', 'sponsorshipC1']">{{"First
              Committee e-Sponsorship" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'draftProposals', 'votingIntentions']">{{"First
              Committee e-Voting intentions" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'draftProposals', 'GuidelinesForMainSponsors']">{{"Guidelines
              for main sponsors" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'draftProposals', 'GuidelinesForCoSponsors']">{{"Guidelines
              for co-sponsors" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'firstCommittee', 'draftProposals', 'ProcedureForDraftSubmissions']">{{"Procedure
              for draft submissions" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #firstComSpeaker="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly' ,'firstCommittee', 'speakerlist', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #firstComDocs="matMenu" class="third-level">
            <button mat-menu-item
              onClick="window.open('https://undocs.org/Home/Mobile?FinalSymbol=A%2fC.1%2f79%2fINF%2f1&Language=E&DeviceType=Desktop&LangRequested=False')">{{"Status
              of
              documentation"
              | translate}}</button>
            <!-- <button mat-menu-item onClick="window.open('https://gafc-vote.un.org/')">{{"First Committee resolutions and
              decisions" | translate}}</button> -->
            <button mat-menu-item
              onClick="window.open('https://www.un.org/disarmament/publications/library/')">{{"Disarmament library
              including resolutions and decisions" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://undocs.org/-$lang$-/A/C.1/79/INF/4', $event)">{{"Revitalization of the
              work of the General Assembly" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #firstComBureau="matMenu" class="third-level">
            <a href="" target="_blank">
              <button mat-menu-item
                (click)="onItemSelected('https://www.un.org/-$lang$-/ga/first/79/bureau.shtml', $event)">{{"Bureau
                members" | translate}}</button>
            </a>
            <a href="" target="_blank">
              <button mat-menu-item
                (click)="onItemSelected('https://www.un.org/-$lang$-/ga/first/secretariat.shtml', $event)">{{"Secretariat
                - contact
                details" | translate}}</button>
            </a>
          </mat-menu>
          <mat-menu class="{{lang}}" #secondcomitte="matMenu" class="second-level">
            <button mat-menu-item [matMenuTriggerFor]="secondComDCSchedule">{{"Schedule" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="secondComDraft">{{"Draft proposals" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="secondSpeaker">{{"Speaker list" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="secondDoc">{{"Documents" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="secondContact">{{"Contact List" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'secondCommittee', 'eListOfParticipants']">{{"e-List of
              participants" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'secondCommittee', 'place']">{{"Second
              Committee e-Place" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'secondCommittee', 'announcements']">{{"Second Committee
              Announcements" | translate}}</button>
            <a href="" target="_blank">
              <button mat-menu-item
                (click)="onItemSelected('https://www.un.org/-$lang$-/ga/second/index.shtml', $event)">{{"Second
                Committee Website" | translate}}</button>
            </a>
            <a href="" target="_blank">
              <button mat-menu-item
                (click)="onItemSelected('https://press.un.org/en/second-committee', $event)">{{"Press Releases"
                | translate}}</button>
            </a>
            <a href="" target="_blank">
              <button mat-menu-item
                onClick="window.open('https://webtv.un.org/en/search/categories/meetings-events/general-assembly/79th-session/2nd-committee')">{{"Second
                Committee Webcast" | translate}}</button>
            </a>
            <button mat-menu-item [matMenuTriggerFor]="secondVideos">{{"Instructional Videos" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #secondComDCSchedule="matMenu" class="third-level">
            <button mat-menu-item
              onClick="window.open('https://edelegate.un.int/portal/generalAssembly/secondCommittee/c2schedule/meetingsAtUNHQC2', '_self')">{{"Second
              Committee
              meetings" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('https://igov.un.org/ga/c2/79/calendar')">{{"Calendar
              of meetings" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('https://undocs.org/en/A/C.2/79/L.1')">{{"Organization of work" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('https://igov.un.org/ga/c2/79/meetings')">{{"Special
              events schedule" | translate}}</button>
            <button mat-menu-item (click)="onItemSelected('https://journal.un.org/-$lang$-', $event)">{{"UN Journal" |
              translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #secondComDraft="matMenu" class="third-level">
            <button mat-menu-item [routerLink]="['/portal', 'proposals', 'c2']">
              {{"List of proposals (action module)" | translate}}
            </button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'secondCommittee', 'draftProposals', 'sponsorshipC2']">{{"e-Proposals
              (Sponsorship and Submission)" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'secondCommittee', 'draftProposals', 'votingIntentions']">{{"e-Voting
              intentions" | translate}}</button>
            <a href="" target="_blank">
              <button mat-menu-item
                onClick="window.open('https://igov.un.org/ga/c2/79/documents?menu=proposals')">{{"List
                of draft proposals" | translate}}</button>
            </a>
            <a href="" target="_blank">
              <button mat-menu-item onClick="window.open('')">{{"Status of action on
                draft proposals" | translate}}</button>
            </a>
            <a href="" target="_blank">
              <button mat-menu-item
                onClick="window.open('https://www.un.org/en/ga/second/79/docs/submission-guidelines.pdf')">{{"Procedure
                for
                submission of draft proposals" | translate}}</button>
            </a>
          </mat-menu>
          <mat-menu class="{{lang}}" #secondSpeaker="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'secondCommittee', 'c2speakerlist', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'secondCommittee', 'c2speakerlist']">{{"LIVE List of
              speakers" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #secondDoc="matMenu" class="third-level">
            <button mat-menu-item onClick="window.open('http://undocs.org/en/A/C.2/79/1')">{{"Allocation of agenda
              items"
              | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://igov.un.org/ga/c2/79/documents?menu=documents')">{{"Documents per
              agenda
              item" | translate}}</button>
            <button mat-menu-item onClick="window.open('http://undocs.org/en/A/C.2/79/L.1/Add.1')">{{"Status of
              documentation" | translate}}</button>
            <button mat-menu-item onClick="window.open('')">{{"Voting records" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('')">{{"Reports
              to the Plenary" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #secondContact="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'secondCommittee', 'contactList', 'delegatesContactList']">{{"C2
              Delegates contact list" | translate}}</button>
            <a href="" target="_blank">
              <button mat-menu-item
                (click)="onItemSelected('https://www.un.org/-$lang$-/ga/second/secretariat.shtml', $event)">{{"Secretariat
                contact list"
                | translate}}</button>
            </a>
          </mat-menu>
          <mat-menu class="{{lang}}" #secondVideos="matMenu" class="second-level">
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/second/video/2C_menu.mp4')">{{"Overview
              of Second Committee Menu" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/second/video/2Cplace.mp4')">{{"Overview
              of Second Committee e-Place" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/second/video/esponsorship.mp4')">{{"Overview of
              e-Sponsorship" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/third/video/how%20to%20initiate.mp4')">{{"How to
              initiate a draft resolution for co-sponsorship" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/second/video/esubmission.mp4')">{{"Overview of
              e-Submission" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/third/video/espeakers.mp4')">{{"Overview of e-Speakers" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/third/video/livelist.mp4')">{{"Overview
              of LIVE List of
              Speakers" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/second/video/evotes.mp4')">{{"Overview
              of e-Voting intentions" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #thirdcomitte="matMenu" class="second-level">
            <button mat-menu-item [matMenuTriggerFor]="thirdSchedule">{{"Schedule" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="thirdDraft">{{"Draft proposals" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="thirdSpeaker">{{"Speaker List" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="thirdDocs">{{"Documents" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="thirdContact">{{"Contact List" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'eListOfParticipants']">{{"e-List of
              participants" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'place']">{{"Third
              Committee e-Place" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'announcements']">{{"Third Committee
              Announcements" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/third/index.shtml')">{{"Third Committee
              Website" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/third/pr.shtml')">{{"Press Releases" |
              translate}}</button>
            <button mat-menu-item
              onClick="window.open('http://webtv.un.org/meetings-events/general-assembly/main-committees/3rd-committee/')">{{"Third
              Committee webcast" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="thirdVideos">{{"Instructional videos" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #thirdSchedule="matMenu" class="third-level">
            <button mat-menu-item onClick="window.open('https://undocs.org/en/A/C.3/79/L.1')">{{"Organization of work" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('https://igov.un.org/ga/c3/79/calendar')">{{"Calendar of
              meetings" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'schedule', 'meetingsAtUNHQC3']">{{"Third
              Committee
              meetings" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/third/79/docs/dialogues.pdf')">{{"List
              of special procedure mandate-holders and the other experts" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #thirdDraft="matMenu" class="third-level">
            <button mat-menu-item [routerLink]="['/portal', 'proposals', 'c3']">
              {{"List of proposals (action module)" | translate}}
            </button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'draftProposals', 'sponsorshipC3']">{{"e-Proposals
              (Sponsorship and Submission)" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'draftProposals', 'votingIntentions']">{{"e-Voting
              intentions" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/third/78/docs/guidelines.pdf')">{{"Procedure for submission
              of draft proposals" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://igov.un.org/ga/c3/79/documents?menu=proposals')">{{"List
              of
              draft proposals" | translate}}</button>
            <button mat-menu-item onClick="window.open('')">{{"Status of action of
              draft proposals" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #thirdSpeaker="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'speakerlist', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'speakerlist']">{{"LIVE
              list of speakers" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #thirdDocs="matMenu" class="third-level">
            <button mat-menu-item onClick="window.open('https://undocs.org/a/c.3/79/1')">{{"Allocation of agenda
              items"
              | translate}}</button>
            <!-- <button mat-menu-item onClick="window.open('https://undocs.org/en/A/C.3/78/L.1/Add.1')">{{"Status of
              documentation" | translate}}</button> -->
            <button mat-menu-item
              onClick="window.open('https://igov.un.org/ga/c3/79/documents?menu=documents')">{{"Documentation per
              agenda
              item" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://igov.un.org/ga/c3/79/documents?menu=proposals')">{{"List
              of
              draft proposals" | translate}}</button>
            <button mat-menu-item onClick="window.open('')">{{"Status of action of
              draft proposals" | translate}}</button>
            <button mat-menu-item onClick="window.open('')">{{"Voting records" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('')">{{"Reports to
              the Plenary" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #thirdContact="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'thirdCommittee', 'contactList', 'delegatesContactList']">{{"Delegates
              Contact List" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/third/secretariat.shtml', $event)">{{"Secretariat
              Contact List" |
              translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #thirdVideos="matMenu" class="third-level">
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/third/video/3C_menu.mp4')">{{"Overview
              of Third Committee Menu" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/third/video/3Cplace.mp4')">{{"Overview
              of Third Committee e-Place" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/third/video/esponsorship.mp4')">{{"Overview of
              e-Sponsorship" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/third/video/how%20to%20initiate.mp4')">{{"How to
              initiate a draft resolution for co-sponsorship" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/third/video/esubmission.mp4')">{{"Overview of e-Submission"
              | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/third/video/espeakers.mp4')">{{"Overview of e-Speakers" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/third/video/livelist.mp4')">{{"Overview
              of LIVE List of Speakers" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/third/video/evotes.mp4')">{{"Overview
              of e-Voting intentions" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #fourthcomitte="matMenu" class="second-level">
            <button mat-menu-item [routerLink]="['/portal', 'proposals', 'c4']">
              {{"List of proposals (action module)" | translate}}
            </button>
            <button mat-menu-item [matMenuTriggerFor]="fourthBureau">{{"Bureau - Secretariat contacts" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fourthCommittee', 'sponsorshipC4']">{{"Fourth Committee
              e-Sponsorship" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fourthCommittee', 'votingIntentions']">{{"Fourth Committee
              e-Voting intentions" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fourthCommittee', 'espeakers']">{{"e-Speakers" |
              translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'fourthCommittee', 'place']">{{"Fourth
              Committee e-Place" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fourthCommittee', 'announcements']">{{"Fourth Committee
              Announcements" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fourthCommittee', 'eListOfParticipants']">{{"e-List
              Participants" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/press/-$lang$-/content/fourth-committee', $event)">{{"Press
              Releases" | translate}}</button>
            <button mat-menu-item onClick="window.open('http://webtv.un.org/')">{{"Webcast" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/fourth/links.shtml', $event)">{{"Related Links"
              | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/fourth/archives.shtml', $event)">{{"Past
              sessions" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #fourthBureau="matMenu" class="third-level">
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/fourth/77/bureau77.shtml', $event)">{{"Bureau -
              contact details" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/fourth/secretariat.shtml', $event)">{{"Secretariat
              - contact
              details" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #fifthcomitte="matMenu" class="second-level">
            <button mat-menu-item [routerLink]="['/portal', 'proposals', 'c5']">
              {{"List of proposals (action module)" | translate}}
            </button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fifthCommittee', 'announcements']">{{"Announcements" |
              translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'generalAssembly', 'fifthCommittee', 'place']">{{"Fifth
              Committee e-Place" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fifthCommittee', 'elections']">{{"e-Candidatures and
              Elections" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fifthCommittee', 'candiweb']">{{"Candiweb" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fifthCommittee', 'esubmit']">{{"e-Submit" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fifthCommittee', 'espeakers']">{{"e-Speakers" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fifthCommittee', 'eListOfParticipants']">{{"e-List of
              Participants" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="fifthMeetings">{{"Meetings" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="fifthDocs">{{"Documents" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="fifthContact">{{"Contact information" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="fifthAbout">{{"About" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #fifthMeetings="matMenu" class="third-level">
            <button mat-menu-item (click)="onItemSelected('https://journal.un.org/-$lang$-', $event)">{{"eJournal" |
              translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/en/ga/fifth/79/main79.shtml', $event)">{{"Programme
              of work" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/fifth/79/statements79.0m.shtml')">{{"Statements" |
              translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://webtv.un.org/en/search/categories/meetings-events/general-assembly/79th-session/5th-committee', $event)">{{"Webcast"
              | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/press/-$lang$-/content/fifth-committee', $event)">{{"Press
              releases" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #fifthDocs="matMenu" class="third-level">
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/fifth/79/agenda79.shtml')">{{"Agenda
              items" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/en/ga/fifth/79/main79.shtml', $event)">{{"Status of
              documentation" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/en/ga/fifth/79/resdec79.shtml')">{{"Resolutions and decisions" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'fifthCommittee', 'c5documents', 'place']">{{"Fifth Committee
              e-Place" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #fifthContact="matMenu" class="third-level">

            <button mat-menu-item
              onClick="window.open('https://edelegate.un.int/portal/doc/placec5/download?uploadName=66ce216058f6f2db3c664043~C5_79_0m_Secretariat Contact Information Sheet.pdf&fileName=C5_79_0m_Secretariat Contact Information Sheet.pdf')">{{"Secretariat
              contact information" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #fifthAbout="matMenu" class="third-level">
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/fifth/index.shtml', $event)">{{"C5 website on
              un.org" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/fifth/previous.shtm')">{{"Past
              sessions" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/fifth/faq.shtml')">{{"Frequently asked
              questions" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/fifth/rl.shtml', $event)">{{"Related links" |
              translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #sixthcomitte="matMenu" class="second-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'sixthCommittee', 'sponsorshipC6']">{{"Sixth Committee
              e-Sponsorship" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'sixthCommittee', 'espeakers']">{{"e-Speakers" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'sixthCommittee', 'eListOfParticipants']">{{"e-List of
              Participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'sixthCommittee', 'announcements']">{{"Announcements" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'sixthCommittee', 'c6meetings']">{{"Scheduled Meetings" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'sixthCommittee', 'contactList']">{{"Delegates contact list"
              | translate}}</button>
            <a [href]="'javascript:void(0);'" (click)="onItemSelected('https://www.un.org/-$lang$-/ga/sixth/', $event)"
              target="_blank">
              <button mat-menu-item>{{"Website" | translate}}</button>
            </a>
          </mat-menu>
          <mat-menu class="{{lang}}" #subbodies="matMenu" class="second-level">
            <button mat-menu-item [matMenuTriggerFor]="subAHCC">{{"Ad Hoc Committee on Cybercrime" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subAdHoc">{{"Ad Hoc Working Group on GA revitalization" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subAHICT">{{"AHIC to draft terms of reference.....on
              international tax cooperation" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subBien">{{"Biennial Meeting on Small Arms" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subCom">{{"Committee on Conferences" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subDisarm">{{"Disarmament Commission" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subGen">{{"General Committee" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subHum">{{"Human Rights Council" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subOEWGA">{{"OEWG on Ageing" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subOEWGCA">{{"OEWG on Conventional Ammunition" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subOEWGSec">{{"OEWG on security of ICTs 2021-2025" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subOEWGInfo">{{"OEWG on Information and Telecomm. (MANDATE
              COMPLETED)" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subPeace">{{"Peacebuilding Commission" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subPeaceSplCom24">{{"Special Committee on Decolonization (C-24)"
              | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subPeaceSplCom34">{{"Special Committee on Peacekeeping Operations
              (C-34)" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="unCharterCom">{{"Special Committee on the Charter" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subPeaceGPE">{{"Towards a Global Pact for the Environment" |
              translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subPeaceUNRWA">{{"UNRWA Pledging Conference" |
              translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subAHCC="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'ahcc', 'sponsorshipAHCC']">{{"e-Proposals
              (Sponsorship and Submission)"
              | translate}}</button>
          </mat-menu>
          <mat-menu #subAdHoc="matMenu" class="third-level">
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/revitalization/index.shtml', $event)">{{"Website"
              |
              translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/revitalization/revital_docs.shtml', $event)">{{"Documents"
              |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'AHWGArevitalization', 'Announcements']">{{"Announcements"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subAHICT="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'ahict', 'Announcements']">{{"Announcements"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'ahict', 'place']">{{"e-Place"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'ahict', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'ahict', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subBien="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'BiennialMeetingonSmallArms', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'BiennialMeetingonSmallArms', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'BiennialMeetingonSmallArms', 'Announcements']">{{"Announcements"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subCom="matMenu" class="third-level">
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/dgacm/-$lang$-/content/coc', $event)">{{"Website" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('https://undocs.org/en/A/AC.172/2024/1')">{{"Proposed
              Agenda for
              2024" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/dgacm/-$lang$-/content/coc/pre-session', $event)">{{"Documents"
              |
              translate}}</button>
            <!-- <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'coc', 'Announcements']">{{"Announcements"
              | translate}}</button> -->
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'coc', 'COCPlace']">{{"COC e-Place" |
              translate}}</button>
            <!-- <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'coc', 'espeakers']">{{"e-Speakers" |
              translate}}</button> -->
          </mat-menu>
          <mat-menu class="{{lang}}" #subDisarm="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'disarmamentCommission', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'disarmamentCommission', 'elistofparticipants']">{{"e-List
              of Participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'disarmamentCommission', 'Bureau']">{{"Bureau"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'disarmamentCommission', 'Secretariatcontactdetails']">{{"Secretariat-Contact
              Details" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'disarmamentCommission', 'Schedule']">{{"Schedule"
              | translate}}</button>
            <button mat-menu-item onClick="window.open('https://undocs.org/A/CN.10/L.85')">{{"Agenda (organizational
              session)" | translate}}</button>
            <button mat-menu-item onClick="window.open('https://undocs.org/A/CN.10/L.86')">{{"Agenda (substantive
              session)" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'disarmamentCommission', 'UNDCAnnouncements']">{{"UNDC
              Announcements" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'disarmamentCommission', 'UNDCPlace']">{{"UNDC
              e-Place" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/disarmament/institutions/disarmament-commission/session-2019/')">{{"Website"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subGen="matMenu" class="third-level">
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/general/general.shtml', $event)">{{"Website" |
              translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/73/agenda/', $event)">{{"Documents" |
              translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subHum="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'HumanRightsCouncil', 'sponsorshipHRC']">{{"Human
              Rights Council e-Sponsorship" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'HumanRightsCouncil', 'GuidelinesForHRCMainSponsors']">{{"Guidelines
              for main sponsors Human Rights Council e-Sponsorship" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'HumanRightsCouncil', 'GuidelinesForHRCCoSponsors']">{{"Guidelines
              for co-sponsors" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'HumanRightsCouncil', 'eRecordedVotesHRC']">{{"e-Recorded
              votes" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subOEWGA="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonAgeing', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonAgeing', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonAgeing', 'Announcements']">{{"Announcements"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonAgeing', 'sponsorshipOEWGA']">{{"e-Proposals
              (Sponsorship and Submission)"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subOEWGCA="matMenu" class="third-level">
            <!-- <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonConvAmm', 'espeakers']">{{"e-Speakers"
              | translate}}</button> -->
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonConvAmm', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonConvAmm', 'Announcements']">{{"Announcements"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonConvAmm', 'place']">{{"OEWG on
              Conventional Ammunition e-Place"
              | translate}}</button>
            <button mat-menu-item onClick="window.open('https://meetings.unoda.org/node/62086')">{{"Website" |
              translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subOEWGSec="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonSecurity', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonSecurity', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonSecurity', 'Announcements']">{{"Announcements"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonSecurity', 'Agenda']">{{"Agenda" |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonSecurity', 'OEWGonSecurityPlace']">{{"OEWG
              on Security e-Place" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subOEWGInfo="matMenu" class="third-level">
            <button mat-menu-item
              onClick="window.open('https://www.un.org/disarmament/open-ended-working-group/')">{{"Website" |
              translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://undocs.org/-$lang$-/A/AC.290/2019/1', $event)">{{"Agenda" |
              translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.eda.admin.ch/dam/mission-new-york/en/documents/Lauber-Juerg-2015_EN.pdf')">{{"Chairmanship"
              | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/-$lang$-/ga/first/secretariat.shtml', $event)">{{"Secretariat"
              |
              translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonInformationandTelecommunications', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonInformationandTelecommunications', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonInformationandTelecommunications', 'OEWGonInformationandTelecommunicationsPlace']">{{"OEWG
              on Information and Telecommunications Place" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonInformationandTelecommunications', 'Announcements']">{{"Announcements"
              | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://undocs.org/-$lang$-/A/AC.290/2019/2', $event)">{{"First substantive
              session (9-13 Sep 2019) - Programme of work" | translate}}</button>
            <button mat-menu-item
              (click)="onItemSelected('https://undocs.org/-$lang$-/A/AC.290/2020/1', $event)">{{"Second substantive
              session (10-14 Feb 2020) - Programme of work" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'OEWGonInformationandTelecommunications', 'Thirdsubstantivesession(8-12Mar2021)']">{{"Third
              substantive session (8-12 Mar 2021) - Programme of work" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subPeace="matMenu" class="third-level">
            <button mat-menu-item
              onClick="window.open('https://www.un.org/peacebuilding/commission/membership')">{{"Membership and
              Bureau" | translate}}</button>
            <button mat-menu-item
              onClick="window.open('https://www.un.org/peacebuilding/commission/mandate')">{{"Mandate" |
              translate}}</button>
            <button mat-menu-item onClick="window.open('https://www.un.org/peacebuilding/documents')">{{"Documents" |
              translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subPeaceSplCom24="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'SpecialCommitteeonDecolonization', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'SpecialCommitteeonDecolonization', 'C24Place']">{{"C24
              e-Place" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'SpecialCommitteeonDecolonization', 'Announcements']">{{"Announcements"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'SpecialCommitteeonDecolonization', 'sponsorshipC24']">{{"C-24
              e-Sponsorship" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'SpecialCommitteeonDecolonization', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subPeaceSplCom34 class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'specialCommitteeOnPeacekeepingOperations', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'specialCommitteeOnPeacekeepingOperations', 'place']">{{"C34
              e-Place" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'specialCommitteeOnPeacekeepingOperations', 'announcements']">{{"Announcements"
              | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'specialCommitteeOnPeacekeepingOperations', 'eListOfParticipants']">{{"e-List
              of participants" | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #unCharterCom class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'UNCharterCommittee', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subPeaceGPE="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'specialCommitteeOnPeacekeepingOperations', 'eListofparticipants']">{{"e-List
              of participants" | translate}}</button>
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'specialCommitteeOnPeacekeepingOperations', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #subPeaceUNRWA="matMenu" class="third-level">
            <button mat-menu-item
              [routerLink]="['/portal', 'generalAssembly', 'subsidiaryBodies', 'specialCommitteeOnPeacekeepingOperations', 'espeakers']">{{"e-Speakers"
              | translate}}</button>
          </mat-menu>
          <mat-menu class="{{lang}}" #seco="matMenu" class="first-level">
            <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'placesc']">{{"Security Council e-Place"
              | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'espeakers']">{{"Security Council
              e-Speakers" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'sponsorshipSC']">{{"Security Council
              e-Sponsorship" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'announcements']">{{"Security Council
              Announcements" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="secSubOrgans">{{"Subsidiary Organs" | translate}}</button>
            <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'Resolution2231(2015)']">{{"Resolution
              2231 (2015) e-Place" | translate}}</button>
            <button mat-menu-item [matMenuTriggerFor]="subPeaceBuilding">{{"Peacebuilding Commission" |
              translate}}</button>
          </mat-menu>
        </mat-toolbar>
        <mat-menu class="{{lang}}" #secSubOrgans="matMenu" class="third-level">
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1267']">{{"ISIL
            (Da'esh) and Al-Qaida Sanctions Committee e-Place" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1373']">{{"Counter-Terrorism
            Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1518']">{{"1518
            Sanctions Committee (Iraq) e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1533']">{{"DRC
            Sanctions Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1540']">{{"1540
            Committee e-Place" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1591']">{{"Sudan Sanctions
            Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1718']">{{"1718
            Sanctions Committee (DPRK) e-Place" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1970']">{{"Libya Sanctions
            Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc1988']">{{"1988
            Sanctions Committee e-Place" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc2048']">{{"Guinea-Bissau Sanctions
            Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc2140']">{{"2140
            Sanctions Committee (Yemen) e-Place" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc2206']">{{"South Sudan Sanctions
            Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc2374']">{{"Mali
            Sanctions Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc2653']">{{"2653
            Sanctions Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc2745']">{{"2745
            Sanctions Committee e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesc751']">{{"2713
            Sanctions Committee e-Place" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placewgcaac']">{{"Working Group on
            Children and Armed Conflict e-Place" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'securitycouncil', 'SubsidiaryOrgans', 'placesciwg']">{{"Informal Working Group on
            Documentation and other Procedural Questions e-Place" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #subPeaceBuilding="matMenu" class="third-level">
          <button mat-menu-item
            onClick="window.open('https://www.un.org/peacebuilding/commission/membership')">{{"Membership and Bureau" |
            translate}}</button>
          <button mat-menu-item onClick="window.open('https://www.un.org/peacebuilding/commission/mandate')">{{"Mandate"
            | translate}}</button>
          <button mat-menu-item onClick="window.open('https://www.un.org/peacebuilding/documents')">{{"Documents" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecosoc="matMenu" class="first-level">
          <button mat-menu-item [matMenuTriggerFor]="ecoMeet">{{"ECOSOC meetings/segments/forums" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoCoord">{{"Committee for Programme and Coordination" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoDev">{{"Commission for Social Development" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoPop">{{"Commission on Population and Development" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoWomen">{{"Commission on the Status of Women" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoCnd">{{"Commission on Narcotic Drugs" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoCcpcj">{{"Commission on Crime Prevention and Criminal Justice" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoNon">{{"Committee on Non-Governmental Organizations" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoForum">{{"Permanent Forum on Indigenous Issues" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoStats">{{"Statistical Commission" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoExp">{{"United Nations Committee of Experts on Global Geospatial
            Information Management" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoForests">{{"United Nations Forum on Forests" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoGeo">{{"United Nations Group of Experts on Geographical Names" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoMeet="matMenu" class="second-level">
          <button mat-menu-item
            (click)="onItemSelected('https://www.un.org/ecosoc/-$lang$-/events', $event)">{{"Schedule" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'ecosocmeetings']">{{"ECOSOC meetings" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'agenda']">{{"Agenda" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoResol">{{"Resolutions and Decisions" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'place']">{{"ECOSOC e-Place" |
            translate}}</button>
          <button mat-menu-item (click)="onItemSelected('https://www.un.org/ecosoc/-$lang$-/', $event)">{{"ECOSOC
            Website" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'speakersld']">{{"ECOSOC Youth Forum
            e-Speakers" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'eListOfParticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoElect">{{"Elections" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'searchmembership']">{{"Search membership" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoResol="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'en', 'resolutionsDecisions', 'sponsorshipECOSOC']">{{"ECOSOC
            e-Sponsorship" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'en', 'resolutionsDecisions', 'edecisions']">{{"ECOSOC Decisions" |
            translate}}</button>
          <button mat-menu-item
            (click)="onItemSelected('https://www.un.org/ecosoc/-$lang$-/documents/resolutions', $event)">{{"ECOSOC
            Resolutions" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'en', 'resolutionsDecisions', 'votingIntentions']">{{"ECOSOC e-Voting
            intentions" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'resolutionsDecisions']">{{"Submission
            guidelines" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoElect="matMenu" class="third-level">
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'elections', 'elections']">{{"e-Candidatures
            and Elections" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'en', 'elections', 'candiweb']">{{"Candiweb" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoCoord="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'committeeforprogrammeandcoordination', 'announcements']">{{"Announcements"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'committeeforprogrammeandcoordination', 'place']">{{"CPC Place
            (Restricted)" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://www.un.org/en/ga/cpc/')">{{"CPC Website (Public)" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoDev="matMenu" class="second-level">
          <button mat-menu-item onClick="window.open('https://undocs.org/E/CN.5/2021/1/Add.1')">{{"Proposed Organization
            of Work" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionForSocialDevelopment', 'CSOCDMeetings']">{{"CSOCD meetings" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ecoCSOCD">{{"Draft Proposals" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionForSocialDevelopment', 'eListOfParticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionForSocialDevelopment', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            onClick="window.open('http://espeakers.unmeetings.org/6012df7530fd2c00180cfa7528012021/index.html')">{{"LIVE
            List of speakers" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc','commissionForSocialDevelopment', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://www.un.org/development/desa/dspd/united-nations-commission-for-social-development-csocd-social-policy-and-development-division/csocd58.html')">{{"Website"
            | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoCSOCD="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionForSocialDevelopment', 'draftProposals', 'sponsorshipCSOCD']">{{"CSOCD
            e-Sponsorship" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionForSocialDevelopment', 'draftProposals', 'votingIntentions']">{{"CSOCD
            e-Voting intentions" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoPop="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnPopulationAndDevelopment', 'CPDMeetings']">{{"CPD meetings"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnPopulationAndDevelopment', 'sponsorshipCPD']">{{"CPD
            e-Sponsorship" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnPopulationAndDevelopment', 'votingIntentions']">{{"CPD
            e-Voting intentions" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnPopulationAndDevelopment', 'announcements']">{{"Announcements"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnPopulationAndDevelopment', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            onClick="window.open('http://espeakers.unmeetings.org/6419f6514d099d5e1bb3948b21032023/index.html')">{{"CPD
            LIVE list of Speakers" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnPopulationAndDevelopment', 'eListOfParticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://www.un.org/development/desa/pd/content/CPD')">{{"Website"
            | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoWomen="matMenu" class="second-level">
          <button mat-menu-item onClick="window.open('https://undocs.org/E/CN.6/2024/1/Add.1')">{{"Organization of Work"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnTheStatusOfWomen', 'CSW62MeetingsInUNHQ']">{{"CSW Meetings"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnTheStatusOfWomen', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnTheStatusOfWomen', 'sponsorshipCSW']">{{"e-Sponsorship" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnTheStatusOfWomen', 'votingIntentions']">{{"CSW e-Voting
            intentions" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnTheStatusOfWomen', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnTheStatusOfWomen', 'speakersmrt']">{{"e-Ministerial Round
            Tables" | translate}}</button>
          <!-- <button mat-menu-item [matMenuTriggerFor]="ecoLiveSpeakers">{{"LIVE list of speakers" | translate}}</button> -->
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'commissionOnTheStatusOfWomen', 'eListOfParticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://www.unwomen.org/en/csw/csw68-2024/official-documents')">{{"Documents" |
            translate}}</button>
          <button mat-menu-item
            (click)="onItemSelected('https://www.unwomen.org/en/how-we-work/commission-on-the-status-of-women', $event)">{{"Website"
            | translate}}</button>
        </mat-menu>
        <!-- <mat-menu class="{{lang}}" #ecoLiveSpeakers="matMenu" class="third-level">
          <button mat-menu-item
            onClick="window.open('http://espeakers.unmeetings.org/6046610af034190011626ee908032021/index.html')">{{"General
            discussion" | translate}}</button> -->
        <!-- <a href="" target="_blank">
            <button mat-menu-item
              onClick="window.open('http://espeakers.unmeetings.org/604b800bf0341900116275cd12032021/index.html')">{{"MRT
              1 (15 March 4-5 pm)" | translate}}</button>
          </a> -->
        <!-- <a href="" target="_blank">
            <button mat-menu-item
              onClick="window.open('http://espeakers.unmeetings.org/604b929ef0341900116276ae12032021/index.html')">{{"MRT
              2 (15 March 5-6 pm)" | translate}}</button>
          </a>
          <a href="" target="_blank">
            <button mat-menu-item
              onClick="window.open('http://espeakers.unmeetings.org/604bc446f03419001162797a12032021/index.html')">{{"MRT
              3 (16 March 9-10 am)" | translate}}</button>
          </a>
          <a href="" target="_blank">
            <button mat-menu-item
              onClick="window.open('http://espeakers.unmeetings.org/604bc54cf03419001162799f12032021/index.html')">{{"MRT
              4 (16 March 10-11 am)" | translate}}</button>
          </a> -->
        <!-- </mat-menu> -->
        <mat-menu #ecoCnd="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'cnd', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'cnd', 'sponsorshipCND']">{{"e-Proposals
            (Sponsorship and Submission)" | translate}}</button>
        </mat-menu>
        <mat-menu #ecoCcpcj="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'ccpcj', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'ecosoc', 'ccpcj', 'sponsorshipCCPCJ']">{{"e-Proposals
            (Sponsorship and Submission)" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoNon="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'CommitteeonNon-GovernmentalOrganizations', 'eListOfParticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://ecosoc.un.org/en/ngo/committee-on-ngos')">{{"CNGOs
            website" | translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://undocs.org/Home/Mobile?FinalSymbol=E%2fC.2%2f2024%2f1%2fRev.1&Language=E&DeviceType=Desktop&LangRequested=False')">{{"Provisional
            Agenda" | translate}}</button>
          <!-- <button mat-menu-item
            onClick="window.open('https://csonet.org/content/documents/Information%20Note%202023%20Resumed%20session_1%20May%202023_FINAL.pdf')">{{"Programme
            of Work" | translate}}</button> -->
          <button mat-menu-item onClick="window.open('https://ecosoc.un.org/en/ngo/committee-reports')">{{"Reports of
            the Committee" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoForum="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'permanentForumOnIndigenousIssues', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'permanentForumOnIndigenousIssues', 'eListOfParticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'permanentForumOnIndigenousIssues', 'announcements']">{{"Announcements"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc','permanentForumOnIndigenousIssues', 'place']">{{"e-Place"
            | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoStats="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'StatisticalCommission', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'StatisticalCommission', 'eListOfParticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://unstats.un.org/UNSDWebsite/statcom/54')">{{"Website" |
            translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://documents-dds-ny.un.org/doc/UNDOC/GEN/N22/714/48/PDF/N2271448.pdf?OpenElement')">{{"Provisional
            Agenda" | translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://documents-dds-ny.un.org/doc/UNDOC/LTD/N22/733/70/PDF/N2273370.pdf?OpenElement')">{{"Programme
            of Work and Timetable" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoExp="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'UnitedNationsCommitteeofExpertsonGGIM', 'Announcements']">{{"Announcements"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'UnitedNationsCommitteeofExpertsonGGIM', 'eListOfParticipants']">{{"e-List
            of Participants" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoForests="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'unitedNationsForumOnForests', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'unitedNationsForumOnForests', 'SponsorshipFF']">{{"e-Sponsorship" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'unitedNationsForumOnForests', 'votingIntentions']">{{"UNFF e-Voting
            intentions" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'unitedNationsForumOnForests', 'eListOfParticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'unitedNationsForumOnForests', 'meetingsAtUNFF']">{{"UNFF Meetings" |
            translate}}</button>
          <button mat-menu-item onClick="window.open('https://www.un.org/esa/forests/index.html')">{{"Website" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ecoGeo="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'UnitedNationsGroupofExpertsonGeographicalNames', 'Announcements']">{{"Announcements"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'ecosoc', 'UnitedNationsGroupofExpertsonGeographicalNames', 'eListOfParticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://unstats.un.org/unsd/geoinfo/ungegn/default.html')">{{"Website" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #escap="matMenu" class="first-level">
          <button mat-menu-item [routerLink]="['/portal', 'escap', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item> {{"Upcoming session" | translate}}</button>
          <button mat-menu-item> {{"Recently concluded sessions" | translate}}</button>
          <button mat-menu-item> {{"Documents" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'escap', 'ecredentials']">{{"e-Credentials" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'escap', 'placeescapacpr']">{{"e-Place" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #conf="matMenu" class="first-level">
          <button mat-menu-item [matMenuTriggerFor]="confcd">{{"Conference on
            Disarmament" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confsids">{{"SIDS Conference" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="conflldc">{{"Conference on Landlocked Developing Countries" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confsalw">{{"4th UN Conference to review progress...Small Arms and
            Light Weapons" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="conficffd">{{"International Conference on Financing for
            Development"
            |
            translate}}</button>



          <button mat-menu-item [matMenuTriggerFor]="confParties">{{"17th Conference of States Parties to CRPD" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confNPT">{{"NPT Review Conference" | translate}}</button>

          <button mat-menu-item [matMenuTriggerFor]="confBBNJ">{{"BBNJ Agreement Preparatory Commission" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confUNCLOS">{{"34th Meeting of States Parties to UNCLOS" |
            translate}}</button>

          <button mat-menu-item [matMenuTriggerFor]="confmezfnwmd">{{"Conference on MEZFNWMD" | translate}}</button>


          <button mat-menu-item [matMenuTriggerFor]="pastconf">{{"Past Conferences" | translate}}</button>
        </mat-menu>

        <mat-menu class="{{lang}}" #pastconf="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'generalAssembly', 'plenary', 'speakerlist', 'espeakers']">{{"High-level Dialogue
            on Energy" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ctbt">{{"Conference on
            Facilitating the Entry into Force of the CTBT" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confPled">{{"2021 Pledging Conference" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confFifth">{{"5th United Nations Conference on the Least Developed
            Countries" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confSecond">{{"2nd HL UN Conference on South-South Cooperation" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confTrans">{{"United Nations Global Sustainable Transport
            Conference" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="confOcean">{{"2022 UN Ocean Conference" | translate }}</button>
          <button mat-menu-item [matMenuTriggerFor]="confStock">{{"Stockholm +50" | translate}}</button>

          <button mat-menu-item [matMenuTriggerFor]="confTPNW">{{"TPNW Meeting of States Parties" | translate}}</button>

          <button mat-menu-item [matMenuTriggerFor]="water">{{"2023 Water Conference" | translate}}</button>



        </mat-menu>


        <mat-menu class="{{lang}}" #confcd="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference','confcd', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'confcd', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'confcd', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confsids="matMenu" class="second-level">
          <button mat-menu-item [matMenuTriggerFor]="prepcom">{{"PrepCom 1 & 2" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="sids">{{"Conference" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #prepcom="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference','confsids', 'prepcom', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confsids', 'prepcom', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confsids', 'prepcom', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #sids="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference','confsids', 'sids', 'announcements']">{{"Announcements"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confsids', 'sids', 'ecredentials']">{{"e-Credentials"
            | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'confsids', 'sids', 'espeakers']">{{"e-Speakers"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confsids', 'sids', 'speakersld']">{{"Interactive
            dialogues" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confsids', 'sids', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://sdgs.un.org/smallislands')">{{"Website" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #conflldc="matMenu" class="second-level">
          <button mat-menu-item [matMenuTriggerFor]="prepcomlldc">{{"PrepCom 1 & 2" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="lldc">{{"Conference" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #prepcomlldc="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference','conflldc', 'prepcomlldc', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'conflldc', 'prepcomlldc', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'conflldc', 'prepcomlldc', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #lldc="matMenu" class="third-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'conflldc', 'lldc', 'espeakers']">{{"e-Speakers"
            | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'conflldc', 'lldc', 'speakersld']">{{"Thematic
            round tables" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://www.un.org/en/landlocked/programme')">{{"Website" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confsalw="matMenu" class="second-level">
          <button mat-menu-item [matMenuTriggerFor]="prepcomsalw">{{"Preparatory Committee" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="salw">{{"Review Conference" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #prepcomsalw="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference','confsalw', 'prepcomsalw', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confsalw', 'prepcomsalw', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://meetings.unoda.org/poa-salw-prepcom/programme-of-action-on-small-arms-and-light-weapons-preparatory-committee-2024')">{{"Website"
            | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #salw="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference','confsalw', 'salw', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'confsalw', 'salw', 'espeakers']">{{"e-Speakers"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confsalw', 'salw', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confsalw', 'salw', 'ecredentials']">{{"e-Credentials"
            | translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://meetings.unoda.org/poa-salw-revcon/programme-of-action-on-small-arms-and-light-weapons-review-conference-2024')">{{"Website"
            | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #conficffd="matMenu" class="second-level">
          <button mat-menu-item [matMenuTriggerFor]="prepcomicffd">{{"PrepCom" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="icffd">{{"Conference" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #prepcomicffd="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference','conficffd', 'prepcomicffd', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference','conficffd', 'prepcomicffd', 'place']">{{"e-Place" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'conficffd', 'prepcomicffd', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'conficffd', 'prepcomicffd', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #icffd="matMenu" class="third-level">
          <button mat-menu-item
            onClick="window.open('https://financing.desa.un.org/fourth-international-conference-financing-development')">{{"Website"
            | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confPled="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'pdc', 'placegaplenary']">{{"Plenary e-Place"|
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ctbt="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ctbt', 'ecredentials']">{{"e-Credentials"
            | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ctbt', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ctbt', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confParties="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'ConferenceofStatesPartiestoCRPD', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'ConferenceofStatesPartiestoCRPD', 'ecredentials']">{{"e-Credentials"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'ConferenceofStatesPartiestoCRPD', 'ConferencePlace']">{{"CRPD
            e-Place" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://www.un.org/development/desa/disabilities/')">{{"Conference
            Website" | translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://social.desa.un.org/issues/disability/cosp/17th-session-of-the-conference-of-states-parties-to-the-crpd-cosp17')">{{"17th
            session of the Conference Website" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confNPT="matMenu" class="second-level">
          <!-- <button mat-menu-item [routerLink]="['/portal', 'Conference', 'NPT', 'ecredentials']">{{"e-Credentials" |
            translate}}</button> -->
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'NPT', 'eListOfParticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'NPT', 'espeakers']">{{"e-Speakers"
            | translate}}</button>
          <!-- <button mat-menu-item onClick="window.open('https://www.un.org/en/conferences/npt2020')">{{"Website" |
            translate}}</button> -->
        </mat-menu>
        <mat-menu class="{{lang}}" #confFifth="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ldc', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ldc', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ldc', 'speakersrt']">{{"High-level thematic
            roundtables" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ldc', 'ecredentials']">{{"e-Credentials" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ldc', 'elistofparticipants']">{{"e-List of
            Participants" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ldc', 'placeconfldc']">{{"Conference on LDC
            e-Place" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ldc', 'ldcmeetings']">{{"Conference on LDC
            Meetings" | translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://www.un.org/ohrlls/content/fifth-united-nations-conference-least-developed-countries-ldc5')">{{"Website"
            | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confSecond="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', '2ndHLUNConferenceonSouthSouthCooperation', 'ConferencePlace']">{{"Conference
            e-Place" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confBBNJ="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'BBNJIntergovernmentalConference', 'ecredentials']">{{"e-Credentials"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'BBNJIntergovernmentalConference', 'elistofparticipants']">{{"e-List
            of participants" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confUNCLOS="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'unclos', 'ecredentials']">{{"e-Credentials" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confTrans="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'gstc', 'eListOfParticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'gstc', 'espeakers']">{{"e-List of Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'gstc', 'gstcmeetings']">{{"e-Meetings" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confOcean="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ocean', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ocean', 'ecredentials']">{{"e-Credentials" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ocean', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ocean', 'speakersld']">{{"Interactive
            dialogues" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'ocean', 'elistofparticipants']">{{"e-List of
            Participants" | translate}}</button>
          <button mat-menu-item
            (click)="onItemSelected('https://www.un.org/-$lang$-/conferences/ocean2022', $event)">{{"Website" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confStock="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'stockholm50', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'stockholm50', 'ecredentials']">{{"e-Credentials" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'stockholm50', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'stockholm50', 'speakersld']">{{"Leadership
            dialogues" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'stockholm50', 'elistofparticipants']">{{"e-List
            of
            Participants" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://www.stockholm50.global/')">{{"Website" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confTPNW="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'tpnw', 'place']">{{"TPNW e-Place" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'tpnw', 'ecredentials']">{{"e-Credentials" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'tpnw', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'tpnw', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item onClick="window.open('https://meetings.unoda.org/tpnw/tpnw-msp-2023')">{{"Website" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #confmezfnwmd="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'confmezfnwmd', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confmezfnwmd', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confmezfnwmd', 'ecredentials']">{{"e-Credentials" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'Conference', 'confmezfnwmd', 'announcements']">{{"Announcements" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #water="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'water', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'water', 'speakersld']">{{"Interactive
            dialogues" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'water', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'Conference', 'water', 'ecredentials']">{{"e-Credentials"
            | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #geneva="matMenu" class="first-level">
          <button mat-menu-item [matMenuTriggerFor]="apmbc">{{"Anti-Personnel Mine Ban Convention" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="gencd">{{"Conference on Disarmament" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ccw">{{"Convention on Certain Conventional Weapons" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="ccm">{{"Convention on Cluster Munitions" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="hrc"> {{"Human Rights Council" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #apmbc="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'geneva', 'apmbc', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ccw="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'geneva', 'ccw', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ccm="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'geneva', 'ccm', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #gencd="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'geneva','gencd', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'geneva', 'gencd', 'elistofparticipants']">{{"e-List
            of Participants" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'geneva', 'gencd', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #hrc="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'geneva', 'HumanRightsCouncil', 'sponsorshipHRC']">{{"e-Sponsorship" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'geneva', 'HumanRightsCouncil', 'GuidelinesForHRCMainSponsors']">{{"Guidelines
            for main sponsors Human Rights Council e-Sponsorship" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'geneva', 'HumanRightsCouncil', 'GuidelinesForHRCCoSponsors']">{{"Guidelines
            for co-sponsors" | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'geneva', 'HumanRightsCouncil', 'eRecordedVotesHRC']">{{"e-Recorded
            votes" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #vienna="matMenu" class="first-level">
          <button mat-menu-item [matMenuTriggerFor]="ahcc">{{"Ad Hoc Committee on Cybercrime" |
            translate}}</button>

          <button mat-menu-item [matMenuTriggerFor]="viennaCnd">{{"Commission on Narcotic Drugs" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="viennaCcpcj">{{"Commission on Crime Prevention and Criminal
            Justice" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="viennaUntoc">{{"Conference of the Parties to the United Nations
            Convention against Transnational Organized Crime" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #ahcc="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'vienna', 'ahcc', 'sponsorshipAHCC']">{{"e-Proposals
            (Sponsorship
            and Submission)" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #viennaCnd="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'vienna', 'cnd', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'vienna', 'cnd', 'sponsorshipCND']">{{"e-Proposals
            (Sponsorship and Submission)" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #viennaCcpcj="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'vienna', 'ccpcj', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'vienna', 'ccpcj', 'sponsorshipCCPCJ']">{{"e-Proposals
            (Sponsorship and Submission)" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #viennaUntoc="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'vienna', 'untoc', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'vienna', 'untoc', 'sponsorshipUNTOC']">{{"e-Proposals
            (Sponsorship and Submission)" | translate}}</button>
        </mat-menu>

        <mat-menu class="{{lang}}" #nairobi="matMenu" class="first-level">
          <button mat-menu-item [matMenuTriggerFor]="unep">{{"UNEP" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #unep="matMenu" class="second-level">
          <button mat-menu-item [matMenuTriggerFor]="unea">{{"UN Environment Assembly" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="cpr">{{"Committee of Permanent Representatives" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #unea="matMenu" class="third-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'nairobi', 'unep',  'unea', 'announcements']">{{"Announcements" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'nairobi', 'unep',  'unea', 'place']">{{"e-Place" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'nairobi', 'unep',  'unea', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'nairobi','unep', 'unea', 'esubmit']">{{"e-Submit" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #cpr="matMenu" class="third-level">
          <button mat-menu-item [routerLink]="['/portal', 'nairobi', 'unep', 'cpr', 'announcements']">{{"Announcements"
            |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'nairobi', 'unep', 'cpr', 'place']">{{"e-Place" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'nairobi', 'unep','cpr', 'esubmit']">{{"e-Submit" |
            translate}}</button>
        </mat-menu>

        <mat-menu #unep="matMenu" class="second-level">
          <button mat-menu-item [matMenuTriggerFor]="unea">{{"UN Environment Assembly" | translate}}</button>
        </mat-menu>
        <mat-menu #unea="matMenu" class="third-level">
          <button mat-menu-item [routerLink]="['/portal', 'unep', 'unea', 'espeakers']">{{"e-Speakers" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'unep', 'unea', 'esubmit']">{{"e-Submit" |
            translate}}</button>
        </mat-menu>
        <mat-menu #protocol="matMenu" class="first-level">
          <a href="" target="_blank">
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/dgacm/-$lang$-/content/protocol', $event)">{{"Protocol and
              Liaison Service" | translate}}</button>
          </a>
          <button mat-menu-item [routerLink]="['/portal', 'protocol', 'emissionstaff']">{{"e-Mission registration of
            personnel" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'protocol', 'eregistration']">{{"Registration to meetings and
            conferences" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'protocol', 'tickets']">{{"Reservation for courtesy tickets
            for the general debate" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'protocol', 'staffregistration']">{{"Conference registration
            for UN secretariat and entities" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'protocol', 'TempDecalDriverRequests']">{{"Temporary pick up -
            drop off permits" | translate}}</button>
          <span *ngIf="environment.branch !== 'PROD'"><button mat-menu-item
              [routerLink]="['/portal', 'protocol', 'countries']">{{"Member States" |
              translate}}</button></span>
          <button mat-menu-item onClick="window.open('https://protocol.un.org/dgacm/pls/site.nsf/BlueBook.xsp')">{{"Blue
            Book" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://bluebook.unmeetings.org')">{{"e-Blue Book" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #protocolUNSD="matMenu" class="first-level">
          <a href="" target="_blank">
            <button mat-menu-item
              (click)="onItemSelected('https://www.un.org/dgacm/-$lang$-/content/protocol', $event)">{{"Protocol and
              Liaison Service" | translate}}</button>
          </a>
          <button mat-menu-item [routerLink]="['/portal', 'protocol', 'staffregistration']">{{"Conference registration
            for UN secretariat and entities" | translate}}</button>
        </mat-menu>
        <mat-menu #resources="matMenu" class="first-level">
          <button mat-menu-item [matMenuTriggerFor]="access">{{"Access Management" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="daglibrary">{{"Dag Hammarskjöld Library" |
            translate}}</button>
          <button mat-menu-item
            onClick="window.open('https://www.un.org/en/ga/76/pdf/Delegates_Handbook_2021_EN.pdf')">{{"Delegates
            Handbook" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="documents">{{"Documents" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'resources', 'eCorrespondenceEmail']">{{"e-Correspondence" |
            translate}}</button>
          <button mat-menu-item onClick="window.open('https://conferences.unite.un.org/gMeets')">{{"gMeets (OSS)" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="meetings">{{"Meetings" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'whatsNew', 'spokespersonMorningHeadlines']">{{"Spokesperson's
            Morning
            Headlines" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'resources', 'telephoneDirectory']">{{"Telephone Directory /
            Yellow Pages" | translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="trainings"
            [routerLink]="['/portal', 'resources', 'telephoneDirectory']">{{"Trainings" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="vacancies"
            [routerLink]="['/portal', 'resources', 'telephoneDirectory']">{{"Vacancies" |
            translate}}</button>

          <!-- <button mat-menu-item [routerLink]="['/portal', 'resources', 'telephoneDirectory']">{{"Telephone Directory /
            Yellow Pages" | translate}}</button> -->
          <!-- <button mat-menu-item [routerLink]="['/portal', 'resources', 'meetingRoom']">{{"Meeting Room / Area Locator" |
            translate}}</button> -->

          <!-- <button mat-menu-item onClick="window.open('https://library.un.org/content/index-proceedings-0')">{{"Index to
            Proceedings" | translate}}</button> -->
        </mat-menu>
        <mat-menu class="{{lang}}" #access="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'resources', 'access' , 'accessNY']">{{"Access
            Management - New York" |
            translate}}</button>
          <!-- <span *ngIf="environment.branch !== 'PROD'"><button mat-menu-item
              [routerLink]="['/portal', 'resources','access' , 'accessBangkok']">{{"Access Management -
              Bangkok" |
              translate}}</button></span> -->
          <button mat-menu-item [routerLink]="['/portal', 'resources','access' , 'accessGeneva']">{{"Access
            Management - Geneva" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'resources','access' , 'accessNairobi']">{{"Access
            Management - Nairobi"
            |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'resources','access' , 'accessVienna']">{{"Access
            Management - Vienna" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #daglibrary="matMenu" class="second-level">
          <button mat-menu-item onClick="window.open('https://ask.un.org/')">{{"Ask DAG Library" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://digitallibrary.un.org/')">{{"UN Digital Library" |
            translate}}</button>
          <button mat-menu-item onClick="window.open('https://digitallibrary.un.org/search?ln=en&cc=Voting+Data')">{{"UN
            Digital Library Voting Data" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #documents="matMenu" class="second-level">
          <button mat-menu-item
            [routerLink]="['/portal', 'resources', 'documents', 'documentsIssuedAtUNHQToday']">{{"Documents
            issued at UNHQ Today" | translate}}</button>
          <button mat-menu-item onClick="window.open('http://undocs.org/')">{{"e-Subscription to United Nations
            Documents" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #meetings="matMenu" class="second-level">
          <a [href]="'javascript:void(0);'" (click)="onItemSelected('https://www.un.org/calendar/-$lang$-', $event)"
            target="_blank">
            <button mat-menu-item>{{"Calendar of Conferences and Meetings" | translate}}</button>
          </a>
          <button mat-menu-item [routerLink]="['/portal', 'resources', 'meetings', 'meetingsAtUNHQ']">{{"Meetings at
            UNHQ" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'resources', 'meetings', 'meetingRoom']">{{"Meeting Room /
            Area Locator" |
            translate}}</button>
          <a href="https://forms.office.com/e/X5zhX2Kx12" target="_blank">
            <button mat-menu-item>{{"Survey on Conference Services" | translate}}</button>
          </a>
        </mat-menu>
        <mat-menu class="{{lang}}" #trainings="matMenu" class="second-level">
          <button mat-menu-item onClick="window.open('https://hr.un.org/page/language-programmes-unhq')">{{"Language
            Training" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://un.libcal.com/')">{{"Library Training" |
            translate}}</button>
          <button mat-menu-item onClick="window.open('https://unitar.org/event/')">{{"UNITAR Events" |
            translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #vacancies="matMenu" class="second-level">
          <button mat-menu-item
            (click)="onItemSelected('https://www.un.org/sg/-$lang$-/vacancies/index.shtml', $event)">{{"Senior Level
            Vacancies" | translate}}</button>
          <button mat-menu-item onClick="window.open('https://jobs.unicsc.org/')">{{"ICSC Vacancies" |
            translate}}</button>
        </mat-menu>




        <mat-menu class="{{lang}}" #secre="matMenu" class="first-level">
          <button mat-menu-item [matMenuTriggerFor]="secreAuth">{{"Authorization Admin" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'userslist']">{{"List of users" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'proposals']">{{"Action module" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="secreReg">{{"e-Registration" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'InscriptionSetup']">{{"Inscription Setup" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'SecretariatListofSpeakers']">{{"List of
            Speakers" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'gd']">{{"List of Speakers - General Debate"
            | translate}}</button>
          <span *ngIf="environment.branch !== 'PROD'"><button mat-menu-item
              [routerLink]="['/portal', 'administration', 'statements']">{{"List of Statements" |
              translate}}</button></span>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'ListOfParticipantsSetup']">{{"List of
            Participants Setup" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'electionssetup']">{{"Candidatures
            and Elections Setup" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'esubmitsetup']">{{"e-Submit Setup" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'eRecordedVotesSetup']">{{"e-Recorded Votes
            Setup" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'eRecordedVotesSetupHRC']">{{"e-Recorded
            Votes Setup HRC" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'eVotes']">{{"e-Votes" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'votingIntentions']">{{"Voting Intentions" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'emailtemplates']">{{"Email Templates" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'e-blue-book-precis-writers']">{{"e-BlueBook
            for precis-writers" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'lookup']">{{"Lookups" |
            translate}}</button>
          <button mat-menu-item [matMenuTriggerFor]="secreTech">{{"Technical Support" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #secreAuth="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'auth', 'accessAdmin']">{{"Authorization
            Admin" | translate}}</button>
          <span *ngIf="environment.branch !== 'PROD'"><button mat-menu-item
              [routerLink]="['/portal', 'administration', 'auth', 'accessmanagementsec']">{{"Entities
              authorization" | translate}}</button></span>
        </mat-menu>
        <mat-menu class="{{lang}}" #secreReg="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'eregistrations', 'media']">{{"Media" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'eregistrations', 'security']">{{"Security"
            | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'eregistrations', 'passandid']">{{"Pass and
            ID" | translate}}</button>
        </mat-menu>
        <mat-menu class="{{lang}}" #secreTech="matMenu" class="second-level">
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'technicalSupport', 'cache']">{{"Cache" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'administration', 'technicalSupport', 'searchindex']">{{"Search-Index" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'administration', 'technicalSupport', 'search-index-elastic']">{{"Bulk Search
            Index" | translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'technicalSupport', 'bulk']">{{"Bulk" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'technicalSupport', 'meta']">{{"Meta" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'administration', 'technicalSupport', 'datasync-mpm']">{{"Datasync MPM" |
            translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'administration', 'technicalSupport', 'import-e-registration-data']">{{"Import
            e-Registration Data" |
            translate}}</button>
          <button mat-menu-item [routerLink]="['/portal', 'administration', 'technicalSupport', 'version']">{{"Version"
            | translate}}</button>
          <button mat-menu-item
            [routerLink]="['/portal', 'administration', 'technicalSupport', 'releaseNotes']">{{"Release Notes" |
            translate}}</button>
        </mat-menu>
        <div class="{{environment.branch=='UAT' ? 'pcoded-main-container-pink' : 'pcoded-main-container'}}"
          [style.margin-top]="headerFixedMargin">

          <div class="pcoded-wrapper">
            <div class="pcoded-content">
              <app-breadcrumbs></app-breadcrumbs>
              <div class="pcoded-inner-content cst-pcoded-inner-content">

                <div class="main-body">
                  <div class="page-wrapper">
                    <router-outlet>
                      <title></title>
                    </router-outlet>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="{{environment.branch=='UAT' ? 'footerInfo-pink' : 'footerInfo'}}">
          <div>
            <p>
              <a id="view:_id1:_id2:link1" href="javascript:void(0);" (click)="nav('CopyRight')">{{ "Copyright" |
                translate }}
              </a>&nbsp;|&nbsp;
              <a id="view:_id1:_id2:link2" href="javascript:void(0);" (click)="nav('TermsOfUse')">{{ "Terms of Use" |
                translate }}</a>&nbsp;|&nbsp;
              <a id="view:_id1:_id2:link3" href="javascript:void(0);" (click)="nav('PrivacyNotice')">{{ "Privacy Notice"
                | translate }}</a>&nbsp;|&nbsp;
              <a id="view:_id1:_id2:link4" href="javascript:void(0);" (click)="nav('FraudAlert')">{{ "Fraud Alert" |
                translate }}</a>&nbsp;|&nbsp;
              <!-- <a id="view:_id1:_id2:link5" href="javascript:void(0);"
                (click)="nav('Help')">{{ "Help" | translate }}</a>&nbsp;|&nbsp; -->
              <a id="view:_id1:_id2:link6" href="javascript:void(0);" (click)="nav('ContactUs')">{{ "Contact Us" |
                translate }}</a>
            </p>
          </div>
          <p>{{ "Copyright 2024 © United Nations" | translate }}- v{{environment.version}}</p>
        </div>

        <div class="loader-mask" [hidden]="!showLoader">
          <div class="loader-block">
            <svg id="loader2" viewBox="0 0 100 100">
              <circle id="circle-loader2" cx="50" cy="50" r="45"></circle>
            </svg>
          </div>
        </div>
      </div>

      <div *ngIf="withoutTheme">
        <router-outlet></router-outlet>
      </div>
    </div>

    <app-modal-custom [modalID]="'effect-12'" [modalClass]="'md-effect-12'" class="cus-authenticated-modal"
      *ngIf="!isAuthenticated">
      <div>
        <p>{{ "Session Timeout. Please login again." | translate }}</p>
        <button (click)="closeNotLogedinModal($event)" class="btn btn-danger md-close">{{ "Back to sign in" | translate
          }}</button>
      </div>
    </app-modal-custom>
  </div>
  <simple-notifications [options]="options"></simple-notifications>
</div>