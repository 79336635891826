import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
// import { temporaryDeclaration } from '@angular/compiler/src/compiler_util/expression_converter';

@Injectable()
export class ProposalsService {
  private JWT = new BehaviorSubject<any>('');
  private k: number;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  setJWT(jwt) {
    this.JWT.next(jwt);
  }

  getJWT() {
    return this.JWT.asObservable();
  }

  getEntities() {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getentities`, { headers: this.headers });
  }

  getServerTime() {
    return this.http.get(`${environment.usersApi}/api/getservertime`, { headers: this.headers });
  }

  getiGAEADLookupListByCode(code) {
    return this.http.get(`${environment.proposalsmpmApi}/api/proposalsmpm/getlookups/${code}`, { headers: this.headers });
  }

  getLookupListByCode(code) {
    return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, { headers: this.headers });
  }

  getAgendaByBodyBySession(param) {
    return this.http.post(`${environment.proposalsmpmApi}/api/proposalsmpm/getagendabybodybysession`, param, { headers: this.headers });
  }

  createProposal(param) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/createproposal`, param, { headers: this.headers });
  }

  createStage(tmpData) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/createstage`, tmpData, { headers: this.headers });
  }

  createGroup(param) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/creategroup`, param, { headers: this.headers });
  }

  getProposalsByBodyByStatus(param) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/getproposalsbybodybystatus`, param, { headers: this.headers });
  };

  getProposalsByBodyByStatusDelegates(param) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/getproposalsbybodybystatusdelegates`, param, { headers: this.headers });
  };

  getProposalByID(docID) {
    return this.http.get(`${environment.proposalsApi}/api/proposals/getproposalbyid/${docID}`, { headers: this.headers });
  }

  getProposalsByIDs(param) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/getproposalsbyids`, param, { headers: this.headers });
  }

  getStageByStageID(param) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/getstagebystageid`, param, { headers: this.headers });
  };

  getDocumentsByUser(param) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/getdocumentsbyuser`, param, { headers: this.headers });
  }

  getDocumentByStageID(docID) {
    return this.http.get(`${environment.proposalsApi}/api/proposals/getdocumentbystageid/${docID}`, { headers: this.headers });
  }

  getGroupsByUser(param) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/getgroupsbyuser`, param, { headers: this.headers });
  }

  updateProposalByID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/updateproposalbyid`, tmpData, { headers: this.headers });
  }

  updateProposalTitleByID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/updateproposaltitlebyid`, tmpData, { headers: this.headers });
  }

  updateStageByStageID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/updatestagebystageid`, tmpData, { headers: this.headers });
  }

  updateStageStatusByStageID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/updatestagestatusbystageid`, tmpData, { headers: this.headers });
  }

  updateStageImplicationsByStageID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/updatestageimplicationsbystageid`, tmpData, { headers: this.headers });
  }

  uploadAttachmentByDocumentID(param) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/uploadattachmentbydocumentid`, param, { headers: this.headers });
  }

  releaseOralStatementByStageID(param) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/releaseoralstatementbystageid`, param, { headers: this.headers });
  }

  updateGroupByID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/updategroupbyid`, tmpData, { headers: this.headers });
  }

  deleteStageByStageID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/deletestagebystageid`, tmpData, { headers: this.headers });
  }

  submitDocumentForReviewByID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/submitdocumentforreviewbyid`, tmpData, { headers: this.headers });
  }

  reviewDocumentByID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/reviewdocumentbyid`, tmpData, { headers: this.headers });
  }

  deleteProposalByID(tmpData) {
    return this.http.put(`${environment.proposalsApi}/api/proposals/deleteproposalbyid`, tmpData, { headers: this.headers });
  }

  updateMeetingByStageID(param) {
    return this.http.post(`${environment.proposalsmpmApi}/api/proposalsmpm/updatemeetingbystageid`, param, { headers: this.headers });
  }








  // setup authorization header
  onBeforeSend = function (event, uploader, type, msg) {
    event.xhr.setRequestHeader('Authorization', this.JWT.getValue());
    if (type == "validate") {
      uploader.files.forEach(file => {
        this.getImageDimension(file, (result) => {
          if (result == false) {

            msg.push("Attachment should be of jpg or jpeg or pjpeg format, size less than 240KB, between 600x600 and 1200x1200 pixels");

            event.xhr.abort();
          }
          else {
            msg.push("");
          }
        });
      });
    }
    else {
      msg.push("");
    }

  };



  onUpload(event, list, type, msgs) {
    // this.showMsg(msgs, 'upload', true, type);

    event.files.forEach(file => {
      if (type == 'validate') {
        if (list.length > 0) {
          list.splice(0, 1);
        }
      }

      list.push({
        originalname: file.name,
        uploadname: file.name


      });


    });
  };


  downloadFile = function (list: any, index: any, param3?: any, param4?: any) {
    const body = { filename: list[index].uploadname };
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    this.http.post(`${environment.proposalsApi}/api/proposals/downloadfile`, body, options).subscribe(
      data => {
        this.saveFile(data, list[index].originalname);
      }
    );
  };

  async downloadOnlyFile(uploadName: string, originalName: string) {
    const body = { filename: uploadName };
    const options: any = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/pdf' }),
      responseType: 'blob'
    };

    const data = await this.http.post(`${environment.proposalsApi}/api/proposals/downloadfile`, body, options).toPromise();
    this.saveFile(data, originalName, '_blank');
  };

  removeFile = function (list, index) {
    list.splice(index, 1);
  };

  async updateAttachment(originalList, currentList, uploader, ID) {
    if (!originalList) {
      originalList = [];
    }

    // Delete
    if (originalList.length) {
      for (const originalAttachment of originalList) {
        let found = false;
        currentList.forEach((currentAttachment) => {
          if (currentAttachment.uploadname === originalAttachment.uploadname) {
            found = true;
          }
        });
        if (!found) {
          await this.deleteFile(originalAttachment.uploadname).then(
            result => {

            },
            error => {
              return Promise.reject('Delete file failed');
            }
          );
        }
      }
    }

    // Upload
    for (const file of uploader.files) {
      await this.uploadFile(file, ID).then(
        result => {
          const date = new Date();
          currentList.push({
            originalname: result['originalname'],
            uploadname: result['uploadname'],
            uploadtime: date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' }) + ' ' + date.getFullYear(),
          });
        },
        error => {
          return Promise.reject('Upload file failed');
        }
      );
    }
    uploader.files = [];

    // return
    return Promise.resolve();
  }

  deleteFile = function (name) {
    const body = { filename: name };

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'blob'
    };
    return this.http.post(`${environment.proposalsApi}/api/proposals/deletefile`, body, options).toPromise();
  };

  uploadFile(file, ID) {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('mongoID', ID);

    return this.http.post(`${environment.proposalsApi}/api/proposals/uploadfiles`, formData).toPromise();
  }

  saveFile(response, filename, target?: string) {
    var result = filename.match('.pdf');
    if (result) {
      let blob = new Blob([response], { type: 'application/pdf' });
      var blobURL = URL.createObjectURL(blob);
      var open = window.open(blobURL, '_blank');
      if (open == null || typeof (open) == 'undefined') {
        let a = document.createElement('a');
        a.target = target || '_self';
        a.href = blobURL;
        a.click();
      };
    } else {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      saveAs(blob, filename);
    }
  }

  extractPBI(body: any) {
    return this.http.post(`${environment.proposalsApi}/api/proposals/extractPBI`, body, { headers: this.headers });
  };

}
