import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface MenuItem {
  label: string;
  disabled?: boolean;
  iconName: string;
  url?: string;
  children?: MenuItem[];
}

/* export interface Menu {
  name: string;
  main: MainMenuItems[];
} */

const MENUITEMS = [
  // {
  //   state: "",
  //   short_label: "O",
  //   name: `What's New`,
  //   type: "sub",
  //   icon: "icon-award",
  //   children: [
  //     {
  //       state: "",
  //       name: "Journal of the United Nations",
  //       type: "external",
  //       external: "https://journal.un.org/-$lang$-",
  //       target: true,
  //     },
  //     // {
  //     //   state: "secretarygeneralletters",
  //     //   name: "Secretary-General Letters",
  //     // },
  //     {
  //       state: "eCorrespondenceEmail",
  //       name: "e-Correspondence",
  //     },
  //     {
  //       state: "spokespersonMorningHeadlines",
  //       name: `Spokesperson's Morning Headlines`,
  //     },
  //     {
  //       state: "meetingsAtUNHQ",
  //       name: `Meetings at UNHQ`,
  //     },
  //     {
  //       state: "",
  //       name: `Calendar of Conferences and Meetings`,
  //       type: "external",
  //       external: 'https://www.un.org/calendar/-$lang$-',
  //       target: true
  //     },
  //     {
  //       state: "surveyOnConferenceServices",
  //       name: `Survey on Conference Services`,
  //       type: "external",
  //       external: 'https://forms.office.com/e/X5zhX2Kx12',
  //       target: true
  //     },
  //     {
  //       state: "",
  //       name: "UN News Centre",
  //       type: "external",
  //       external: "https://news.un.org/-$lang$-/",
  //       target: true,
  //     },
  //     {
  //       state: "",
  //       name: `Meetings Coverage & Press Releases`,
  //       type: "external",
  //       external: "https://www.un.org/press/-$lang$-",
  //       target: true,
  //     },
  //     {
  //       state: "documentsIssuedAtUNHQToday",
  //       name: `Documents issued at UNHQ Today`,
  //     },
  //     // {
  //     //   state: "eCorrespondenceEmail",
  //     //   name: `e-Correspondence Email`,
  //     // },
  //   ]
  // },
  {
    state: "generalAssembly",
    short_label: "O",
    name: "General Assembly",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "plenary",
        short_label: "M",
        name: "Plenary",
        type: "sub",
        children: [
          {
            state: "schedule",
            name: "Schedule",
            type: "sub",
            children: [
              {
                state: "",
                name: "Schedule of meetings",
                type: "external",
                external: "https://www.un.org/en/ga/info/meetings/79schedule.shtml",
                target: true,
              },
            ],
          },
          {
            state: "agenda",
            name: "Agenda",
            type: "sub",
            children: [
              {
                state: "",
                name: "79th session",
                type: "external",
                external: "https://www.un.org/en/ga/79/agenda/",
                target: true,
              },
              {
                state: "",
                name: "Status of consideration of agenda items",
                type: "external",
                external: "https://pep.unmeetings.org/reports/ga79_agendaStatus.html",
                target: true,
              },
              {
                state: "annotatedagenda",
                name: "Annotated agenda",
              },
            ],
          },
          {
            state: "resolutionsDecisions",
            name: "Resolutions and Decisions",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/plenary",
                target: false,
              },
              {
                state: "sponsorshipP",
                name: "e-Proposals (Sponsorship and Submission)",
              },
              {
                state: "eRecordedVotes",
                name: "e-Recorded votes",
              },
              {
                state: "edecisions",
                name: "e-Decisions",
              },
              {
                state: "votingIntentions",
                name: "e-Voting intentions",
              },
              {
                state: "",
                name: "List of resolutions",
                type: "external",
                external: "https://www.un.org/en/ga/79/resolutions.shtml",
                target: true,
              },
              {
                state: "",
                name: "Submission guidelines",
                type: "external",
                external:
                  "https://www.un.org/en/ga/pdf/guidelines_preparation_co-sponsorship_proposals_submission_GA76.pdf",
                target: true,
              },
              {
                state: "",
                name: "Editing of Proposals at the United Nations",
                type: "external",
                external:
                  "https://www.un.org/en/ga/pdf/guidelines_submit_draft_proposals_jan2017.pdf",
                target: true,
              },
              // {
              //   state: "",
              //   name: "Tutorial on e-Recorded Votes",
              // },
            ],
          },
          {
            state: "speakerlist",
            name: "Speakers list",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersgd",
                name: "General Debate speaker registration",
              },

            ],
          },
          {
            state: "documents",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "DGACMServices",
                name:
                  "Documentation services for Delegates provided by DGACM",

              },
            ],
          },
          {
            state: "placegaplenary",
            name: "Plenary e-Place",
          },
          {
            state: "gaannouncements",
            name: "Plenary Announcements",
          },
          {
            state: "esubmit",
            name: "e-Submit",
          },
          {
            state: "elections",
            name: "e-Candidatures and Elections",
          },
          {
            state: "candiweb",
            name: "Candiweb",
          },
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
          {
            state: "faqs",
            name: "FAQs",
          },
          {
            state: "stvm",
            name: "Services and tools for virtual meetings",
            type: "sub",
            children: [
              {
                state: "PowerPoint",
                name: "PowerPoint",

              },
              {
                state: "PDF",
                name: "PDF",

              },
            ],
          },
        ],
      },
      // {
      //   state: "HighlevelPoliticalForumonSustainableDevelopment",
      //   short_label: "M",
      //   name: "High-level Political Forum on Sustainable Development",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "eListOfParticipants",
      //       name: "e-List of participants",
      //     },
      //     {
      //       state: "espeakers",
      //       name: "e-Speakers",
      //     },
      //     {
      //       state: "speakersmrt",
      //       name: "SDG Summit Leader's Dialogue",
      //     },
      //   ],
      // },
      // {
      //   state: "ffd",
      //   short_label: "M",
      //   name: "2023 High-level Dialogue on Financing for Development",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Interactive roundtables",
      //     },
      //   ],
      // },
      // {
      //   state: "imrf",
      //   short_label: "M",
      //   name: "International Migration Review Forum",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakers",
      //       name: "e-Speakers",
      //       type: "sub",
      //       children: [
      //         {
      //           state: "espeakers",
      //           name: "General debate",
      //         },
      //         {
      //           state: "speakersld",
      //           name: "Round tables",
      //         },
      //         {
      //           state: "espeakers",
      //           name: "Policy debate",
      //         },
      //       ],
      //     },
      //     {
      //       state: "elistofparticipants",
      //       name: "e-List of participants",
      //     },
      //   ],
      // },
      // {
      //   state: "pppr",
      //   short_label: "M",
      //   name: "HLM on pandemic preparedness prevention and response",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      // {
      //   state: "uhc",
      //   short_label: "M",
      //   name: "HLM on universal health coverage",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      // {
      //   state: "hlmtb",
      //   short_label: "M",
      //   name: "HLM on the fight against tuberculosis",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      // {
      //   state: "hlmar",
      //   short_label: "M",
      //   name: "High-level meeting on antimicrobial resistance",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "espeakers",
      //       name: "e-Speakers",
      //     },
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      // {
      //   state: "sotf",
      //   short_label: "M",
      //   name: "Summit of the Future",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Interactive dialogues",
      //     },
      //   ],
      // },
      // {
      //   state: "hlmaetpslr",
      //   short_label: "M",
      //   name: "High-level meeting on addressing the existential threats posed by sea level rise",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "espeakers",
      //       name: "e-Speakers",
      //     },
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      {
        state: "firstCommittee",
        short_label: "",
        name: "First Committee",
        type: "sub",
        children: [
          {
            state: "pw",
            name: "Programme of work",
            type: "sub",
            children: [
              {
                state: "",
                name:
                  "Programme of work and timetable of the First Committee",
                type: "external",
                external:
                  "https://www.un.org/en/ga/first/79/pdf/A_C1_79_CRP1.pdf",
                target: true,
              },
              {
                state: "",
                name: "Timetable for thematic discussions",
                type: "external",
                external:
                  "https://www.un.org/en/ga/first/79/pdf/A_C1_79_CRP2.pdf",
                target: true,
              },
              {
                state: "",
                name: "Side events calendar",
                type: "external",
                external:
                  "https://meetings.unoda.org/ga-c1/general-assembly-first-committee-seventy-ninth-session-2024",
                target: true,
              },


            ],
          },
          {
            state: "agenda",
            name: "Agenda",
            type: "sub",
            children: [
              {
                state: "",
                name: "Allocation of agenda items",
                type: "external",
                external: "http://undocs.org/en/A/C.1/79/1",
                target: true,
              },
            ],
          },
          {
            state: "draftProposals",
            name: "Draft proposals",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/c1",
                target: false,
              },
              {
                state: "sponsorshipC1",
                name: "First Committee e-Sponsorship",
              },
              {
                state: "votingIntentions",
                name: "First Committee e-Voting intentions",
              },
              {
                state: "GuidelinesForMainSponsors",
                name: "Guidelines for main sponsors",
              },
              {
                state: "GuidelinesForCoSponsors",
                name: "Guidelines for co-sponsors",
              },
              {
                state: "ProcedureForDraftSubmissions",
                name: "Procedure for draft submissions",
              },
            ],
          },
          {
            state: "speakerlist",
            name: "Speaker List",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "documents",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "",
                name: "Status of documentation",
                type: "external",
                external:
                  "https://undocs.org/Home/Mobile?FinalSymbol=A%2fC.1%2f79%2fINF%2f1&Language=E&DeviceType=Desktop&LangRequested=False",
                target: true,
              },
              // {
              //   state: "",
              //   name: "First Committee resolutions and decisions",
              //   type: "external",
              //   external: "https://gafc-vote.un.org/",
              //   target: true,
              // },
              {
                state: "",
                name:
                  "Disarmament library including resolutions and decisions",
                type: "external",
                external: "https://www.un.org/disarmament/publications/library/",
                target: true,
              },
              {
                state: "",
                name: "Revitalization of the work of the General Assembly",
                type: "external",
                external: "https://undocs.org/-$lang$-/A/C.1/79/INF/4",
                target: true,
              },
            ],
          },
          {
            state: "bureauSecretariatContacts",
            name: "Bureau - Secretariat",
            type: "sub",
            children: [
              {
                state: "",
                name: "Bureau members",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/first/79/bureau.shtml",
                target: true,
              },
              {
                state: "",
                name: "Secretariat - contact details",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/first/secretariat.shtml",
                target: true,
              },
            ],
          },
          {
            state: "eListOfParticipants",
            name: "e-List of participants",
          },
          {
            state: "esubmit",
            name: "e-Submit",
          },
          {
            state: "",
            name: "First Committee webcast",
            type: "external",
            external:
              "http://webtv.un.org/meetings-events/general-assembly/main-committees/1st-committee/",
            target: true,
          },
          {
            state: "",
            name: "First Committee website",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/first/index.shtml",
            target: true,
          },
          {
            state: "place",
            name: "First Committee e-Place",
          },
          {
            state: "announcements",
            name: "First Committee Announcements",
          },
        ],
      },
      {
        state: "secondCommittee",
        short_label: "M",
        name: "Second Committee",
        type: "sub",
        children: [
          {
            state: "c2schedule",
            name: "Schedule",
            type: "sub",
            children: [
              {
                state: "",
                name: "Second Committee meetings",
                type: "external",
                external:
                  "https://edelegate.un.int/portal/generalAssembly/secondCommittee/c2schedule/meetingsAtUNHQC2",
                target: true,
              },
              {
                state: "",
                name: "Calendar of meetings",
                type: "external",
                external:
                  "https://igov.un.org/ga/c2/79/calendar",
                target: true,
              },
              {
                state: "",
                name: "Organization of work",
                type: "external",
                external: "http://undocs.org/en/A/C.2/79/L.1",
                target: true,
              },
              {
                state: "",
                name: "Special events schedule",
                type: "external",
                external: "https://igov.un.org/ga/c2/79/meetings",
                target: true,
              },
              {
                state: "",
                name: "UN Journal",
                type: "external",
                external: "https://journal.un.org/-$lang$-",
                target: true,
              },
            ],
          },
          {
            state: "draftProposals",
            name: "Draft proposals",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/c2",
                target: false,
              },
              {
                state: "sponsorshipC2",
                name: "e-Proposals (Sponsorship and Submission)",
              },
              {
                state: "votingIntentions",
                name: "e-Voting intentions",
              },
              {
                state: "",
                name: "List of draft proposals",
                type: "external",
                external:
                  "https://igov.un.org/ga/c2/79/documents?menu=proposals",
                target: true,
              },
              {
                state: "",
                name: "Status of action on draft proposals",
                type: "external",
                external:
                  "",
                target: true,
              },
              {
                state: "",
                name: "Procedure for submission of draft proposals",
                type: "external",
                external:
                  "https://www.un.org/en/ga/second/79/docs/submission-guidelines.pdf",
                target: true,
              },

            ],
          },
          {
            state: "c2speakerlist",
            name: "Speaker list",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "",
                name: "LIVE List of speakers",
              },
            ],
          },
          {
            state: "documentation",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "",
                name: "Allocation of agenda items",
                type: "external",
                external: "http://undocs.org/en/A/C.2/79/1",
                target: true,
              },
              {
                state: "",
                name: "Documents per agenda item",
                type: "external",
                external:
                  "https://igov.un.org/ga/c2/79/documents?menu=documents",
                target: true,
              },
              {
                state: "",
                name: "Status of documentation",
                type: "external",
                external: "http://undocs.org/en/A/C.2/79/L.1/Add.1",
                target: true,
              },
              {
                state: "",
                name: "Voting records",
                type: "external",
                external:
                  "",
                target: true,
              },
              {
                state: "",
                name: "Reports to the Plenary",
                type: "external",
                external:
                  "",
                target: true,
              },
            ],
          },
          {
            state: "contactList",
            name: "Contact list",
            type: "sub",
            children: [
              {
                state: "delegatesContactList",
                name: "C2 Delegates contact list",
              },
              {
                state: "",
                name: "Secretariat contact list",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/second/secretariat.shtml",
                target: true,
              },
            ],
          },
          {
            state: "eListOfParticipants",
            name: "e-List of participants",
          },
          {
            state: "place",
            name: "Second Committee e-Place",
          },
          {
            state: "announcements",
            name: "Second Committee Announcements",
          },
          {
            state: "secondCommitteeWebsite",
            name: "Second Committee Website",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/second/index.shtml",
            target: true,
          },
          {
            state: "press",
            name: "Press releases",
            type: "external",
            external: "https://press.un.org/en/second-committee",
            target: true,
          },
          {
            state: "press",
            name: "Second Committee webcast",
            type: "external",
            external:
              "https://webtv.un.org/en/search/categories/meetings-events/general-assembly/79th-session/2nd-committee",
            target: true,
          },
          {
            state: "c2videos",
            name: "Instructional videos",
            type: "sub",
            children: [
              {
                state: "",
                name: "Overview of Second Committee Menu",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/2C_menu.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of Second Committee e-Place",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/2Cplace.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Sponsorship",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/esponsorship.mp4",
                target: true,
              },
              {
                state: "",
                name: "How to initiate a draft resolution for co-sponsorship",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/how%20to%20initiate.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Submission",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/esubmission.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Speakers",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/espeakers.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of LIVE List of Speakers",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/livelist.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Voting intentions",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/evotes.mp4",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "thirdCommittee",
        short_label: "M",
        name: "Third Committee",
        type: "sub",
        children: [
          {
            state: "schedule",
            name: "Schedule",
            type: "sub",
            children: [
              {
                state: "",
                name: "Organization of work",
                type: "external",
                external: "https://undocs.org/en/A/C.3/79/L.1",
                target: true,
              },
              {
                state: "",
                name: "Calendar of meetings",
                type: "external",
                external:
                  "https://igov.un.org/ga/c3/79/calendar",
                target: true,
              },
              {
                state: "meetingsAtUNHQC3",
                name: "Third Committee meetings",
              },
              {
                state: "",
                name:
                  "List of special procedure mandate-holders and the other experts",
                type: "external",
                external:
                  "https://www.un.org/en/ga/third/79/docs/dialogues.pdf",
                target: true,
              },
            ],
          },
          {
            state: "draftProposals",
            name: "Draft proposals",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/c3",
                target: false,
              },
              {
                state: "sponsorshipC3",
                name: "e-Proposals (Sponsorship and Submission)",
              },
              {
                state: "votingIntentions",
                name: "e-Voting intentions",
              },
              {
                state: "",
                name: "Procedure for submission of draft proposals",
                type: "external",
                external:
                  "https://www.un.org/en/ga/third/78/docs/guidelines.pdf",
                target: true,
              },
              {
                state: "",
                name: "List of draft proposals",
                type: "external",
                external:
                  "https://igov.un.org/ga/c3/79/documents?menu=proposals",
                target: true,
              },
              {
                state: "",
                name: "Status of action of draft proposals",
                type: "external",
                external:
                  "",
                target: true,
              },
            ],
          },
          {
            state: "speakerlist",
            name: "Speaker List",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "",
                name: "LIVE list of speakers",
              },
            ],
          },
          {
            state: "documents",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "",
                name: "Allocation of agenda items",
                type: "external",
                external: "https://undocs.org/a/c.3/79/1",
                target: true,
              },
              // {
              //   state: "",
              //   name: "Status of documentation",
              //   type: "external",
              //   external: "https://undocs.org/en/A/C.3/78/L.1/Add.1",
              //   target: true,
              // },
              {
                state: "",
                name: "Documentation per agenda item",
                type: "external",
                external:
                  "https://igov.un.org/ga/c3/79/documents?menu=documents",
                target: true,
              },
              {
                state: "",
                name: "List of draft proposals",
                type: "external",
                external:
                  "https://igov.un.org/ga/c3/79/documents?menu=proposals",
                target: true,
              },
              {
                state: "",
                name: "Status of action of draft proposals",
                type: "external",
                external:
                  "",
                target: true,
              },
              {
                state: "",
                name: "Voting records",
                type: "external",
                external: "",
                target: true,
              },
              {
                state: "",
                name: "Reports to the Plenary",
                type: "external",
                external: "",
                target: true,
              },
            ],
          },
          {
            state: "contactList",
            name: "Contact List",
            type: "sub",
            children: [
              {
                state: "delegatesContactList",
                name: "Delegates Contact List",
              },
              {
                state: "",
                name: "Secretariat Contact List",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/third/secretariat.shtml",
                target: true,
              },
            ],
          },
          {
            state: "eListOfParticipants",
            name: "e-List of participants",
          },
          {
            state: "place",
            name: "Third Committee e-Place",
          },
          {
            state: "announcements",
            name: "Third Committee Announcements",
          },
          {
            state: "thirdCommitteeWebsite",
            name: "Third Committee Website",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/third/index.shtml",
            target: true,
          },
          {
            state: "pressReleases",
            name: "Press Releases",
            type: "external",
            external: "https://www.un.org/en/ga/third/pr.shtml",
            target: true,
          },
          {
            state: "c3webcast",
            name: "Third Committee webcast",
            type: "external",
            external:
              "http://webtv.un.org/meetings-events/general-assembly/main-committees/3rd-committee/",
            target: true,
          },
          {
            state: "c3videos",
            name: "Instructional videos",
            type: "sub",
            children: [
              {
                state: "",
                name: "Overview of Third Committee Menu",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/3C_menu.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of Third Committee e-Place",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/3Cplace.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Sponsorship",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/esponsorship.mp4",
                target: true,
              },
              {
                state: "",
                name:
                  "How to initiate a draft resolution for co-sponsorship",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/how%20to%20initiate.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Submission",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/esubmission.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Speakers",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/espeakers.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of LIVE List of Speakers",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/livelist.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Voting intentions",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/evotes.mp4",
                target: true,
              },

            ],
          },
        ],
      },
      {
        state: "fourthCommittee",
        short_label: "M",
        name: "Fourth Committee",
        type: "sub",
        children: [
          {
            state: "",
            name: "List of proposals (action module)",
            type: "external",
            external: "/portal/proposals/c4",
            target: false,
          },
          {
            state: "bureauSecretariatContacts",
            name: "Bureau - Secretariat contacts",
            type: "sub",
            children: [
              {
                state: "",
                name: "Bureau - contact details",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/fourth/77/bureau77.shtml",
                target: true,
              },
              {
                state: "",
                name: "Secretariat - contact details",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/fourth/secretariat.shtml",
                target: true,
              },
            ],
          },
          {
            state: "sponsorshipC4",
            name: "Fourth Committee e-Sponsorship",
          },
          {
            state: "votingIntentions",
            name: "Fourth Committee e-Voting intentions",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "place",
            name: "Fourth Committee e-Place",
          },
          {
            state: "announcements",
            name: "Fourth Committee Announcements",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "PressReleases",
            name: "Press Releases",
            type: "external",
            external: "https://www.un.org/press/-$lang$-/content/fourth-committee",
            target: true,
          },
          {
            state: "c4webcast",
            name: "Webcast",
            type: "external",
            external: "http://webtv.un.org/",
            target: true,
          },
          {
            state: "relatedLinks",
            name: "Related Links",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/fourth/links.shtml",
            target: true,
          },
          {
            state: "pastSessions",
            name: "Past sessions",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/fourth/archives.shtml",
            target: true,
          },
        ],
      },
      {
        state: "fifthCommittee",
        short_label: "M",
        name: "Fifth Committee",
        type: "sub",
        children: [
          {
            state: "",
            name: "List of proposals (action module)",
            type: "external",
            external: "/portal/proposals/c5",
            target: false,
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "place",
            name: "Fifth Committee e-Place",
          },
          {
            state: "elections",
            name: "e-Candidatures and Elections",
          },
          {
            state: "candiweb",
            name: "Candiweb",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "esubmit",
            name: "e-Submit",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "c5meetings",
            name: "Meetings",
            type: "sub",
            children: [
              {
                state: "",
                name: "eJournal",
                type: "external",
                external: "https://journal.un.org/-$lang$-",
                target: true,
              },
              {
                state: "",
                name: "Programme of work",
                type: "external",
                external:
                  "https://www.un.org/en/ga/fifth/79/main79.shtml",
                target: true,
              },
              {
                state: "",
                name: "Statements",
                type: "external",
                external:
                  "https://www.un.org/en/ga/fifth/79/statements79.0m.shtml",
                target: true,
              },
              {
                state: "",
                name: "Webcast",
                type: "external",
                external:
                  "https://webtv.un.org/en/search/categories/meetings-events/general-assembly/79th-session/5th-committee",
                target: true,
              },
              {
                state: "",
                name: "Press releases",
                type: "external",
                external:
                  "https://www.un.org/press/-$lang$-/content/fifth-committee",
                target: true,
              },
            ],
          },
          {
            state: "c5documents",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "",
                name: "Agenda items",
                type: "external",
                external: "https://www.un.org/en/ga/fifth/79/agenda79.shtml",
                target: true,
              },
              {
                state: "",
                name: "Status of documentation",
                type: "external",
                external: "https://www.un.org/en/ga/fifth/79/main79.shtml",
                target: true,
              },
              {
                state: "",
                name: "Resolutions and decisions",
                type: "external",
                external:
                  "https://www.un.org/en/ga/fifth/79/resdec79.shtml",
                target: true,
              },
              {
                state: "place",
                name: "Fifth Committee e-Place",
              },
            ],
          },
          {
            state: "c5contacts",
            name: "Contact information",
            type: "sub",
            children: [

              {
                state: "",
                name: "Secretariat contact information",
                type: "external",
                external:
                  "https://edelegate.un.int/portal/doc/placec5/download?uploadName=66ce216058f6f2db3c664043~C5_79_0m_Secretariat Contact Information Sheet.pdf&fileName=C5_79_0m_Secretariat Contact Information Sheet.pdf",
                target: true,
              },
            ],
          },
          {
            state: "c5about",
            name: "About",
            type: "sub",
            children: [
              {
                state: "",
                name: "C5 website on un.org",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/fifth/index.shtml",
                target: true,
              },
              {
                state: "",
                name: "Past sessions",
                type: "external",
                external: "https://www.un.org/en/ga/fifth/previous.shtml",
                target: true,
              },
              {
                state: "",
                name: "Frequently asked questions",
                type: "external",
                external: "https://www.un.org/en/ga/fifth/faq.shtml",
                target: true,
              },
              {
                state: "",
                name: "Related links",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/fifth/rl.shtml",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "sixthCommittee",
        short_label: "M",
        name: "Sixth Committee",
        type: "sub",
        children: [
          {
            state: "sponsorshipC6",
            name: "Sixth Committee e-Sponsorship",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "c6meetings",
            name: "Scheduled meetings"
          },
          {
            state: "contactList",
            name: "Delegates contact list",
          },
          {
            state: "c6website",
            name: "Website",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/sixth/",
            target: true,
          },


        ],
      },
      {
        state: "subsidiaryBodies",
        short_label: "M",
        name: "Subsidiary Bodies",
        type: "sub",
        children: [
          {
            state: "ahcc",
            name: "Ad Hoc Committee on Cybercrime",
            type: "sub",
            children: [
              {
                state: "sponsorshipAHCC",
                name: "e-Proposals (Sponsorship and Submission)",
              }
            ],
          },
          {
            state: "AHWGArevitalization",
            name: "Ad Hoc Working Group on GA revitalization",
            type: "sub",
            children: [
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/revitalization/index.shtml",
                target: true,
              },
              {
                state: "Documents",
                name: "Documents",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/revitalization/revital_docs.shtml",
                target: true,
              },
              {
                state: "Announcements",
                name: "Announcements",
              }
            ],
          },
          {
            state: "ahict",
            name: "AHIC to draft terms of reference.....on international tax cooperation",
            type: "sub",
            children: [
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "place",
                name: "e-Place",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "BiennialMeetingonSmallArms",
            name: "Biennial Meeting on Small Arms",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
            ],
          },
          {
            state: "coc",
            name: "Committee on Conferences",
            type: "sub",
            children: [
              {
                state: "Website",
                name: "Website",
                type: "external",
                external: "https://www.un.org/dgacm/-$lang$-/content/coc",
                target: true,
              },
              {
                state: "Proposedagendafor2020",
                name: "Proposed agenda for 2024",
                type: "external",
                external: "https://undocs.org/en/A/AC.172/2024/1",
                target: true,
              },
              {
                state: "Documents",
                name: "Documents",
                type: "external",
                external:
                  "https://www.un.org/dgacm/-$lang$-/content/coc/pre-session",
                target: true,
              },
              // {
              //   state: "Announcements",
              //   name: "Announcements",
              // },
              {
                state: "COCPlace",
                name: "COC e-Place",
              },
              // {
              //   state: "espeakers",
              //   name: "e-Speakers",
              // },
            ],
          },
          {
            state: "disarmamentCommission",
            name: "Disarmament Commission",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "Bureau",
                name: "Bureau",
              },
              {
                state: "Secretariatcontactdetails",
                name: "Secretariat - contact details",
              },
              {
                state: "Schedule",
                name: "Schedule",
                type: "external",
                external:
                  "",
                target: true,
              },
              {
                state: "agendaorganizationalsession",
                name: "Agenda (organizational session)",
                type: "external",
                external:
                  "https://undocs.org/A/CN.10/L.85",
                target: true,
              },
              {
                state: "agendasubstantivesession",
                name: "Agenda (substantive session)",
                type: "external",
                external:
                  "https://undocs.org/A/CN.10/L.86",
                target: true,
              },
              {
                state: "UNDCAnnouncements",
                name: "UNDC Announcements",
              },
              {
                state: "UNDCPlace",
                name: "UNDC e-Place",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/disarmament/institutions/disarmament-commission/session-2022/",
                target: true,
              },
            ],
          },
          {
            state: "GeneralCommittee",
            name: "General Committee",
            type: "sub",
            children: [
              {
                state: "Website",
                name: "Website",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/general/general.shtml",
                target: true,
              },
              {
                state: "Documents",
                name: "Documents",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/73/agenda/",
                target: true,
              },
            ],
          },
          {
            state: "HumanRightsCouncil",
            name: "Human Rights Council",
            type: "sub",
            children: [
              {
                state: "sponsorshipHRC",
                name: "Human Rights Council e-Sponsorship",
              },
              {
                state: "Guidelinesformainsponsors",
                name: "Guidelines for main sponsors",
              },
              {
                state: "Guidelinesforco-sponsors",
                name: "Guidelines for co-sponsors",
              },
              {
                state: "eRecordedVotesHRC",
                name: "e-Recorded votes",
              },
            ],
          },
          {
            state: "OEWGonAgeing",
            name: "OEWG on Ageing",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "sponsorshipOEWGA",
                name: "e-Proposals (Sponsorship and Submission)",
              },
            ],
          },
          {
            state: "OEWGonConvAmm",
            name: "OEWG on Conventional Ammunition",
            type: "sub",
            children: [
              // {
              //   state: "espeakers",
              //   name: "e-Speakers",
              // },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "place",
                name: "OEWG on Conventional Ammunition e-Place"
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external: "https://meetings.unoda.org/node/62086",
                target: true,
              },
            ],
          },
          {
            state: "OEWGonSecurity",
            name: "OEWG on Security of ICTs 2021-2025",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "Agenda",
                name: "Agenda",
                // type: "external",
                // external: "https://undocs.org/en/A/AC.290/2019/1",
                // target: true,
              },
              {
                state: "OEWGonSecurityPlace",
                name: "OEWG on Security e-Place",
              },
            ],
          },
          {
            state: "OEWGonInformationandTelecommunications",
            name: "OEWG on Information and Telecomm.(MANDATE COMPLETED)",
            type: "sub",
            children: [
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/disarmament/open-ended-working-group/",
                target: true,
              },
              {
                state: "Agenda",
                name: "Agenda",
                type: "external",
                external: "https://undocs.org/-$lang$-/A/AC.290/2019/1",
                target: true,
              },
              {
                state: "Chairmanship",
                name: "Chairmanship",
                type: "external",
                external:
                  "https://www.eda.admin.ch/dam/mission-new-york/en/documents/Lauber-Juerg-2015_EN.pdf",
                target: true,
              },
              {
                state: "Secretariat",
                name: "Secretariat",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/first/secretariat.shtml",
                target: true,
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "OEWGonInformationandTelecommunicationsPlace",
                name: "OEWG on Information and Telecommunications e-Place",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "Firstsubstantivesession(9-13Sep2019)",
                name:
                  "First substantive session (9-13 Sep 2019) - Programme of work",
                type: "external",
                external: "https://undocs.org/en/A/AC.290/2019/2",
                target: true,
              },
              {
                state: "Secondsubstantivesession(10-14Feb2020)",
                name:
                  "Second substantive session (10-14 Feb 2020) - Programme of work",
                type: "external",
                external: "https://undocs.org/en/A/AC.290/2020/1",
                target: true,
              },
              {
                state: "Thirdsubstantivesession(8-12Mar2021)",
                name: "Third substantive session (8-12 Mar 2021) - Programme of work",
              },
            ],
          },
          {
            state: "PeacebuildingCommission",
            name: "Peacebuilding Commission",
            type: "sub",
            children: [
              {
                state: "Membership",
                name: "Membership and Bureau",
                type: "external",
                external:
                  "https://www.un.org/peacebuilding/commission/membership",
                target: true,
              },
              {
                state: "e-Mandate",
                name: "Mandate",
                type: "external",
                external:
                  "https://www.un.org/peacebuilding/commission/mandate",
                target: true,
              },
              {
                state: "Documents",
                name: "Documents",
                type: "external",
                external: "https://www.un.org/peacebuilding/documents",
                target: true,
              },
            ],
          },
          {
            state: "SpecialCommitteeonDecolonization",
            name: "Special Committee on Decolonization (C-24)",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "C24Place",
                name: "C24 e-Place",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "sponsorshipC24",
                name: "C-24 e-Sponsorship",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "specialCommitteeOnPeacekeepingOperations",
            name: "Special Committee on Peacekeeping Operations (C-34)",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "place",
                name: "C-34 e-Place",
              },
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "UNCharterCommittee",
            name: "Special Committee on the Charter",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "TowardsaGlobalPactfortheEnvironment",
            name: "Towards a Global Pact for the Environment",
            type: "sub",
            children: [
              {
                state: "eListofparticipants",
                name: "e-List of participants",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "UNRWAPledgingConference",
            name: "UNRWA Pledging Conference",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
        ],
      },
      {
        state: "searchelectedofficers",
        short_label: "M",
        name: "Search elected officers",
        type: "",
      },
    ],

  },
  {
    state: "securitycouncil",
    short_label: "O",
    name: "Security Council",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "placesc",
        name: "Security Council e-Place",
      },
      {
        state: "espeakers",
        name: "Security Council e-Speakers",
      },
      {
        state: "sponsorshipSC",
        name: "Security Council e-Sponsorship",
      },
      {
        state: "announcements",
        name: "Security Council Announcements",
      },
      {
        state: "SubsidiaryOrgans",
        name: "Subsidiary Organs",
        type: "sub",
        children: [
          {
            state: "placesc1267",
            name: "ISIL (Da'esh) and Al-Qaida Sanctions Committee e-Place",
          },
          {
            state: "placesc1373",
            name: "Counter-Terrorism Committee e-Place",
          },
          {
            state: "placesc1518",
            name: "1518 Sanctions Committee (Iraq) e-Place",
          },
          {
            state: "placesc1533",
            name: "DRC Sanctions Committee e-Place",
          },
          {
            state: "placesc1540",
            name: "1540 Committee e-Place",
          },
          {
            state: "placesc1591",
            name: "Sudan Sanctions Committee e-Place",
          },
          {
            state: "placesc1718",
            name: "1718 Sanctions Committee (DPRK) e-Place",
          },
          {
            state: "placesc1970",
            name: "Libya Sanctions Committee e-Place",
          },
          {
            state: "placesc1988",
            name: "1988 Sanctions Committee e-Place",
          },
          {
            state: "placesc2048",
            name: "Guinea-Bissau Sanctions Committee e-Place",
          },
          {
            state: "placesc2140",
            name: "2140 Sanctions Committee (Yemen) e-Place",
          },
          {
            state: "placesc2206",
            name: "South Sudan Sanctions Committee e-Place",
          },
          {
            state: "placesc2374",
            name: "Mali Sanctions Committee e-Place",
          },
          {
            state: "placesc2653",
            name: "2653 Sanctions Committee e-Place",
          },
          {
            state: "placesc2745",
            name: "2745 Sanctions Committee e-Place",
          },
          {
            state: "placesc751",
            name: "2713 Sanctions Committee e-Place",
          },
          {
            state: "placewgcaac",
            name: "Working Group on Children and Armed Conflict e-Place",
          },
          {
            state: "placesciwg",
            name: "Informal Working Group on Documentation and other Procedural Questions e-Place",
          },
        ],
      },
      {
        state: "Resolution2231(2015)",
        name: "Resolution 2231 (2015) e-Place",
      },
      {
        state: "PeacebuildingCommission",
        name: "Peacebuilding Commission",
        type: "sub",
        children: [
          {
            state: "MembershipandBureau",
            name: "Membership and Bureau",
            type: "external",
            external:
              "https://www.un.org/peacebuilding/commission/membership",
            target: true,
          },
          {
            state: "Mandate",
            name: "Mandate",
            type: "external",
            external: "https://www.un.org/peacebuilding/commission/mandate",
            target: true,
          },
          {
            state: "Documents",
            name: "Documents",
            type: "external",
            external: "https://www.un.org/peacebuilding/documents",
            target: true,
          },
        ],
      },
    ],
  },
  {
    state: "ecosoc",
    short_label: "O",
    name: "ECOSOC",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "en",
        name: "ECOSOC meetings/segments/forums",
        type: "sub",
        children: [
          {
            state: "schedule",
            name: "Schedule",
            type: "external",
            external: "https://www.un.org/ecosoc/en/events",
            target: true,
          },
          {
            state: "ecosocmeetings",
            name: "ECOSOC meetings",
          },
          {
            state: "agenda",
            name: "Agenda",
            type: "external",
            external: "https://www.un.org/ecosoc/en/content/agenda-working-arrangements-and-programme-work",
            target: true,
          },
          {
            state: "resolutionsDecisions",
            name: "Resolutions and Decisions",
            type: "sub",
            children: [
              {
                state: "sponsorshipECOSOC",
                name: "ECOSOC e-Sponsorship",
              },
              {
                state: "edecisions",
                name: "ECOSOC Decisions",
              },
              {
                state: "",
                name: "ECOSOC Resolutions",
                type: "external",
                external:
                  "https://www.un.org/ecosoc/en/documents/resolutions",
                target: true,
              },
              {
                state: "votingIntentions",
                name: "ECOSOC e-Voting intentions",
              },
              {
                state: "",
                name: "Submission guidelines",
              },
            ],
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "place",
            name: "ECOSOC e-Place",
          },

          {
            state: "ECOSOCWebsite",
            name: "ECOSOC Website",
            type: "external",
            external: "https://www.un.org/ecosoc/en/",
            target: true,
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "speakersld",
            name: "ECOSOC Youth Forum e-Speakers",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "elections",
            name: "Elections",
            type: "sub",
            children: [
              {
                state: "elections",
                name: "e-Candidatures and Elections",
              },
              {
                state: "candiweb",
                name: "Candiweb",
              },
            ],
          },
          {
            state: "searchmembership",
            name: "Search membership",
          },
        ],
      },
      {
        state: "committeeforprogrammeandcoordination",
        name: `Committee for Programme and Coordination`,
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "place",
            name: "CPC e-Place (Restricted)",
          },
          {
            state: "cpcwebsite",
            name: "CPC Website (Public)",
            type: "external",
            external: "https://www.un.org/en/ga/cpc/",
            target: true,
          },
        ],
      },
      {
        state: "commissionForSocialDevelopment",
        name: "Commission for Social Development",
        type: "sub",
        children: [
          {
            state: "organizationOfWork",
            name: "Proposed Organization of Work",
            type: "external",
            external:
              "https://undocs.org/E/CN.5/2022/1",
            target: true,
          },
          {
            state: "CSOCDMeetings",
            name: "CSOCD meetings",
          },
          {
            state: "draftProposals",
            name: "Draft Proposals",
            type: "sub",
            children: [
              {
                state: "sponsorshipCSOCD",
                name: "CSOCD e-Sponsorship",
              },
              {
                state: "votingIntentions",
                name: "CSOCD e-Voting intentions",
              },
            ],
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "livespeakers",
            name: "LIVE List of speakers",
            type: "external",
            external:
              "http://espeakers.unmeetings.org/61e82887a17e2700128a2d9519012022/index.html",
            target: true,
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "CSOCDWebsite",
            name: "Website",
            type: "external",
            external:
              "https://www.un.org/development/desa/dspd/united-nations-commission-for-social-development-csocd-social-policy-and-development-division/csocd60.html",
            target: true,
          },
        ],
      },
      {
        state: "commissionOnPopulationAndDevelopment",
        name: "Commission on Population and Development",
        type: "sub",
        children: [{
          state: "CPDMeetings",
          name: "CPD meetings",
        },
        {
          state: "sponsorshipCPD",
          name: "CPD e-Sponsorship",
        },
        {
          state: "votingIntentions",
          name: "CPD e-Voting intentions",
        },
        {
          state: "announcements",
          name: "Announcements",
        },
        {
          state: "espeakers",
          name: "e-Speakers",
        },
        {
          state: "livelist",
          name: "CPD LIVE list of Speakers",
          type: "external",
          external:
            "http://espeakers.unmeetings.org/6419f6514d099d5e1bb3948b21032023/index.html",
          target: true,
        },
        {
          state: "eListOfParticipants",
          name: "e-List of Participants",
        },
        {
          state: "cpdwebsite",
          name: "Website",
          type: "external",
          external:
            "https://www.un.org/development/desa/pd/content/CPD",
          target: true,
        },
        ],
      },
      {
        state: "commissionOnTheStatusOfWomen",
        name: "Commission on the Status of Women",
        type: "sub",
        children: [
          {
            state: "organizationOfWork",
            name: "Organization of Work",
            type: "external",
            external:
              "https://undocs.org/E/CN.6/2024/1/Add.1",
            target: true,
          },
          {
            state: "CSW62MeetingsInUNHQ",
            name: "CSW Meetings",
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "sponsorshipCSW",
            name: "e-Sponsorship",
          },
          {
            state: "votingIntentions",
            name: "CSW e-Voting intentions",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "speakersmrt",
            name: "Ministerial Roundtables",
          },
          // {
          //   state: "cswliveListOfSpeakers",
          //   name: "LIVE list of speakers",
          //   type: "sub",
          //   children: [
          //     {
          //       state: "cswgeneraldiscussion",
          //       name: "General discussion",
          //       type: "external",
          //       external:
          //         "http://espeakers.unmeetings.org/620402a609eff10011ab949109022022/index.html",
          //       target: true,
          //     },
          //     // {
          //     //   state: "cswmrt1",
          //     //   name: "MRT 1 (15 March 4-5 pm)",
          //     //   type: "external",
          //     //   external:
          //     //     "",
          //     //   target: true,
          //     // },
          //     // {
          //     //   state: "cswmrt2",
          //     //   name: "MRT 2 (15 March 5-6 pm)",
          //     //   type: "external",
          //     //   external:
          //     //     "",
          //     //   target: true,
          //     // },
          //     // {
          //     //   state: "cswmrt3",
          //     //   name: "MRT 3 (16 March 9-10 am)",
          //     //   type: "external",
          //     //   external:
          //     //     "",
          //     //   target: true,
          //     // },
          //     // {
          //     //   state: "cswmrt4",
          //     //   name: "MRT 4 (16 March 10-11 am)",
          //     //   type: "external",
          //     //   external:
          //     //     "",
          //     //   target: true,
          //     // },
          //   ],
          // },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "CSWDocuments",
            name: "Documents",
            type: "external",
            external:
              "https://www.unwomen.org/en/csw/csw68-2024/official-documents",
            target: true,
          },
          {
            state: "CSWWebsite",
            name: "Website",
            type: "external",
            external: "https://www.unwomen.org/en/how-we-work/commission-on-the-status-of-women",
            target: true,
          },
        ],
      },
      {
        state: "cnd",
        name: "Commission on Narcotic Drugs",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipCND",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },
      {
        state: "ccpcj",
        name: "Commission on Crime Prevention and Criminal Justice",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipCCPCJ",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },
      {
        state: "CommitteeonNon-GovernmentalOrganizations",
        name: "Committee on Non-Governmental Organizations",
        type: "sub",
        children: [
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "CNGOswebsite",
            name: "CNGOs website",
            type: "external",
            external: "https://ecosoc.un.org/en/ngo/committee-on-ngos",
            target: true,
          },
          {
            state: "ProvisionalAgenda",
            name: "Provisional Agenda",
            type: "external",
            external: "https://undocs.org/Home/Mobile?FinalSymbol=E%2fC.2%2f2024%2f1%2fRev.1&Language=E&DeviceType=Desktop&LangRequested=False",
            target: true,
          },
          // {
          //   state: "ProgrammeofWork",
          //   name: "Programme of Work",
          //   type: "external",
          //   external:
          //     "https://csonet.org/content/documents/Information%20Note%202023%20Resumed%20session_1%20May%202023_FINAL.pdf",
          //   target: true,
          // },
          {
            state: "ReportsoftheCommittee",
            name: "Reports of the Committee",
            type: "external",
            external: "https://ecosoc.un.org/en/ngo/committee-reports",
            target: true,
          },
        ],
      },
      {
        state: "permanentForumOnIndigenousIssues",
        name: `Permanent Forum on Indigenous Issues`,
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "place",
            name: "PFII e-Place",
          },

        ],
      },
      {
        state: "StatisticalCommission",
        name: "Statistical Commission",
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "CN52ndSessionWebsiteGOswebsite",
            name: "Website",
            type: "external",
            external: "https://unstats.un.org/UNSDWebsite/statcom/54",
            target: true,
          },
          {
            state: "2021ProvisionalAgenda",
            name: "Provisional Agenda",
            type: "external",
            external:
              "https://documents-dds-ny.un.org/doc/UNDOC/GEN/N22/714/48/PDF/N2271448.pdf?OpenElement",
            target: true,
          },
          {
            state: "ProgrammeofWork",
            name: "Programme of Work and Timetable",
            type: "external",
            external: "https://documents-dds-ny.un.org/doc/UNDOC/LTD/N22/733/70/PDF/N2273370.pdf?OpenElement",
            target: true,
          },
        ],
      },
      {
        state: "UnitedNationsCommitteeofExpertsonGGIM",
        name: "United Nations Committee of Experts on Global Geospatial Information Management",
        type: "sub",
        children: [
          {
            state: "Announcements",
            name: "Announcements",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of participants",
          },

        ],
      },
      {
        state: "unitedNationsForumOnForests",
        name: `United Nations Forum on Forests`,
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "sponsorshipFF",
            name: "e-Sponsorship",
          },
          {
            state: "votingIntentions",
            name: "UNFF e-Voting intentions",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "meetingsAtUNFF",
            name: "UNFF Meetings",
          },
          {
            state: "Website",
            name: "Website",
            type: "external",
            external:
              "https://www.un.org/esa/forests/index.html",
            target: true,
          },
        ],
      },

      {
        state: "UnitedNationsGroupofExpertsonGeographicalNames",
        name: "United Nations Group of Experts on Geographical Names",
        type: "sub",
        children: [
          {
            state: "Announcements",
            name: "Announcements",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "Website",
            name: "Website",
            type: "external",
            external:
              "https://unstats.un.org/unsd/geoinfo/ungegn/default.html",
            target: true,
          },
        ],
      },
    ],
  },
  {
    state: "Conference",
    short_label: "C",
    name: "Conference",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "confcd",
        name: `Conference on Disarmament`,
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "elistofparticipants",
            name: "e-List of participants",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
        ],
      },
      {
        state: "confsids",
        name: `SIDS Conference`,
        type: "sub",
        children: [
          {
            state: "prepcom",
            name: `PrepCom 1 & 2`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "sids",
            name: `Conference`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Interactive dialogues",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://sdgs.un.org/smallislands",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "conflldc",
        name: `Conference on Landlocked Developing Countries`,
        type: "sub",
        children: [
          {
            state: "prepcomlldc",
            name: `PrepCom 1 & 2`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "lldc",
            name: `Conference`,
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Thematic round tables",
              },
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://www.un.org/en/landlocked/programme",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "confsalw",
        name: `4th UN Conference to review progress...Small Arms and Light Weapons`,
        type: "sub",
        children: [
          {
            state: "prepcomsalw",
            name: `Preparatory Committee`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://meetings.unoda.org/poa-salw-prepcom/programme-of-action-on-small-arms-and-light-weapons-preparatory-committee-2024",
                target: true,
              },
            ],
          },
          {
            state: "salw",
            name: `Review Conference`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://meetings.unoda.org/poa-salw-revcon/programme-of-action-on-small-arms-and-light-weapons-review-conference-2024",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "conficffd",
        name: `International Conference on Financing for Development`,
        type: "sub",
        children: [
          {
            state: "prepcomicffd",
            name: `PrepCom`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "place",
                name: "e-Place",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "icffd",
            name: `Conference`,
            type: "sub",
            children: [
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://financing.desa.un.org/fourth-international-conference-financing-development",
                target: true,
              },
            ],
          },
        ],
      },

      {
        state: "ConferenceofStatesPartiestoCRPD",
        name: `17th Conference of States Parties to CRPD`,
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
          {
            state: "ConferencePlace",
            name: "CRPD e-Place",
          },
          {
            state: "ConferenceWebsite",
            name: "Conference Website",
            type: "external",
            external: "https://www.un.org/development/desa/disabilities/",
            target: true,
          },
          {
            state: "12thsessionoftheConferenceWebsite",
            name: "17th session of the Conference Website",
            type: "external",
            external:
              "https://social.desa.un.org/issues/disability/cosp/17th-session-of-the-conference-of-states-parties-to-the-crpd-cosp17",
            target: true,
          },
        ],
      },
      {
        state: "NPT",
        name: `NPT Review Conference`,
        type: "sub",
        children: [
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          // {
          //   state: "ecredentials",
          //   name: "e-Credentials",
          // },
          // {
          //   state: "Website",
          //   name: "Website",
          //   type: "external",
          //   external:
          //     "https://www.un.org/en/conferences/npt2020",
          //   target: true,
          // },
        ],
      },

      {
        state: "BBNJIntergovernmentalConference",
        name: `BBNJ Agreement Preparatory Commission`,
        type: "sub",
        children: [
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
          {
            state: "elistofparticipants",
            name: "e-List of participants",
          },
        ],
      },
      {
        state: "unclos",
        name: `34th Meeting of States Parties to UNCLOS`,
        type: "sub",
        children: [
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
        ],
      },

      {
        state: "confmezfnwmd",
        name: `Conference on MEZFNWMD`,
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "elistofparticipants",
            name: "e-List of participants",
          },
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
          {
            state: "announcements",
            name: "Announcements",
          },

        ],
      },


      {
        state: "pastconf",
        name: `Past Conferences`,
        type: "sub",
        children: [
          {
            state: "hldonenergy",
            name: "High-level Dialogue on Energy",
            type: "external",
            external: "/portal/generalAssembly/plenary/speakerlist/espeakers",
            target: false,
          },
          {
            state: "ctbt",
            name: `Conference on Facilitating the Entry into Force of the CTBT`,
            type: "sub",
            children: [
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },

          {
            state: "pdc",
            name: "2021 Pledging Conference",
            type: "sub",
            children: [
              {
                state: "placegaplenary",
                name: "Plenary e-Place",
              },
            ],
          },

          {
            state: "ldc",
            name: `5th United Nations Conference on the Least Developed Countries`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersrt",
                name: "High-level thematic roundtables",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of Participants",
              },
              {
                state: "placeconfldc",
                name: "Conference on LDC e-Place",
              },
              {
                state: "ldcmeetings",
                name: "Conference on LDC Meetings",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/ohrlls/content/fifth-united-nations-conference-least-developed-countries-ldc5",
                target: true,
              },
            ],
          },
          {
            state: "2ndHLUNConferenceonSouthSouthCooperation",
            name: `2nd HL UN Conference on South-South Cooperation`,
            type: "sub",
            children: [
              {
                state: "ConferencePlace",
                name: "Conference e-Place",
              },
            ],
          },


          {
            state: "gstc",
            name: `United Nations Global Sustainable Transport Conference`,
            type: "sub",
            children: [
              {
                state: "eListOfParticipants",
                name: "e-List of Participants",
              },
              {
                state: "espeakers",
                name: "e-List of Speakers",
              },
              {
                state: "gstcmeetings",
                name: "e-Meetings",
              },
            ],
          },
          {
            state: "ocean",
            name: `2022 UN Ocean Conference`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Interactive dialogues",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/conferences/ocean2022",
                target: true,
              },
            ],
          },





          {
            state: "stockholm50",
            name: `Stockholm +50`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Leadership dialogues",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.stockholm50.global/",
                target: true,
              },
            ],
          },
          {
            state: "tpnw",
            name: `TPNW Meeting of States Parties`,
            type: "sub",
            children: [
              {
                state: "place",
                name: "TPNW e-Place",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://meetings.unoda.org/tpnw/tpnw-msp-2023",
                target: true,
              },
            ],
          },

          {
            state: "water",
            name: `2023 Water Conference`,
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Interactive dialogues",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
            ],
          },









        ],
      },







    ],
  },
  {
    state: "escap",
    short_label: "O",
    name: "ESCAP",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "announcements",
        name: "Announcements",
      },
      {
        state: "upcoming",
        name: `Upcoming session`,
        type: "sub",
        children: [
          // {
          //   state: "eListOfParticipants",
          //   name: "e-List of Participants",
          // },
        ],
      },
      {
        state: "past",
        name: `Recently concluded sessions`,
        type: "sub",
        children: [
        ],
      },
      {
        state: "documents",
        name: `Documents`,
        type: "sub",
        children: [
        ],
      },
      {
        state: "ecredentials",
        name: "e-Credentials",
      },
      {
        state: "placeescapacpr",
        name: "e-Place",
      },
    ],
  },
  {
    state: "geneva",
    short_label: "U",
    name: "Geneva",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "apmbc",
        name: `Anti-Personnel Mine Ban Convention`,
        type: "sub",
        children: [
          {
            state: "elistofparticipants",
            name: "e-List of Participants",
          },
        ],
      },
      {
        state: "gencd",
        name: `Conference on Disarmament`,
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "elistofparticipants",
            name: "e-List of Participants",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          }
        ],
      },
      {
        state: "ccw",
        name: `Convention on Certain Conventional Weapons`,
        type: "sub",
        children: [
          {
            state: "elistofparticipants",
            name: "e-List of Participants",
          },
        ],
      },
      {
        state: "ccm",
        name: `Convention on Cluster Munitions`,
        type: "sub",
        children: [
          {
            state: "elistofparticipants",
            name: "e-List of Participants",
          },
        ],
      },
      {
        state: "HumanRightsCouncil",
        name: "Human Rights Council",
        type: "sub",
        children: [
          {
            state: "sponsorshipHRC",
            name: "Human Rights Council e-Sponsorship",
          },
          {
            state: "Guidelinesformainsponsors",
            name: "Guidelines for main sponsors",
          },
          {
            state: "Guidelinesforco-sponsors",
            name: "Guidelines for co-sponsors",
          },
          {
            state: "eRecordedVotesHRC",
            name: "e-Recorded votes",
          },
        ],
      },
    ],
  },
  {
    state: "vienna",
    short_label: "U",
    name: "Vienna",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "ahcc",
        name: `Ad Hoc Committee on Cybercrime`,
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Proposals (Sponsorship and Submission)",
          }

        ],
      },
      {
        state: "cnd",
        name: "Commission on Narcotic Drugs",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipCND",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },
      {
        state: "ccpcj",
        name: "Commission on Crime Prevention and Criminal Justice",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipCCPCJ",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },
      {
        state: "untoc",
        name: "Conference of the Parties to the United Nations Convention against Transnational Organized Crime",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipUNTOC",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },

    ],
  },
  {
    state: "nairobi",
    short_label: "U",
    name: "Nairobi",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "unep",
        name: "UNEP",
        type: "sub",
        children: [
          {
            state: "unea",
            name: `UN Environment Assembly`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "place",
                name: "e-Place",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "esubmit",
                name: "e-Submit",
              },

            ],
          },
          {
            state: "cpr",
            name: `Committee of Permanent Representatives`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "place",
                name: "e-Place",
              },
              {
                state: "esubmit",
                name: "e-Submit",
              },
            ],
          },
        ]
      }

    ],
  },
  // {
  //   state: "unep",
  //   short_label: "U",
  //   name: "UNEP",
  //   type: "sub",
  //   icon: "icon-award",
  //   children: [
  //     {
  //       state: "unea",
  //       name: `UN Environment Assembly`,
  //       type: "sub",
  //       children: [
  //         {
  //           state: "espeakers",
  //           name: "e-Speakers",
  //         },
  //         {
  //           state: "esubmit",
  //           name: "e-Submit",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    state: "protocol",
    short_label: "O",
    name: "Protocol",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "protocolAndLiaisonService",
        name: "Protocol and Liaison Service",
        type: "external",
        external: "https://www.un.org/dgacm/-$lang$-/content/protocol",
        target: true,
      },
      {
        state: "emissionstaff",
        name: "e-Mission registration of personnel",
      },
      {
        state: "eregistration",
        name: "Registration to meetings and conferences",
      },
      {
        state: "tickets",
        name: "Reservation for courtesy tickets for the general debate",
      },
      // {
      //   state: "pastConferences",
      //   name: "Past Conferences",
      // },
      {
        state: "staffregistration",
        name: "Conference registration for UN secretariat and entities",
      },
      {
        state: "TempDecalDriverRequests",
        name: "Temporary pick up - drop off permits",
      },
      {
        state: "countries",
        name: "Member States",
      },
      {
        state: "blueBook",
        name: "Blue Book",
        type: "external",
        external: "https://protocol.un.org/dgacm/pls/site.nsf/BlueBook.xsp",
        target: true,
      },
      {
        state: "eblueBook",
        name: "e-Blue Book",
        type: "external",
        external:
          "https://bluebook.unmeetings.org",
        target: true,
      },
    ],
  },
  {
    state: "resources",
    short_label: "rsrcs",
    name: "Resources",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "access",
        name: "Access Management",
        type: "sub",
        children: [
          {
            state: "accessNY",
            name: "Access Management - New York",
          },
          // {
          //   state: "accessBangkok",
          //   name: "Access Management - Bangkok",
          // },
          {
            state: "accessGeneva",
            name: "Access Management - Geneva",
          },
          {
            state: "accessNairobi",
            name: "Access Management - Nairobi",
          },
          {
            state: "accessVienna",
            name: "Access Management - Vienna",
          },
        ],
      },
      {
        state: "daglibrary",
        name: "Dag Hammarskjöld Library",
        type: "sub",
        children: [
          {
            state: "asklibrary",
            name: "Ask DAG Library",
            type: "external",
            external: "https://ask.un.org/",
            target: true,
          },
          {
            state: "dlibrary",
            name: "UN Digital Library",
            type: "external",
            external: "https://digitallibrary.un.org/",
            target: true,
          },
          {
            state: "dlibraryV",
            name: "UN Digital Library Voting Data",
            type: "external",
            external:
              "https://digitallibrary.un.org/search?ln=en&cc=Voting+Data",
            target: true,
          },
        ],
      },
      {
        state: "delegatesHandbook",
        name: "Delegates Handbook",
        type: "external",
        external:
          "https://www.un.org/en/ga/76/pdf/Delegates_Handbook_2021_EN.pdf",
        target: true,
      },
      {
        state: "documents",
        name: "Documents",
        type: "sub",
        children: [
          {
            state: "documentsIssuedAtUNHQToday",
            name: `Documents issued at UNHQ Today`,
          },
          {
            state: "eCorrespondenceEmail",
            name: "e-Correspondence",
          },
          {
            state: "eSubscriptionToUnitedNationsDocuments",
            name: "e-Subscription to United Nations Documents",
            type: "external",
            external: "http://undocs.org/",
            target: true,
          },
        ],
      },
      {
        state: "gMeets",
        name: "gMeets (OSS)",
        type: "external",
        external: "https://conferences.unite.un.org/gMeets",
        target: true,
      },
      {
        state: "meetings",
        name: "Meetings",
        type: "sub",
        children: [
          {
            state: "",
            name: `Calendar of Conferences and Meetings`,
            type: "external",
            external: 'https://www.un.org/calendar/-$lang$-',
            target: true
          },
          {
            state: "meetingsAtUNHQ",
            name: `Meetings at UNHQ`,
          },
          {
            state: "meetingRoom",
            name: "Meeting Room / Area Locator",
          },
          {
            state: "surveyOnConferenceServices",
            name: `Survey on Conference Services`,
            type: "external",
            external: 'https://forms.office.com/e/X5zhX2Kx12',
            target: true
          },
        ],
      },
      {
        state: "spokespersonMorningHeadlines",
        name: `Spokesperson's Morning Headlines`,
      },
      {
        state: "telephoneDirectory",
        name: "Telephone Directory / Yellow Pages",
      },
      {
        state: "trainings",
        name: "Trainings",
        type: "sub",
        children: [
          {
            state: "languageTraining",
            name: "Language Training",
            type: "external",
            external: "https://hr.un.org/page/language-programmes-unhq",
            target: true,
          },
          {
            state: "libraryTraining",
            name: "Library Training",
            type: "external",
            external: "https://un.libcal.com/",
            target: true,
          },
          {
            state: "unitar",
            name: "UNITAR Events",
            type: "external",
            external: "https://unitar.org/event/",
            target: true,
          },
        ],
      },
      {
        state: "vacancies",
        name: "Vacancies",
        type: "sub",
        children: [
          {
            state: "seniorLevelVacancies",
            name: "Senior Level Vacancies",
            type: "external",
            external: "https://www.un.org/sg/-$lang$-/vacancies/index.shtml",
            target: true,
          },
          {
            state: "ICSCVacancies",
            name: "ICSC Vacancies",
            type: "external",
            external: "https://jobs.unicsc.org/",
            target: true,
          },
        ],
      },
      // {
      //   state: "indtoproc",
      //   name: "Index to Proceedings",
      //   type: "external",
      //   external: "https://library.un.org/content/index-proceedings-0",
      //   target: true,
      // },
      // {
      //   state: "feedback",
      //   name: "Feedback",
      // },
    ],
  },
  {
    state: "administration",
    short_label: "administration",
    name: "Secretariat",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "auth",
        name: "Authorization Admin",
        type: "sub",
        children: [
          {
            state: "accessAdmin",
            name: "Authorization Admin",
          },
          {
            state: "accessmanagementsec",
            name: "Entities authorization",
          },
        ],
      },
      {
        state: "userslist",
        name: "List of users",
      },
      {
        state: "proposals",
        name: "Action module",
      },
      {
        state: "eregistrations",
        name: "e-Registration",
        type: "sub",
        children: [
          {
            state: "media",
            name: "Media",
          },
          {
            state: "security",
            name: "Security",
          },
          {
            state: "passandid",
            name: "Pass and ID",
          },
        ],
      },
      {
        state: "InscriptionSetup",
        name: "Inscription Setup",
      },
      {
        state: "emailtemplates",
        name: "Email Templates",
      },
      {
        state: "SecretariatListofSpeakers",
        name: "List of Speakers",
      },
      {
        state: "gd",
        name: "List of Speakers - General Debate",
      },
      {
        state: "statements",
        name: "List of Statements",
      },
      {
        state: "ListOfParticipantsSetup",
        name: "List of Participants Setup",
      },
      {
        state: "electionssetup",
        name: "Candidatures and Elections Setup",
      },
      {
        state: "esubmitsetup",
        name: "e-Submit Setup",
      },
      // {
      //   state: "meetingproceedings",
      //   name: "Meeting Proceedings",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "list",
      //       name: "List of Meetings",
      //     },
      //     {
      //       state: "report",
      //       name: "Report",
      //     },
      //     {
      //       state: "RulesOfProcedure",
      //       name: "Rules of Procedure",
      //     },
      //     {
      //       state: "PGAProcesses",
      //       name: "PGA Processes",
      //     },
      //   ],
      // },
      {
        state: "eRecordedVotesSetup",
        name: "e-Recorded Votes Setup",
      },
      {
        state: "eRecordedVotesSetupHRC",
        name: "e-Recorded Votes Setup HRC",
      },
      {
        state: "eVotes",
        name: "e-Votes",
      },
      {
        state: "votingIntentions",
        name: "Voting Intentions",
      },
      {
        state: "e-blue-book-precis-writers",
        name: "e-BlueBook for precis-writers",
      },
      {
        state: "lookup",
        name: "Lookups",
      },
      {
        state: "technicalSupport",
        name: "Technical Support",
        type: "sub",
        children: [
          {
            state: "cache",
            name: "Cache",
          },
          {
            state: "searchindex",
            name: "Search-Index",
          },
          {
            state: 'search-index-elastic',
            name: 'Bulk Search Index'
          },
          {
            state: "bulk",
            name: "Bulk",
          },
          {
            state: "meta",
            name: "Meta",
          },
          {
            state: "datasync-mpm",
            name: "Datasync MPM",
          },
          {
            state: "import-e-registration-data",
            name: "Import e-Registration Data",
          },
          {
            state: "version",
            name: "Version",
          },
          {
            state: "releaseNotes",
            name: "Release Notes",
          },
        ],
      },
    ],
  },
];

const MENUITEMS_PROD = [
  // {
  //   state: "whatsNew",
  //   short_label: "O",
  //   name: `What's New`,
  //   type: "sub",
  //   icon: "icon-award",
  //   children: [
  //     {
  //       state: "",
  //       name: "Journal of the United Nations",
  //       type: "external",
  //       external: "https://journal.un.org/-$lang$-",
  //       target: true,
  //     },
  //     // {
  //     //   state: "secretarygeneralletters",
  //     //   name: "Secretary-General Letters",
  //     // },
  //     {
  //       state: "eCorrespondenceEmail",
  //       name: "e-Correspondence",
  //     },
  //     {
  //       state: "spokespersonMorningHeadlines",
  //       name: `Spokesperson's Morning Headlines`,
  //     },
  //     {
  //       state: "meetingsAtUNHQ",
  //       name: `Meetings at UNHQ`,
  //     },
  //     {
  //       state: "",
  //       name: `Calendar of Conferences and Meetings`,
  //       type: "external",
  //       external: 'https://www.un.org/calendar/-$lang$-',
  //       target: true
  //     },
  //     {
  //       state: "surveyOnConferenceServices",
  //       name: `Survey on Conference Services`,
  //       type: "external",
  //       external: 'https://forms.office.com/e/X5zhX2Kx12',
  //       target: true
  //     },
  //     {
  //       state: "",
  //       name: "UN News Centre",
  //       type: "external",
  //       external: "https://news.un.org/-$lang$-/",
  //       target: true,
  //     },
  //     {
  //       state: "",
  //       name: `Meetings Coverage & Press Releases`,
  //       type: "external",
  //       external: "https://www.un.org/press/-$lang$-",
  //       target: true,
  //     },
  //     {
  //       state: "documentsIssuedAtUNHQToday",
  //       name: `Documents issued at UNHQ Today`,
  //     },
  //     // {
  //     //   state: "eCorrespondenceEmail",
  //     //   name: `e-Correspondence Email`,
  //     // },
  //   ],
  // },
  {
    state: "generalAssembly",
    short_label: "O",
    name: "General Assembly",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "plenary",
        short_label: "M",
        name: "Plenary",
        type: "sub",
        children: [
          {
            state: "schedule",
            name: "Schedule",
            type: "sub",
            children: [
              {
                state: "",
                name: "Schedule of meetings",
                type: "external",
                external: "https://www.un.org/en/ga/info/meetings/79schedule.shtml",
                target: true,
              },
            ],
          },
          {
            state: "agenda",
            name: "Agenda",
            type: "sub",
            children: [
              {
                state: "",
                name: "79th session",
                type: "external",
                external: "https://www.un.org/en/ga/79/agenda/",
                target: true,
              },
              {
                state: "",
                name: "Status of consideration of agenda items",
                type: "external",
                external: "https://pep.unmeetings.org/reports/ga79_agendaStatus.html",
                target: true,
              },
              {
                state: "annotatedagenda",
                name: "Annotated agenda",
              },
            ],
          },
          {
            state: "resolutionsDecisions",
            name: "Resolutions and Decisions",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/plenary",
                target: false,
              },
              {
                state: "sponsorshipP",
                name: "e-Proposals (Sponsorship and Submission)",
              },
              {
                state: "edecisions",
                name: "e-Decisions",
              },
              {
                state: "votingIntentions",
                name: "e-Voting intentions",
              },
              {
                state: "",
                name: "List of resolutions",
                type: "external",
                external: "https://www.un.org/en/ga/79/resolutions.shtml",
                target: true,
              },
              {
                state: "",
                name: "Submission guidelines",
                type: "external",
                external:
                  "https://www.un.org/en/ga/pdf/guidelines_preparation_co-sponsorship_proposals_submission_GA76.pdf",
                target: true,
              },
              {
                state: "",
                name: "Editing of Proposals at the United Nations",
                type: "external",
                external:
                  "https://www.un.org/en/ga/pdf/guidelines_submit_draft_proposals_jan2017.pdf",
                target: true,
              },
              // {
              //   state: "",
              //   name: "Tutorial on e-Recorded Votes",
              // },
            ],
          },
          {
            state: "speakerlist",
            name: "Speakers list",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersgd",
                name: "General Debate speaker registration",
              },

            ],
          },
          {
            state: "documents",
            name: "Documents",
            type: "sub",
            children: [

              {
                state: "DGACMServices",
                name:
                  "Documentation services for Delegates provided by DGACM",
              },
            ],
          },
          {
            state: "placegaplenary",
            name: "Plenary e-Place",
          },
          {
            state: "gaannouncements",
            name: "Plenary Announcements",
          },
          {
            state: "esubmit",
            name: "e-Submit",
          },
          {
            state: "elections",
            name: "e-Candidatures and Elections",
          },
          {
            state: "candiweb",
            name: "Candiweb",
          },
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
          {
            state: "faqs",
            name: "FAQs",
          },
          {
            state: "stvm",
            name: "Services and tools for virtual meetings",
            type: "sub",
            children: [
              {
                state: "PowerPoint",
                name: "PowerPoint",

              },
              {
                state: "PDF",
                name: "PDF",

              },
            ],
          },
        ],
      },
      // {
      //   state: "HighlevelPoliticalForumonSustainableDevelopment",
      //   short_label: "M",
      //   name: "High-level Political Forum on Sustainable Development",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "eListOfParticipants",
      //       name: "e-List of participants",
      //     },
      //     {
      //       state: "espeakers",
      //       name: "e-Speakers",
      //     },
      //     {
      //       state: "speakersmrt",
      //       name: "SDG Summit Leader's Dialogue",
      //     },
      //   ],
      // },
      // {
      //   state: "ffd",
      //   short_label: "M",
      //   name: "2023 High-level Dialogue on Financing for Development",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Interactive roundtables",
      //     },
      //   ],
      // },
      // {
      //   state: "imrf",
      //   short_label: "M",
      //   name: "International Migration Review Forum",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakers",
      //       name: "e-Speakers",
      //       type: "sub",
      //       children: [
      //         {
      //           state: "espeakers",
      //           name: "General debate",
      //         },
      //         {
      //           state: "speakersld",
      //           name: "Round tables",
      //         },
      //         {
      //           state: "espeakers",
      //           name: "Policy debate",
      //         },
      //       ],
      //     },
      //     {
      //       state: "elistofparticipants",
      //       name: "e-List of participants",
      //     },
      //   ],
      // },
      // {
      //   state: "pppr",
      //   short_label: "M",
      //   name: "HLM on pandemic preparedness prevention and response",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      // {
      //   state: "uhc",
      //   short_label: "M",
      //   name: "HLM on universal health coverage",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      // {
      //   state: "hlmtb",
      //   short_label: "M",
      //   name: "HLM on the fight against tuberculosis",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      // {
      //   state: "hlmar",
      //   short_label: "M",
      //   name: "High-level meeting on antimicrobial resistance",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "espeakers",
      //       name: "e-Speakers",
      //     },
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      // {
      //   state: "sotf",
      //   short_label: "M",
      //   name: "Summit of the Future",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "speakersld",
      //       name: "Interactive dialogues",
      //     },
      //   ],
      // },
      // {
      //   state: "hlmaetpslr",
      //   short_label: "M",
      //   name: "High-level meeting on addressing the existential threats posed by sea level rise",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "espeakers",
      //       name: "e-Speakers",
      //     },
      //     {
      //       state: "speakersld",
      //       name: "Multi-stakeholder panels",
      //     },
      //   ],
      // },
      {
        state: "firstCommittee",
        short_label: "",
        name: "First Committee",
        type: "sub",
        children: [
          {
            state: "pw",
            name: "Programme of work",
            type: "sub",
            children: [
              {
                state: "",
                name:
                  "Programme of work and timetable of the First Committee",
                type: "external",
                external:
                  "https://www.un.org/en/ga/first/79/pdf/A_C1_79_CRP1.pdf",
                target: true,
              },
              {
                state: "",
                name: "Timetable for thematic discussions",
                type: "external",
                external:
                  "https://www.un.org/en/ga/first/79/pdf/A_C1_79_CRP2.pdf",
                target: true,
              },
              {
                state: "",
                name: "Side events calendar",
                type: "external",
                external:
                  "https://meetings.unoda.org/ga-c1/general-assembly-first-committee-seventy-ninth-session-2024",
                target: true,
              },
            ],
          },
          {
            state: "agenda",
            name: "Agenda",
            type: "sub",
            children: [
              {
                state: "",
                name: "Allocation of agenda items",
                type: "external",
                external: "http://undocs.org/en/A/C.1/79/1",
                target: true,
              },
            ],
          },
          {
            state: "draftProposals",
            name: "Draft proposals",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/c1",
                target: false,
              },
              {
                state: "sponsorshipC1",
                name: "First Committee e-Sponsorship",
              },
              {
                state: "votingIntentions",
                name: "First Committee e-Voting intentions",
              },
              {
                state: "GuidelinesForMainSponsors",
                name: "Guidelines for main sponsors",
              },
              {
                state: "GuidelinesForCoSponsors",
                name: "Guidelines for co-sponsors",
              },
              {
                state: "ProcedureForDraftSubmissions",
                name: "Procedure for draft submissions",
              },
            ],
          },
          {
            state: "speakerlist",
            name: "Speaker List",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "documents",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "",
                name: "Status of documentation",
                type: "external",
                external:
                  "https://undocs.org/Home/Mobile?FinalSymbol=A%2fC.1%2f79%2fINF%2f1&Language=E&DeviceType=Desktop&LangRequested=False",
                target: true,
              },
              // {
              //   state: "",
              //   name: "First Committee resolutions and decisions",
              //   type: "external",
              //   external: "https://www.un.org/disarmament/publications/yearbook/",
              //   target: true,
              // },
              {
                state: "",
                name:
                  "Disarmament library including resolutions and decisions",
                type: "external",
                external: "https://www.un.org/disarmament/publications/library/",
                target: true,
              },
              {
                state: "",
                name: "Revitalization of the work of the General Assembly",
                type: "external",
                external: "https://undocs.org/-$lang$-/A/C.1/79/INF/4",
                target: true,
              },
            ],
          },
          {
            state: "bureauSecretariatContacts",
            name: "Bureau - Secretariat",
            type: "sub",
            children: [
              {
                state: "",
                name: "Bureau members",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/first/79/bureau.shtml",
                target: true,
              },
              {
                state: "",
                name: "Secretariat - contact details",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/first/secretariat.shtml",
                target: true,
              },
            ],
          },
          {
            state: "eListOfParticipants",
            name: "e-List of participants",
          },
          {
            state: "esubmit",
            name: "e-Submit",
          },
          {
            state: "",
            name: "First Committee webcast",
            type: "external",
            external:
              "http://webtv.un.org/meetings-events/general-assembly/main-committees/1st-committee/",
            target: true,
          },
          {
            state: "",
            name: "First Committee website",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/first/index.shtml",
            target: true,
          },
          {
            state: "place",
            name: "First Committee e-Place",
          },
          {
            state: "announcements",
            name: "First Committee Announcements",
          },
        ],
      },
      {
        state: "secondCommittee",
        short_label: "M",
        name: "Second Committee",
        type: "sub",
        children: [
          {
            state: "c2schedule",
            name: "Schedule",
            type: "sub",
            children: [
              {
                state: "",
                name: "Second Committee meetings",
                type: "external",
                external:
                  "https://edelegate.un.int/portal/generalAssembly/secondCommittee/c2schedule/meetingsAtUNHQC2",
                target: true,
              },
              {
                state: "",
                name: "Calendar of meetings",
                type: "external",
                external:
                  "https://igov.un.org/ga/c2/79/calendar",
                target: true,
              },
              {
                state: "",
                name: "Organization of work",
                type: "external",
                external: "http://undocs.org/en/A/C.2/79/L.1",
                target: true,
              },
              {
                state: "",
                name: "Special events schedule",
                type: "external",
                external: "https://igov.un.org/ga/c2/79/meetings",
                target: true,
              },
              {
                state: "",
                name: "UN Journal",
                type: "external",
                external: "https://journal.un.org/-$lang$-",
                target: true,
              },
            ],
          },
          {
            state: "draftProposals",
            name: "Draft proposals",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/c2",
                target: false,
              },
              {
                state: "sponsorshipC2",
                name: "e-Proposals (Sponsorship and Submission)",
              },
              {
                state: "votingIntentions",
                name: "e-Voting intentions",
              },
              {
                state: "",
                name: "List of draft proposals",
                type: "external",
                external:
                  "https://igov.un.org/ga/c2/79/documents?menu=proposals",
                target: true,
              },
              {
                state: "",
                name: "Status of action on draft proposals",
                type: "external",
                external:
                  "",
                target: true,
              },
              {
                state: "",
                name: "Procedure for submission of draft proposals",
                type: "external",
                external:
                  "https://www.un.org/en/ga/second/79/docs/submission-guidelines.pdf",
                target: true,
              },

            ],
          },
          {
            state: "c2speakerlist",
            name: "Speaker list",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "",
                name: "LIVE List of speakers",
              },
            ],
          },
          {
            state: "documentation",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "",
                name: "Allocation of agenda items",
                type: "external",
                external: "http://undocs.org/en/A/C.2/79/1",
                target: true,
              },
              {
                state: "",
                name: "Documents per agenda item",
                type: "external",
                external:
                  "https://igov.un.org/ga/c2/79/documents?menu=documents",
                target: true,
              },
              {
                state: "",
                name: "Status of documentation",
                type: "external",
                external: "http://undocs.org/en/A/C.2/79/L.1/Add.1",
                target: true,
              },
              {
                state: "",
                name: "Voting records",
                type: "external",
                external:
                  "",
                target: true,
              },
              {
                state: "",
                name: "Reports to the Plenary",
                type: "external",
                external:
                  "",
                target: true,
              },
            ],
          },
          {
            state: "contactList",
            name: "Contact list",
            type: "sub",
            children: [
              {
                state: "delegatesContactList",
                name: "C2 Delegates contact list",
              },
              {
                state: "",
                name: "Secretariat contact list",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/second/secretariat.shtml",
                target: true,
              },
            ],
          },
          {
            state: "eListOfParticipants",
            name: "e-List of participants",
          },
          {
            state: "place",
            name: "Second Committee e-Place",
          },
          {
            state: "announcements",
            name: "Second Committee Announcements",
          },
          {
            state: "secondCommitteeWebsite",
            name: "Second Committee Website",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/second/index.shtml",
            target: true,
          },
          {
            state: "press",
            name: "Press releases",
            type: "external",
            external: "https://press.un.org/en/second-committee",
            target: true,
          },
          {
            state: "press",
            name: "Second Committee webcast",
            type: "external",
            external:
              "https://webtv.un.org/en/search/categories/meetings-events/general-assembly/79th-session/2nd-committee",
            target: true,
          },
          {
            state: "c2videos",
            name: "Instructional videos",
            type: "sub",
            children: [
              {
                state: "",
                name: "Overview of Second Committee Menu",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/2C_menu.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of Second Committee e-Place",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/2Cplace.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Sponsorship",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/esponsorship.mp4",
                target: true,
              },
              {
                state: "",
                name: "How to initiate a draft resolution for co-sponsorship",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/how%20to%20initiate.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Submission",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/esubmission.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Speakers",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/espeakers.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of LIVE List of Speakers",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/livelist.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Voting intentions",
                type: "external",
                external: "https://www.un.org/en/ga/second/video/evotes.mp4",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "thirdCommittee",
        short_label: "M",
        name: "Third Committee",
        type: "sub",
        children: [
          {
            state: "schedule",
            name: "Schedule",
            type: "sub",
            children: [
              {
                state: "",
                name: "Organization of work",
                type: "external",
                external: "https://undocs.org/en/A/C.3/79/L.1",
                target: true,
              },
              {
                state: "",
                name: "Calendar of meetings",
                type: "external",
                external:
                  "https://igov.un.org/ga/c3/79/calendar",
                target: true,
              },
              {
                state: "meetingsAtUNHQC3",
                name: "Third Committee meetings",
              },
              {
                state: "",
                name:
                  "List of special procedure mandate-holders and the other experts",
                type: "external",
                external:
                  "https://www.un.org/en/ga/third/79/docs/dialogues.pdf",
                target: true,
              },
            ],
          },
          {
            state: "draftProposals",
            name: "Draft proposals",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/c3",
                target: false,
              },
              {
                state: "sponsorshipC3",
                name: "e-Proposals (Sponsorship and Submission)",
              },
              {
                state: "votingIntentions",
                name: "e-Voting intentions",
              },
              {
                state: "",
                name: "Procedure for submission of draft proposals",
                type: "external",
                external:
                  "https://www.un.org/en/ga/third/78/docs/guidelines.pdf",
                target: true,
              },
              {
                state: "",
                name: "List of draft proposals",
                type: "external",
                external:
                  "https://igov.un.org/ga/c3/79/documents?menu=proposals",
                target: true,
              },
              {
                state: "",
                name: "Status of action of draft proposals",
                type: "external",
                external:
                  "",
                target: true,
              },
            ],
          },
          {
            state: "speakerlist",
            name: "Speaker List",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "",
                name: "LIVE list of speakers",
              },
            ],
          },
          {
            state: "documents",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "",
                name: "Allocation of agenda items",
                type: "external",
                external: "https://undocs.org/a/c.3/79/1",
                target: true,
              },
              // {
              //   state: "",
              //   name: "Status of documentation",
              //   type: "external",
              //   external: "https://undocs.org/en/A/C.3/78/L.1/Add.1",
              //   target: true,
              // },
              {
                state: "",
                name: "Documentation per agenda item",
                type: "external",
                external:
                  "https://igov.un.org/ga/c3/79/documents?menu=documents",
                target: true,
              },
              {
                state: "",
                name: "List of draft proposals",
                type: "external",
                external:
                  "https://igov.un.org/ga/c3/79/documents?menu=proposals",
                target: true,
              },
              {
                state: "",
                name: "Status of action of draft proposals",
                type: "external",
                external:
                  "",
                target: true,
              },
              {
                state: "",
                name: "Voting records",
                type: "external",
                external: "",
                target: true,
              },
              {
                state: "",
                name: "Reports to the Plenary",
                type: "external",
                external: "",
                target: true,
              },
            ],
          },
          {
            state: "contactList",
            name: "Contact List",
            type: "sub",
            children: [
              {
                state: "delegatesContactList",
                name: "Delegates Contact List",
              },
              {
                state: "",
                name: "Secretariat Contact List",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/third/secretariat.shtml",
                target: true,
              },
            ],
          },
          {
            state: "eListOfParticipants",
            name: "e-List of participants",
          },
          {
            state: "place",
            name: "Third Committee e-Place",
          },
          {
            state: "announcements",
            name: "Third Committee Announcements",
          },
          {
            state: "",
            name: "Third Committee Website",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/third/index.shtml",
            target: true,
          },
          {
            state: "",
            name: "Press Releases",
            type: "external",
            external: "https://www.un.org/en/ga/third/pr.shtml",
            target: true,
          },
          {
            state: "c3webcast",
            name: "Third Committee webcast",
            type: "external",
            external:
              "http://webtv.un.org/meetings-events/general-assembly/main-committees/3rd-committee/",
            target: true,
          },
          {
            state: "c3videos",
            name: "Instructional videos",
            type: "sub",
            children: [
              {
                state: "",
                name: "Overview of Third Committee Menu",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/3C_menu.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of Third Committee e-Place",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/3Cplace.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Sponsorship",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/esponsorship.mp4",
                target: true,
              },
              {
                state: "",
                name:
                  "How to initiate a draft resolution for co-sponsorship",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/how%20to%20initiate.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Submission",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/esubmission.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Speakers",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/espeakers.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of LIVE List of Speakers",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/livelist.mp4",
                target: true,
              },
              {
                state: "",
                name: "Overview of e-Voting intentions",
                type: "external",
                external: "https://www.un.org/en/ga/third/video/evotes.mp4",
                target: true,
              },

            ],
          },
        ],
      },
      {
        state: "fourthCommittee",
        short_label: "M",
        name: "Fourth Committee",
        type: "sub",
        children: [
          {
            state: "bureauSecretariatContacts",
            name: "Bureau - Secretariat contacts",
            type: "sub",
            children: [
              {
                state: "",
                name: "List of proposals (action module)",
                type: "external",
                external: "/portal/proposals/c4",
                target: false,
              },
              {
                state: "",
                name: "Bureau - contact details",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/fourth/77/bureau77.shtml",
                target: true,
              },
              {
                state: "",
                name: "Secretariat - contact details",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/fourth/secretariat.shtml",
                target: true,
              },
            ],
          },
          {
            state: "sponsorshipC4",
            name: "Fourth Committee e-Sponsorship",
          },
          {
            state: "votingIntentions",
            name: "Fourth Committee e-Voting intentions",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "place",
            name: "Fourth Committee e-Place",
          },
          {
            state: "announcements",
            name: "Fourth Committee Announcements",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "PressReleases",
            name: "Press Releases",
            type: "external",
            external: "https://www.un.org/press/-$lang$-/content/fourth-committee",
            target: true,
          },
          {
            state: "c4webcast",
            name: "Webcast",
            type: "external",
            external: "http://webtv.un.org/",
            target: true,
          },
          {
            state: "relatedLinks",
            name: "Related Links",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/fourth/links.shtml",
            target: true,
          },
          {
            state: "pastSessions",
            name: "Past sessions",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/fourth/archives.shtml",
            target: true,
          },
        ],
      },
      {
        state: "fifthCommittee",
        short_label: "M",
        name: "Fifth Committee",
        type: "sub",
        children: [
          {
            state: "",
            name: "List of proposals (action module)",
            type: "external",
            external: "/portal/proposals/c5",
            target: false,
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "place",
            name: "Fifth Committee e-Place",
          },
          {
            state: "elections",
            name: "e-Candidatures and Elections",
          },
          {
            state: "candiweb",
            name: "Candiweb",
          },
          {
            state: "esubmit",
            name: "e-Submit",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "c5meetings",
            name: "Meetings",
            type: "sub",
            children: [
              {
                state: "",
                name: "eJournal",
                type: "external",
                external: "https://journal.un.org/-$lang$-",
                target: true,
              },
              {
                state: "",
                name: "Programme of work",
                type: "external",
                external:
                  "https://www.un.org/en/ga/fifth/79/main79.shtml",
                target: true,
              },
              {
                state: "",
                name: "Statements",
                type: "external",
                external:
                  "https://www.un.org/en/ga/fifth/79/statements79.0m.shtml",
                target: true,
              },
              {
                state: "",
                name: "Webcast",
                type: "external",
                external:
                  "https://webtv.un.org/en/search/categories/meetings-events/general-assembly/79th-session/5th-committee",
                target: true,
              },
              {
                state: "",
                name: "Press releases",
                type: "external",
                external:
                  "https://www.un.org/press/-$lang$-/content/fifth-committee",
                target: true,
              },
            ],
          },
          {
            state: "c5documents",
            name: "Documents",
            type: "sub",
            children: [
              {
                state: "",
                name: "Agenda items",
                type: "external",
                external: "https://www.un.org/en/ga/fifth/79/agenda79.shtml",
                target: true,
              },
              {
                state: "",
                name: "Status of documentation",
                type: "external",
                external: "https://www.un.org/en/ga/fifth/79/main79.shtml",
                target: true,
              },
              {
                state: "",
                name: "Resolutions and decisions",
                type: "external",
                external:
                  "https://www.un.org/en/ga/fifth/79/resdec79.shtml",
                target: true,
              },
              {
                state: "place",
                name: "Fifth Committee e-Place",
              },
            ],
          },

          {
            state: "c5contacts",
            name: "Contact information",
            type: "sub",
            children: [

              {
                state: "",
                name: "Secretariat contact information",
                type: "external",
                external:
                  "https://edelegate.un.int/portal/doc/placec5/download?uploadName=66ce216058f6f2db3c664043~C5_79_0m_Secretariat Contact Information Sheet.pdf&fileName=C5_79_0m_Secretariat Contact Information Sheet.pdf",
                target: true,
              },
            ],
          },
          {
            state: "c5about",
            name: "About",
            type: "sub",
            children: [
              {
                state: "",
                name: "C5 website on un.org",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/fifth/index.shtml",
                target: true,
              },
              {
                state: "",
                name: "Past sessions",
                type: "external",
                external: "https://www.un.org/en/ga/fifth/previous.shtml",
                target: true,
              },
              {
                state: "",
                name: "Frequently asked questions",
                type: "external",
                external: "https://www.un.org/en/ga/fifth/faq.shtml",
                target: true,
              },
              {
                state: "",
                name: "Related links",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/fifth/rl.shtml",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "sixthCommittee",
        short_label: "M",
        name: "Sixth Committee",
        type: "sub",
        children: [
          {
            state: "sponsorshipC6",
            name: "Sixth Committee e-Sponsorship",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "c6meetings",
            name: "Scheduled meetings",
            // type: "external",
            // external:
            //   "https://delegate.un.int/dgacm/delegate.nsf/xpMembershipList.xsp",
            // target: true,
          },
          {
            state: "contactList",
            name: "Delegates contact list",
          },
          {
            state: "c6website",
            name: "Website",
            type: "external",
            external: "https://www.un.org/-$lang$-/ga/sixth/",
            target: true,
          },
        ],
      },
      {
        state: "subsidiaryBodies",
        short_label: "M",
        name: "Subsidiary Bodies",
        type: "sub",
        children: [
          {
            state: "ahcc",
            name: "Ad Hoc Committee on Cybercrime",
            type: "sub",
            children: [
              {
                state: "sponsorshipAHCC",
                name: "e-Proposals (Sponsorship and Submission)",
              }
            ],
          },
          {
            state: "AHWGArevitalization",
            name: "Ad Hoc Working Group on GA revitalization",
            type: "sub",
            children: [
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/revitalization/index.shtml",
                target: true,
              },
              {
                state: "Documents",
                name: "Documents",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/revitalization/revital_docs.shtml",
                target: true,
              },
              {
                state: "Announcements",
                name: "Announcements",
              }
            ],
          },
          {
            state: "ahict",
            name: "AHIC to draft terms of reference.....on international tax cooperation",
            type: "sub",
            children: [
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "place",
                name: "e-Place",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "BiennialMeetingonSmallArms",
            name: "Biennial Meeting on Small Arms",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
            ],
          },
          {
            state: "coc",
            name: "Committee on Conferences",
            type: "sub",
            children: [
              {
                state: "Website",
                name: "Website",
                type: "external",
                external: "https://www.un.org/dgacm/-$lang$-/content/coc",
                target: true,
              },
              {
                state: "Proposedagendafor2020",
                name: "Proposed agenda for 2024",
                type: "external",
                external: "https://undocs.org/en/A/AC.172/2024/1",
                target: true,
              },
              {
                state: "Documents",
                name: "Documents",
                type: "external",
                external:
                  "https://www.un.org/dgacm/-$lang$-/content/coc/pre-session",
                target: true,
              },
              // {
              //   state: "Announcements",
              //   name: "Announcements",
              // },
              {
                state: "COCPlace",
                name: "COC e-Place",
              },
              // {
              //   state: "espeakers",
              //   name: "e-Speakers",
              // },
            ],
          },
          {
            state: "disarmamentCommission",
            name: "Disarmament Commission",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "Bureau",
                name: "Bureau",
              },
              {
                state: "Secretariatcontactdetails",
                name: "Secretariat - contact details",
              },
              {
                state: "Schedule",
                name: "Schedule",
                // type: "external",
                // external:
                //   "https://coc.dgacm.org/documents/substantive-session",
                // target: true,
              },
              {
                state: "agendaorganizationalsession",
                name: "Agenda (organizational session)",
                type: "external",
                external:
                  "https://undocs.org/A/CN.10/L.85",
                target: true,
              },
              {
                state: "agendasubstantivesession",
                name: "Agenda (substantive session)",
                type: "external",
                external:
                  "https://undocs.org/A/CN.10/L.86",
                target: true,
              },
              {
                state: "UNDCAnnouncements",
                name: "UNDC Announcements",
              },
              {
                state: "UNDCPlace",
                name: "UNDC e-Place",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/disarmament/institutions/disarmament-commission/session-2022/",
                target: true,
              },
            ],
          },
          {
            state: "GeneralCommittee",
            name: "General Committee",
            type: "sub",
            children: [
              {
                state: "Website",
                name: "Website",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/general/general.shtml",
                target: true,
              },
              {
                state: "Documents",
                name: "Documents",
                type: "external",
                external: "https://www.un.org/-$lang$-/ga/76/agenda/",
                target: true,
              },
            ],
          },
          {
            state: "HumanRightsCouncil",
            name: "Human Rights Council",
            type: "sub",
            children: [
              {
                state: "sponsorshipHRC",
                name: "Human Rights Council e-Sponsorship",
              },
              {
                state: "Guidelinesformainsponsors",
                name: "Guidelines for main sponsors",
              },
              {
                state: "Guidelinesforco-sponsors",
                name: "Guidelines for co-sponsors",
              },
              {
                state: "eRecordedVotesHRC",
                name: "e-Recorded votes",
              },
            ],
          },
          {
            state: "OEWGonAgeing",
            name: "OEWG on Ageing",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "sponsorshipOEWGA",
                name: "e-Proposals (Sponsorship and Submission)",
              },
            ],
          },
          {
            state: "OEWGonConvAmm",
            name: "OEWG on Conventional Ammunition",
            type: "sub",
            children: [
              // {
              //   state: "espeakers",
              //   name: "e-Speakers",
              // },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "place",
                name: "OEWG on Conventional Ammunition e-Place"
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external: "https://meetings.unoda.org/node/62086",
                target: true,
              },
            ],
          },
          {
            state: "OEWGonSecurity",
            name: "OEWG on Security of ICTs 2021-2025",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "Agenda",
                name: "Agenda",
                // type: "external",
                // external: "https://undocs.org/en/A/AC.290/2019/1",
                // target: true,
              },
              {
                state: "OEWGonSecurityPlace",
                name: "OEWG on Security e-Place",
              },
            ],
          },
          {
            state: "OEWGonInformationandTelecommunications",
            name: "OEWG on Information and Telecomm.(MANDATE COMPLETED)",
            type: "sub",
            children: [
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/disarmament/open-ended-working-group/",
                target: true,
              },
              {
                state: "Agenda",
                name: "Agenda",
                type: "external",
                external: "https://undocs.org/-$lang$-/A/AC.290/2019/1",
                target: true,
              },
              {
                state: "Chairmanship",
                name: "Chairmanship",
                type: "external",
                external:
                  "https://www.eda.admin.ch/dam/mission-new-york/en/documents/Lauber-Juerg-2015_EN.pdf",
                target: true,
              },
              {
                state: "Secretariat",
                name: "Secretariat",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/ga/first/secretariat.shtml",
                target: true,
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
              {
                state: "OEWGonInformationandTelecommunicationsPlace",
                name: "OEWG on Information and Telecommunications e-Place",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "Firstsubstantivesession(9-13Sep2019)",
                name:
                  "First substantive session (9-13 Sep 2019) - Programme of work",
                type: "external",
                external: "https://undocs.org/-$lang$-/A/AC.290/2019/2",
                target: true,
              },
              {
                state: "Secondsubstantivesession(10-14Feb2020)",
                name:
                  "Second substantive session (10-14 Feb 2020) - Programme of work",
                type: "external",
                external: "https://undocs.org/-$lang$-/A/AC.290/2020/1",
                target: true,
              },
              {
                state: "Thirdsubstantivesession(8-12Mar2021)",
                name: "Third substantive session (8-12 Mar 2021) - Programme of work",
              },
            ],
          },
          {
            state: "PeacebuildingCommission",
            name: "Peacebuilding Commission",
            type: "sub",
            children: [
              {
                state: "Membership",
                name: "Membership and Bureau",
                type: "external",
                external:
                  "https://www.un.org/peacebuilding/commission/membership",
                target: true,
              },
              {
                state: "Mandate",
                name: "Mandate",
                type: "external",
                external:
                  "https://www.un.org/peacebuilding/commission/mandate",
                target: true,
              },
              {
                state: "Documents",
                name: "Documents",
                type: "external",
                external: "https://www.un.org/peacebuilding/documents",
                target: true,
              },
            ],
          },
          {
            state: "SpecialCommitteeonDecolonization",
            name: "Special Committee on Decolonization (C-24)",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "C24Place",
                name: "C24 e-Place",
              },
              {
                state: "Announcements",
                name: "Announcements",
              },
              {
                state: "sponsorshipC24",
                name: "C-24 e-Sponsorship",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "specialCommitteeOnPeacekeepingOperations",
            name: "Special Committee on Peacekeeping Operations (C-34)",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "place",
                name: "C-34 e-Place",
              },
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "eListOfParticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "UNCharterCommittee",
            name: "Special Committee on the Charter",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "TowardsaGlobalPactfortheEnvironment",
            name: "Towards a Global Pact for the Environment",
            type: "sub",
            children: [
              {
                state: "",
                name: "e-List of participants",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "UNRWAPledgingConference",
            name: "UNRWA Pledging Conference",
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
        ],
      },
      {
        state: "searchelectedofficers",
        short_label: "M",
        name: "Search elected officers",
        type: "",
      },
    ],
  },
  {
    state: "securitycouncil",
    short_label: "O",
    name: "Security Council",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "placesc",
        name: "Security Council e-Place",
      },
      {
        state: "espeakers",
        name: "Security Council e-Speakers",
      },
      {
        state: "sponsorshipSC",
        name: "Security Council e-Sponsorship",
      },
      {
        state: "announcements",
        name: "Security Council Announcements",
      },
      {
        state: "SubsidiaryOrgans",
        name: "Subsidiary Organs",
        type: "sub",
        children: [
          {
            state: "placesc1267",
            name: "ISIL (Da'esh) and Al-Qaida Sanctions Committee e-Place",
          },
          {
            state: "placesc1373",
            name: "Counter-Terrorism Committee e-Place",
          },
          {
            state: "placesc1518",
            name: "1518 Sanctions Committee (Iraq) e-Place",
          },
          {
            state: "placesc1533",
            name: "DRC Sanctions Committee e-Place",
          },
          {
            state: "placesc1540",
            name: "1540 Committee e-Place",
          },
          {
            state: "placesc1591",
            name: "Sudan Sanctions Committee e-Place",
          },
          {
            state: "placesc1718",
            name: "1718 Sanctions Committee (DPRK) e-Place",
          },
          {
            state: "placesc1970",
            name: "Libya Sanctions Committee e-Place",
          },
          {
            state: "placesc1988",
            name: "1988 Sanctions Committee e-Place",
          },
          {
            state: "placesc2048",
            name: "Guinea-Bissau Sanctions Committee e-Place",
          },
          {
            state: "placesc2140",
            name: "2140 Sanctions Committee (Yemen) e-Place",
          },
          {
            state: "placesc2206",
            name: "South Sudan Sanctions Committee e-Place",
          },
          {
            state: "placesc2374",
            name: "Mali Sanctions Committee e-Place",
          },
          {
            state: "placesc2653",
            name: "2653 Sanctions Committee e-Place",
          },
          {
            state: "placesc2745",
            name: "2745 Sanctions Committee e-Place",
          },
          {
            state: "placesc751",
            name: "2713 Sanctions Committee e-Place",
          },
          {
            state: "placewgcaac",
            name: "Working Group on Children and Armed Conflict e-Place",
          },
          {
            state: "placesciwg",
            name: "Informal Working Group on Documentation and other Procedural Questions e-Place",
          },
        ],
      },
      {
        state: "Resolution2231(2015)",
        name: "Resolution 2231 (2015) e-Place",
      },
      {
        state: "PeacebuildingCommission",
        name: "Peacebuilding Commission",
        type: "sub",
        children: [
          {
            state: "MembershipandBureau",
            name: "Membership and Bureau",
            type: "external",
            external:
              "https://www.un.org/peacebuilding/commission/membership",
            target: true,
          },
          {
            state: "Mandate",
            name: "Mandate",
            type: "external",
            external: "https://www.un.org/peacebuilding/commission/mandate",
            target: true,
          },
          {
            state: "Documents",
            name: "Documents",
            type: "external",
            external: "https://www.un.org/peacebuilding/documents",
            target: true,
          },
        ],
      },
    ],
  },
  {
    state: "ecosoc",
    short_label: "O",
    name: "ECOSOC",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "en",
        name: "ECOSOC meetings/segments/forums",
        type: "sub",
        children: [
          {
            state: "schedule",
            name: "Schedule",
            type: "external",
            external: "https://www.un.org/ecosoc/en/events",
            target: true,
          },
          {
            state: "ecosocmeetings",
            name: "ECOSOC meetings",
          },
          {
            state: "agenda",
            name: "Agenda",
            type: "external",
            external: "https://www.un.org/ecosoc/en/content/agenda-working-arrangements-and-programme-work",
            target: true,
          },
          {
            state: "resolutionsDecisions",
            name: "Resolutions and Decisions",
            type: "sub",
            children: [
              {
                state: "sponsorshipECOSOC",
                name: "ECOSOC e-Sponsorship",
              },
              {
                state: "edecisions",
                name: "ECOSOC Decisions",
              },
              {
                state: "",
                name: "ECOSOC Resolutions",
                type: "external",
                external:
                  "https://www.un.org/ecosoc/en/documents/resolutions",
                target: true,
              },
              {
                state: "votingIntentions",
                name: "ECOSOC e-Voting intentions",
              },
              {
                state: "",
                name: "Submission guidelines",
              },
            ],
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "place",
            name: "ECOSOC e-Place",
          },
          {
            state: "ECOSOCWebsite",
            name: "ECOSOC Website",
            type: "external",
            external: "https://www.un.org/ecosoc/en/",
            target: true,
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "speakersld",
            name: "ECOSOC Youth Forum e-Speakers",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "elections",
            name: "Elections",
            type: "sub",
            children: [
              {
                state: "elections",
                name: "e-Candidatures and Elections",
              },
              {
                state: "candiweb",
                name: "Candiweb",
              },
            ],
          },
          {
            state: "searchmembership",
            name: "Search membership",
          },
        ],
      },
      {
        state: "committeeforprogrammeandcoordination",
        name: `Committee for Programme and Coordination`,
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "place",
            name: "CPC e-Place (Restricted)",
          },
          {
            state: "cpcwebsite",
            name: "CPC Website (Public)",
            type: "external",
            external: "https://www.un.org/en/ga/cpc/",
            target: true,
          },
        ],
      },
      {
        state: "commissionForSocialDevelopment",
        name: "Commission for Social Development",
        type: "sub",
        children: [
          {
            state: "organizationOfWork",
            name: "Proposed Organization of Work",
            type: "external",
            external:
              "https://undocs.org/E/CN.5/2022/1",
            target: true,
          },
          {
            state: "CSOCDMeetings",
            name: "CSOCD meetings",
          },
          {
            state: "draftProposals",
            name: "Draft Proposals",
            type: "sub",
            children: [
              {
                state: "sponsorshipCSOCD",
                name: "CSOCD e-Sponsorship",
              },
              {
                state: "votingIntentions",
                name: "CSOCD e-Voting intentions",
              },
            ],
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "livespeakers",
            name: "LIVE List of speakers",
            type: "external",
            external:
              "http://espeakers.unmeetings.org/61e82887a17e2700128a2d9519012022/index.html",
            target: true,
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "CSOCDWebsite",
            name: "Website",
            type: "external",
            external:
              "https://www.un.org/development/desa/dspd/united-nations-commission-for-social-development-csocd-social-policy-and-development-division/csocd60.html",
            target: true,
          },
        ],
      },
      {
        state: "commissionOnPopulationAndDevelopment",
        name: "Commission on Population and Development",
        type: "sub",
        children: [
          {
            state: "CPDMeetings",
            name: "CPD meetings",
          },
          {
            state: "sponsorshipCPD",
            name: "CPD e-Sponsorship",
          },
          {
            state: "votingIntentions",
            name: "CPD e-Voting intentions",
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "livelist",
            name: "CPD LIVE list of Speakers",
            type: "external",
            external:
              "http://espeakers.unmeetings.org/6419f6514d099d5e1bb3948b21032023/index.html",
            target: true,
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "cpdwebsite",
            name: "Website",
            type: "external",
            external:
              "https://www.un.org/development/desa/pd/content/CPD",
            target: true,
          },
        ],
      },
      {
        state: "commissionOnTheStatusOfWomen",
        name: "Commission on the Status of Women",
        type: "sub",
        children: [
          {
            state: "organizationOfWork",
            name: "Organization of Work",
            type: "external",
            external:
              "https://undocs.org/E/CN.6/2024/1/Add.1",
            target: true,
          },
          {
            state: "CSW62MeetingsInUNHQ",
            name: "CSW Meetings",
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "sponsorshipCSW",
            name: "e-Sponsorship",
          },
          {
            state: "votingIntentions",
            name: "CSW e-Voting intentions",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "speakersmrt",
            name: "Ministerial Roundtables",
          },
          // {
          //   state: "cswliveListOfSpeakers",
          //   name: "LIVE list of speakers",
          //   type: "sub",
          //   children: [
          //     {
          //       state: "cswgeneraldiscussion",
          //       name: "General discussion",
          //       type: "external",
          //       external:
          //         "http://espeakers.unmeetings.org/620402a609eff10011ab949109022022/index.html",
          //       target: true,
          //     },
          //     // {
          //     //   state: "cswmrt1",
          //     //   name: "MRT 1 (",
          //     //   type: "external",
          //     //   external:
          //     //     "http://espeakers.unmeetings.org/604b800bf0341900116275cd12032021/index.html",
          //     //   target: true,
          //     // },
          //     // {
          //     //   state: "cswmrt2",
          //     //   name: "MRT 2 (15 March 5-6 pm)",
          //     //   type: "external",
          //     //   external:
          //     //     "http://espeakers.unmeetings.org/604b929ef0341900116276ae12032021/index.html",
          //     //   target: true,
          //     // },
          //     // {
          //     //   state: "cswmrt3",
          //     //   name: "MRT 3 (16 March 9-10 am)",
          //     //   type: "external",
          //     //   external:
          //     //     "http://espeakers.unmeetings.org/604bc446f03419001162797a12032021/index.html",
          //     //   target: true,
          //     // },
          //     // {
          //     //   state: "cswmrt4",
          //     //   name: "MRT 4 (16 March 10-11 am)",
          //     //   type: "external",
          //     //   external:
          //     //     "http://espeakers.unmeetings.org/604bc54cf03419001162799f12032021/index.html",
          //     //   target: true,
          //     // },
          //   ],
          // },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "CSWDocuments",
            name: "Documents",
            type: "external",
            external:
              "https://www.unwomen.org/en/csw/csw68-2024/official-documents",
            target: true,
          },
          {
            state: "CSWWebsite",
            name: "Website",
            type: "external",
            external: "https://www.unwomen.org/en/how-we-work/commission-on-the-status-of-women",
            target: true,
          },
        ],
      },
      {
        state: "cnd",
        name: "Commission on Narcotic Drugs",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipCND",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },
      {
        state: "ccpcj",
        name: "Commission on Crime Prevention and Criminal Justice",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipCCPCJ",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },
      {
        state: "CommitteeonNon-GovernmentalOrganizations",
        name: "Committee on Non-Governmental Organizations",
        type: "sub",
        children: [
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "CNGOswebsite",
            name: "CNGOs website",
            type: "external",
            external: "https://ecosoc.un.org/en/ngo/committee-on-ngos",
            target: true,
          },
          {
            state: "ProvisionalAgenda",
            name: "Provisional Agenda",
            type: "external",
            external: "https://undocs.org/Home/Mobile?FinalSymbol=E%2fC.2%2f2024%2f1%2fRev.1&Language=E&DeviceType=Desktop&LangRequested=False",
            target: true,
          },
          // {
          //   state: "ProgrammeofWork",
          //   name: "Programme of Work",
          //   type: "external",
          //   external:
          //     "https://csonet.org/content/documents/Information%20Note%202023%20Resumed%20session_1%20May%202023_FINAL.pdf",
          //   target: true,
          // },
          {
            state: "ReportsoftheCommittee",
            name: "Reports of the Committee",
            type: "external",
            external: "https://ecosoc.un.org/en/ngo/committee-reports",
            target: true,
          },
        ],
      },
      {
        state: "permanentForumOnIndigenousIssues",
        name: `Permanent Forum on Indigenous Issues`,
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "place",
            name: "PFII e-Place",
          },

        ],
      },
      {
        state: "StatisticalCommission",
        name: "Statistical Commission",
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "CN52ndSessionWebsiteGOswebsite",
            name: "Website",
            type: "external",
            external: "https://unstats.un.org/UNSDWebsite/statcom/54",
            target: true,
          },
          {
            state: "2021ProvisionalAgenda",
            name: "Provisional Agenda",
            type: "external",
            external:
              "https://documents-dds-ny.un.org/doc/UNDOC/GEN/N22/714/48/PDF/N2271448.pdf?OpenElement",
            target: true,
          },
          {
            state: "ProgrammeofWork",
            name: "Programme of Work and Timetable",
            type: "external",
            external: "https://documents-dds-ny.un.org/doc/UNDOC/LTD/N22/733/70/PDF/N2273370.pdf?OpenElement",
            target: true,
          },
        ],
      },
      {
        state: "UnitedNationsCommitteeofExpertsonGGIM",
        name: "United Nations Committee of Experts on Global Geospatial Information Management",
        type: "sub",
        children: [
          {
            state: "Announcements",
            name: "Announcements",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of participants",
          },

        ],
      },
      {
        state: "unitedNationsForumOnForests",
        name: `United Nations Forum on Forests`,
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "sponsorshipFF",
            name: "e-Sponsorship",
          },
          {
            state: "votingIntentions",
            name: "UNFF e-Voting intentions",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "meetingsAtUNFF",
            name: "UNFF Meetings",
          },
          {
            state: "Website",
            name: "Website",
            type: "external",
            external:
              "https://www.un.org/esa/forests/index.html",
            target: true,
          },
        ],
      },

      {
        state: "UnitedNationsGroupofExpertsonGeographicalNames",
        name: "United Nations Group of Experts on Geographical Names",
        type: "sub",
        children: [
          {
            state: "Announcements",
            name: "Announcements",
          },
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "Website",
            name: "Website",
            type: "external",
            external:
              "hhttps://unstats.un.org/unsd/ungegn/",
            target: true,
          },
        ],
      },

    ],
  },
  {
    state: "Conference",
    short_label: "C",
    name: "Conference",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "confcd",
        name: `Conference on Disarmament`,
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "elistofparticipants",
            name: "e-List of participants",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
        ],
      },
      {
        state: "confsids",
        name: `SIDS Conference`,
        type: "sub",
        children: [
          {
            state: "prepcom",
            name: `PrepCom 1 & 2`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "sids",
            name: `Conference`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Interactive dialogues",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://sdgs.un.org/smallislands",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "conflldc",
        name: `Conference on Landlocked Developing Countries`,
        type: "sub",
        children: [
          {
            state: "prepcomlldc",
            name: `PrepCom 1 & 2`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "lldc",
            name: `Conference`,
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Thematic round tables",
              },
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://www.un.org/en/landlocked/programme",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "confsalw",
        name: `4th UN Conference to review progress...Small Arms and Light Weapons`,
        type: "sub",
        children: [
          {
            state: "prepcomsalw",
            name: `Preparatory Committee`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://meetings.unoda.org/poa-salw-prepcom/programme-of-action-on-small-arms-and-light-weapons-preparatory-committee-2024",
                target: true,
              },
            ],
          },
          {
            state: "salw",
            name: `Review Conference`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://meetings.unoda.org/poa-salw-revcon/programme-of-action-on-small-arms-and-light-weapons-review-conference-2024",
                target: true,
              },
            ],
          },
        ],
      },
      {
        state: "conficffd",
        name: `International Conference on Financing for Development`,
        type: "sub",
        children: [
          {
            state: "prepcomicffd",
            name: `PrepCom`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "place",
                name: "e-Place",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
            ],
          },
          {
            state: "icffd",
            name: `Conference`,
            type: "sub",
            children: [
              {
                state: "ConferenceWebsite",
                name: "Website",
                type: "external",
                external: "https://financing.desa.un.org/fourth-international-conference-financing-development",
                target: true,
              },
            ],
          },
        ],
      },



      {
        state: "ConferenceofStatesPartiestoCRPD",
        name: `17th Conference of States Parties to CRPD`,
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
          {
            state: "ConferencePlace",
            name: "CRPD e-Place",
          },
          {
            state: "ConferenceWebsite",
            name: "Conference Website",
            type: "external",
            external: "https://www.un.org/development/desa/disabilities/",
            target: true,
          },
          {
            state: "12thsessionoftheConferenceWebsite",
            name: "17th session of the Conference Website",
            type: "external",
            external:
              "https://social.desa.un.org/issues/disability/cosp/17th-session-of-the-conference-of-states-parties-to-the-crpd-cosp17",
            target: true,
          },
        ],
      },
      {
        state: "NPT",
        name: `NPT Review Conference`,
        type: "sub",
        children: [
          {
            state: "eListOfParticipants",
            name: "e-List of Participants",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          // {
          //   state: "ecredentials",
          //   name: "e-Credentials",
          // },
          // {
          //   state: "Website",
          //   name: "Website",
          //   type: "external",
          //   external:
          //     "https://www.un.org/en/conferences/npt2020",
          //   target: true,
          // },
        ],
      },


      {
        state: "BBNJIntergovernmentalConference",
        name: `BBNJ Agreement Preparatory Commission`,
        type: "sub",
        children: [
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
          {
            state: "elistofparticipants",
            name: "e-List of participants",
          },
        ],
      },
      {
        state: "unclos",
        name: `34th Meeting of States Parties to UNCLOS`,
        type: "sub",
        children: [
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
        ],
      },

      {
        state: "confmezfnwmd",
        name: `Conference on MEZFNWMD`,
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "elistofparticipants",
            name: "e-List of participants",
          },
          {
            state: "ecredentials",
            name: "e-Credentials",
          },
          {
            state: "announcements",
            name: "Announcements",
          },

        ],
      },


      {
        state: "pastconf",
        name: `Past Conferences`,
        type: "sub",
        children: [
          {
            state: "hldonenergy",
            name: "High-level Dialogue on Energy",
            type: "external",
            external: "/portal/generalAssembly/plenary/speakerlist/espeakers",
            target: false,
          },
          {
            state: "ctbt",
            name: `Conference on Facilitating the Entry into Force of the CTBT`,
            type: "sub",
            children: [
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
            ],
          },
          {
            state: "pdc",
            name: "2021 Pledging Conference",
            type: "sub",
            children: [
              {
                state: "placegaplenary",
                name: "Plenary e-Place",
              },
            ],
          },
          {
            state: "ldc",
            name: `5th United Nations Conference on the Least Developed Countries`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersrt",
                name: "High-level thematic roundtables",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "elistofparticipants",
                name: "e-List of Participants",
              },
              {
                state: "placeconfldc",
                name: "Conference on LDC e-Place",
              },
              {
                state: "ldcmeetings",
                name: "Conference on LDC Meetings",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/ohrlls/content/fifth-united-nations-conference-least-developed-countries-ldc5",
                target: true,
              },
            ],
          },

          {
            state: "2ndHLUNConferenceonSouthSouthCooperation",
            name: `2nd HL UN Conference on South-South Cooperation`,
            type: "sub",
            children: [
              {
                state: "ConferencePlace",
                name: "Conference e-Place",
              },
            ],
          },

          {
            state: "gstc",
            name: `United Nations Global Sustainable Transport Conference`,
            type: "sub",
            children: [
              {
                state: "eListOfParticipants",
                name: "e-List of Participants",
              },
              {
                state: "espeakers",
                name: "e-List of Speakers",
              },
              {
                state: "gstcmeetings",
                name: "e-Meetings",
              },
            ],
          },

          {
            state: "ocean",
            name: `2022 UN Ocean Conference`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Interactive dialogues",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.un.org/-$lang$-/conferences/ocean2022",
                target: true,
              },
            ],
          },




          {
            state: "stockholm50",
            name: `Stockholm +50`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Leadership dialogues",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://www.stockholm50.global/",
                target: true,
              },
            ],
          },
          {
            state: "tpnw",
            name: `TPNW Meeting of States Parties`,
            type: "sub",
            children: [
              {
                state: "place",
                name: "TPNW e-Place",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "Website",
                name: "Website",
                type: "external",
                external:
                  "https://meetings.unoda.org/tpnw/tpnw-msp-2023",
                target: true,
              },
            ],
          },

          {
            state: "water",
            name: `2023 Water Conference`,
            type: "sub",
            children: [
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "speakersld",
                name: "Interactive dialogues",
              },
              {
                state: "elistofparticipants",
                name: "e-List of participants",
              },
              {
                state: "ecredentials",
                name: "e-Credentials",
              },
            ],
          },












        ],
      },





    ],
  },
  {
    state: "geneva",
    short_label: "U",
    name: "Geneva",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "apmbc",
        name: `Anti-Personnel Mine Ban Convention`,
        type: "sub",
        children: [
          {
            state: "elistofparticipants",
            name: "e-List of Participants",
          },
        ],
      },
      {
        state: "gencd",
        name: `Conference on Disarmament`,
        type: "sub",
        children: [
          {
            state: "announcements",
            name: "Announcements",
          },
          {
            state: "elistofparticipants",
            name: "e-List of Participants",
          },
          {
            state: "espeakers",
            name: "e-Speakers",
          }
        ],
      },
      {
        state: "ccw",
        name: `Convention on Certain Conventional Weapons`,
        type: "sub",
        children: [
          {
            state: "elistofparticipants",
            name: "e-List of Participants",
          },
        ],
      },
      {
        state: "ccm",
        name: `Convention on Cluster Munitions`,
        type: "sub",
        children: [
          {
            state: "elistofparticipants",
            name: "e-List of Participants",
          },
        ],
      },
      {
        state: "HumanRightsCouncil",
        name: "Human Rights Council",
        type: "sub",
        children: [
          {
            state: "sponsorshipHRC",
            name: "Human Rights Council e-Sponsorship",
          },
          {
            state: "Guidelinesformainsponsors",
            name: "Guidelines for main sponsors",
          },
          {
            state: "Guidelinesforco-sponsors",
            name: "Guidelines for co-sponsors",
          },
          {
            state: "eRecordedVotesHRC",
            name: "e-Recorded votes",
          },
        ],
      },
    ],
  },
  {
    state: "vienna",
    short_label: "U",
    name: "Vienna",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "ahcc",
        name: `Ad Hoc Committee on Cybercrime`,
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Proposals (Sponsorship and Submission)",
          }

        ],
      },
      {
        state: "cnd",
        name: "Commission on Narcotic Drugs",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipCND",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },
      {
        state: "ccpcj",
        name: "Commission on Crime Prevention and Criminal Justice",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipCCPCJ",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },
      {
        state: "untoc",
        name: "Conference of the Parties to the United Nations Convention against Transnational Organized Crime",
        type: "sub",
        children: [
          {
            state: "espeakers",
            name: "e-Speakers",
          },
          {
            state: "sponsorshipUNTOC",
            name: "e-Proposals (Sponsorship and Submission)",
          },
        ],
      },

    ],
  },
  {
    state: "nairobi",
    short_label: "U",
    name: "Nairobi",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "unep",
        name: "UNEP",
        type: "sub",
        children: [
          {
            state: "unea",
            name: `UN Environment Assembly`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "espeakers",
                name: "e-Speakers",
              },
              {
                state: "esubmit",
                name: "e-Submit",
              },

            ],
          },
          {
            state: "cpr",
            name: `Committee of Permanent Representatives`,
            type: "sub",
            children: [
              {
                state: "announcements",
                name: "Announcements",
              },
              {
                state: "esubmit",
                name: "e-Submit",
              },
            ],
          },
        ]
      }

    ],
  },
  // {
  //   state: "unep",
  //   short_label: "U",
  //   name: "UNEP",
  //   type: "sub",
  //   icon: "icon-award",
  //   children: [
  //     {
  //       state: "unea",
  //       name: `UN Environment Assembly`,
  //       type: "sub",
  //       children: [
  //         {
  //           state: "espeakers",
  //           name: "e-Speakers",
  //         },
  //         {
  //           state: "esubmit",
  //           name: "e-Submit",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    state: "protocol",
    short_label: "O",
    name: "Protocol",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "protocolAndLiaisonService",
        name: "Protocol and Liaison Service",
        type: "external",
        external: "https://www.un.org/dgacm/-$lang$-/content/protocol",
        target: true,
      },
      {
        state: "emissionstaff",
        name: "e-Mission registration of personnel",
      },
      {
        state: "eregistration",
        name: "Registration to meetings and conferences",
      },
      {
        state: "tickets",
        name: "Reservation for courtesy tickets for the general debate",
      },
      // {
      //   state: "pastConferences",
      //   name: "Past Conferences",
      // },
      {
        state: "staffregistration",
        name: "Conference registration for UN secretariat and entities",
      },
      {
        state: "TempDecalDriverRequests",
        name: "Temporary pick up - drop off permits",
      },
      // {
      //   state: "",
      //   name: "Member States",
      // },
      {
        state: "blueBook",
        name: "Blue Book",
        type: "external",
        external: "https://protocol.un.org/dgacm/pls/site.nsf/BlueBook.xsp",
        target: true,
      },
      {
        state: "eblueBook",
        name: "e-Blue Book",
        type: "external",
        external:
          "https://bluebook.unmeetings.org",
        target: true,
      },
    ],
  },
  {
    state: "resources",
    short_label: "rsrcs",
    name: "Resources",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "access",
        name: "Access Management",
        type: "sub",
        children: [
          {
            state: "accessNY",
            name: "Access Management - New York",
          },
          // {
          //   state: "accessBangkok",
          //   name: "Access Management - Bangkok",
          // },
          {
            state: "accessGeneva",
            name: "Access Management - Geneva",
          },
          {
            state: "accessNairobi",
            name: "Access Management - Nairobi",
          },
          {
            state: "accessVienna",
            name: "Access Management - Vienna",
          },
        ],
      },
      {
        state: "daglibrary",
        name: "Dag Hammarskjöld Library",
        type: "sub",
        children: [
          {
            state: "asklibrary",
            name: "Ask DAG Library",
            type: "external",
            external: "https://ask.un.org/",
            target: true,
          },
          {
            state: "dlibrary",
            name: "UN Digital Library",
            type: "external",
            external: "https://digitallibrary.un.org/",
            target: true,
          },
          {
            state: "dlibraryV",
            name: "UN Digital Library Voting Data",
            type: "external",
            external:
              "https://digitallibrary.un.org/search?ln=en&cc=Voting+Data",
            target: true,
          },
        ],
      },
      {
        state: "delegatesHandbook",
        name: "Delegates Handbook",
        type: "external",
        external:
          "https://www.un.org/en/ga/76/pdf/Delegates_Handbook_2021_EN.pdf",
        target: true,
      },
      {
        state: "documents",
        name: "Documents",
        type: "sub",
        children: [
          {
            state: "documentsIssuedAtUNHQToday",
            name: `Documents issued at UNHQ Today`,
          },
          {
            state: "eCorrespondenceEmail",
            name: "e-Correspondence",
          },
          {
            state: "eSubscriptionToUnitedNationsDocuments",
            name: "e-Subscription to United Nations Documents",
            type: "external",
            external: "http://undocs.org/",
            target: true,
          },
        ],
      },
      {
        state: "gMeets",
        name: "gMeets (OSS)",
        type: "external",
        external: "https://conferences.unite.un.org/gMeets",
        target: true,
      },
      {
        state: "meetings",
        name: "Meetings",
        type: "sub",
        children: [
          {
            state: "",
            name: `Calendar of Conferences and Meetings`,
            type: "external",
            external: 'https://www.un.org/calendar/-$lang$-',
            target: true
          },
          {
            state: "meetingsAtUNHQ",
            name: `Meetings at UNHQ`,
          },
          {
            state: "meetingRoom",
            name: "Meeting Room / Area Locator",
          },
          {
            state: "surveyOnConferenceServices",
            name: `Survey on Conference Services`,
            type: "external",
            external: 'https://forms.office.com/e/X5zhX2Kx12',
            target: true
          },
        ],
      },
      {
        state: "spokespersonMorningHeadlines",
        name: `Spokesperson's Morning Headlines`,
      },
      {
        state: "telephoneDirectory",
        name: "Telephone Directory / Yellow Pages",
      },
      {
        state: "trainings",
        name: "Trainings",
        type: "sub",
        children: [
          {
            state: "languageTraining",
            name: "Language Training",
            type: "external",
            external: "https://hr.un.org/page/language-programmes-unhq",
            target: true,
          },
          {
            state: "libraryTraining",
            name: "Library Training",
            type: "external",
            external: "https://un.libcal.com/",
            target: true,
          },
          {
            state: "unitar",
            name: "UNITAR Events",
            type: "external",
            external: "https://unitar.org/event/",
            target: true,
          },
        ],
      },
      {
        state: "vacancies",
        name: "Vacancies",
        type: "sub",
        children: [
          {
            state: "seniorLevelVacancies",
            name: "Senior Level Vacancies",
            type: "external",
            external: "https://www.un.org/sg/-$lang$-/vacancies/index.shtml",
            target: true,
          },
          {
            state: "ICSCVacancies",
            name: "ICSC Vacancies",
            type: "external",
            external: "https://jobs.unicsc.org/",
            target: true,
          },
        ],
      },
      // {
      //   state: "indtoproc",
      //   name: "Index to Proceedings",
      //   type: "external",
      //   external: "https://library.un.org/content/index-proceedings-0",
      //   target: true,
      // },
      // {
      //   state: "feedback",
      //   name: "Feedback",
      // },
    ],
  },
  {
    state: "administration",
    short_label: "administration",
    name: "Secretariat",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "auth",
        name: "Authorization Admin",
        type: "sub",
        children: [
          {
            state: "accessAdmin",
            name: "Authorization Admin",
          },
          // {
          //   state: "accessmanagementsec",
          //   name: "Entities authorization",
          // },
        ],
      },
      {
        state: "userslist",
        name: "List of users",
      },
      {
        state: "proposals",
        name: "Action module",
      },
      {
        state: "eregistrations",
        name: "e-Registration",
        type: "sub",
        children: [
          {
            state: "media",
            name: "Media",
          },
          {
            state: "security",
            name: "Security",
          },
          {
            state: "passandid",
            name: "Pass and ID",
          },
        ],
      },
      {
        state: "InscriptionSetup",
        name: "Inscription Setup",
      },
      {
        state: "emailtemplates",
        name: "Email Templates",
      },
      {
        state: "SecretariatListofSpeakers",
        name: "List of Speakers",
      },
      {
        state: "gd",
        name: "List of Speakers - General Debate",
      },
      // {
      //   state: "meetingproceedings",
      //   name: "Meeting Proceedings",
      //   type: "sub",
      //   children: [
      //     {
      //       state: "list",
      //       name: "List of Meetings",
      //     },
      //     {
      //       state: "report",
      //       name: "Report",
      //     },
      //     {
      //       state: "RulesOfProcedure",
      //       name: "Rules of Procedure",
      //     },
      //     {
      //       state: "PGAProcesses",
      //       name: "PGA Processes",
      //     },
      //   ],
      // },
      // {
      //   state: "statements",
      //   name: "List of Statements",
      // },
      {
        state: "ListOfParticipantsSetup",
        name: "List of Participants Setup",
      },
      {
        state: "electionssetup",
        name: "Candidatures and Elections Setup",
      },
      {
        state: "esubmitsetup",
        name: "e-Submit Setup",
      },
      {
        state: "eRecordedVotesSetup",
        name: "e-Recorded Votes Setup",
      },
      {
        state: "eRecordedVotesSetupHRC",
        name: "e-Recorded Votes Setup HRC",
      },
      {
        state: "eVotes",
        name: "e-Votes",
      },
      {
        state: "votingIntentions",
        name: "Voting Intentions",
      },
      {
        state: "e-blue-book-precis-writers",
        name: "e-BlueBook for precis-writers",
      },
      {
        state: "lookup",
        name: "Lookups",
      },
      {
        state: "technicalSupport",
        name: "Technical Support",
        type: "sub",
        children: [
          {
            state: "cache",
            name: "Cache",
          },
          {
            state: "searchindex",
            name: "Search-Index",
          },
          {
            state: 'search-index-elastic',
            name: 'Bulk Search Index'
          },
          {
            state: "bulk",
            name: "Bulk",
          },
          {
            state: "meta",
            name: "Meta",
          },
          {
            state: "version",
            name: "Version",
          },
          {
            state: "datasync-mpm",
            name: "Datasync MPM",
          },
          {
            state: "import-e-registration-data",
            name: "Import e-Registration Data",
          },
          {
            state: "releaseNotes",
            name: "Release Notes",
          },
        ],
      },
    ],
  },
];


/**
 * What's New
 * Protocol
 *  - Protocol and Liaison Service
 *  - Conference registration for UN secretariat and entities
 * Resources
 */
const MENUITEMS_UNSD = [
  {
    state: "protocol",
    short_label: "O",
    name: "Protocol",
    type: "sub",
    icon: "icon-award",
    children: [
      {
        state: "protocolAndLiaisonService",
        name: "Protocol and Liaison Service",
        type: "external",
        external: "https://www.un.org/dgacm/-$lang$-/content/protocol",
        target: true,
      },
      {
        state: "staffregistration",
        name: "Conference registration for UN secretariat and entities",
      },
    ],
  },
];

@Injectable()
export class MenuItems {
  getAll() {
    let user;
    try {
      const userLocalStorage = localStorage.getItem("user");
      if (userLocalStorage) user = JSON.parse(userLocalStorage);
    } catch (e) {
    }

    if (user
      && user.USR_Access?.length === 1
      && user.USR_Access?.[0].ACC_Access?.length === 1
      && user.USR_Access?.[0].ACC_Access?.[0].accessType === 'edit'
      && user.USR_Access?.[0].ACC_Access?.[0].moduleName === 'ERegistrationStaff') {
      return {
        menuItems: MENUITEMS_UNSD,
        showUNSDMenu: true,
      };
    } else {
      const menuItemsTemp = environment.branch === "PROD" ? MENUITEMS_PROD : MENUITEMS;
      return {
        menuItems: menuItemsTemp,
        showUNSDMenu: false,
      }
    }
  }
}
